import React, {Component} from "react";
import Profile from '../../../assets/user/images/profile-icons/profile.svg';
import ActiveProfile from '../../../assets/user/images/profile-icons/active/profile.svg';
import EditProfile from '../../../assets/user/images/profile-icons/edit-profile.svg';
import ActiveEditProfile from '../../../assets/user/images/profile-icons/active/edit-profile.svg';
import PhoneVefiry from '../../../assets/user/images/profile-icons/phone-verify.svg';
import ActivePhoneVefiry from '../../../assets/user/images/profile-icons/active/phone-verify.svg';
import IdVerify from '../../../assets/user/images/profile-icons/id-verify.svg';
import ActiveIdVerify from '../../../assets/user/images/profile-icons/active/id-verify.svg';
import ResetPass from '../../../assets/user/images/profile-icons/reset-pass.svg';
import ActiveResetPass from '../../../assets/user/images/profile-icons/active/reset-pass.svg';
import ActiveLog from '../../../assets/user/images/profile-icons/activity-log.svg';
import AciveActiveLog from '../../../assets/user/images/profile-icons/active/activity-log.svg';
import ProfileImage from '../../../assets/user/images/profile.jpg';
import Flag from '../../../assets/user/images/flag.svg';
import Nid from '../../../assets/user/images/cards/nid.svg';
import Passport from '../../../assets/user/images/cards/passport.svg';
import License from '../../../assets/user/images/cards/driving-license.svg';
import IdFront from '../../../assets/user/images/cards/id-front.svg';
import IdBack from '../../../assets/user/images/cards/id-back.svg';
import User_Header from "../Header";
import User_Sidebar from "../Sidebar";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Pagination01 from "../../../assets/admin/images/angle-left.svg";
import RightArrow from "../../../assets/admin/images/angle-right.svg";

const ActiveLogData = [
    {
        id: 1,
        action: 'Profile Image Upload',
        source: 'Web',
        ip: '12.00.98.006',
        location: 'United State',
        date: '2019-01-24 12:50:17',
    }, {
        id: 2,
        action: 'Log In',
        source: 'App',
        ip: '12.00.98.006',
        location: 'Japan',
        date: '2019-01-24 12:50:17',
    }, {
        id: 3,
        action: 'Create Address',
        source: 'Web',
        ip: '12.00.98.006',
        location: 'United State',
        date: '2019-01-24 12:50:17',
    }, {
        id: 4,
        action: 'Profile Image Upload',
        source: 'App',
        ip: '12.00.98.006',
        location: 'Canada',
        date: '2019-01-24 12:50:17',
    }, {
        id: 5,
        action: 'Profile Image Upload',
        source: 'Web',
        ip: '12.00.98.006',
        location: 'United State',
        date: '2019-01-24 12:50:17',
    }, {
        id: 6,
        action: 'Profile Image Upload',
        source: 'Web',
        ip: '12.00.98.006',
        location: 'United State',
        date: '2019-01-24 12:50:17',
    }, {
        id: 7,
        action: 'Profile Image Upload',
        source: 'Web',
        ip: '12.00.98.006',
        location: 'United State',
        date: '2019-01-24 12:50:17',
    },
];

class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProfile: true,
            isEdit: false,
            isPhoneVerify: false,
            isIdVerify: false,
            isResetPass: false,
            isActiveLog: false,
            isAvatar: '',
            isPhoneNumnerPopup: false,
            show: false,
            frontImage: '',
            backImage: '',
            currentPage: 1,
            userPage: 5,
            ActiveLogData: ActiveLogData,
            isMenu: false,
        };
        this.modalShow = this.modalShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    componentDidMount() {
        document.body.addEventListener('click', this.myHandler);
    }

    myHandler = () => {
        if (this.state.isPhoneNumnerPopup === true) {
            this.setState({
                isPhoneNumnerPopup: false
            })
        }
    };

    isProfile = () => {
        this.setState({
            isEdit: false,
            isPhoneVerify: false,
            isIdVerify: false,
            isResetPass: false,
            isActiveLog: false,
            isProfile: true,
        })
    };
    isEdit = () => {
        this.setState({
            isProfile: false,
            isPhoneVerify: false,
            isIdVerify: false,
            isResetPass: false,
            isActiveLog: false,
            isEdit: true,
        })
    };
    isPhoneVerify = () => {
        this.setState({
            isProfile: false,
            isIdVerify: false,
            isResetPass: false,
            isActiveLog: false,
            isEdit: false,
            isPhoneVerify: true,
        })
    };
    isIdVerify = () => {
        this.setState({
            isProfile: false,
            isResetPass: false,
            isActiveLog: false,
            isEdit: false,
            isPhoneVerify: false,
            isIdVerify: true,
        })
    };
    isResetPass = () => {
        this.setState({
            isProfile: false,
            isActiveLog: false,
            isEdit: false,
            isPhoneVerify: false,
            isIdVerify: false,
            isResetPass: true,
        })
    };
    isActiveLog = () => {
        this.setState({
            isProfile: false,
            isEdit: false,
            isPhoneVerify: false,
            isIdVerify: false,
            isResetPass: false,
            isActiveLog: true,
        })
    };
    //For Image change
    profileImageUpload = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                isAvatar: URL.createObjectURL(event.target.files[0])
            });
        }
        toast.success('Successfully Added!');
    };
    //For Image change
    frontImageHandler = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                frontImage: URL.createObjectURL(event.target.files[0])
            });
        }
        toast.success('Successfully Added!');
    };
    //For Image change
    backImageHandler = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                backImage: URL.createObjectURL(event.target.files[0])
            });
        }
        toast.success('Successfully Added!');
    };
    //From Submit Handler
    fromSubmit = (e) => {
        e.preventDefault()
    };

    //Phone number dropdown handler
    isPhoneNumnerPopup = () => {
        this.setState({
            isPhoneNumnerPopup: !this.state.isPhoneNumnerPopup,
        })
    };

    modalShow() {
        this.setState(
            {
                show: true,
            }
        )
    }

    handleClose() {
        this.setState(
            {
                show: false,
            }
        )
    }

    // Pagination
    firstPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: 1
            })
        }

    }
    lastPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.ActiveLogData.length / this.state.userPage)) {
            this.setState({
                currentPage: Math.ceil(this.state.ActiveLogData.length / this.state.userPage)
            })
        }

    }
    prevPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }

    }
    nextPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.ActiveLogData.length / this.state.userPage)) {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    }
    paginateNumber = (id) => {
        this.setState({
            currentPage: id
        })
    }
    //Onchange Handler
    onChangeHandler = (e) =>{
        e.preventDefault();
    }
    toggleClass = () => {
        this.setState({
            isMenu: !this.state.isMenu
        })
    }

    render() {
        const {ActiveLogData, currentPage, userPage} = this.state;
        const lastIndex = currentPage * userPage;
        const firstIndex = lastIndex - userPage;
        const currentUser = ActiveLogData ? ActiveLogData.slice(firstIndex, lastIndex) : '';
        const totalPage = ActiveLogData ? ActiveLogData.length / userPage : '';

        const result = Math.ceil(totalPage);
        const pageNumber = [];
        for (let i = 1; i <= Math.ceil(totalPage); i++) {
            pageNumber.push(i);
        }
        return (
            <div>
                <User_Header toggleClass={this.toggleClass} />
                <User_Sidebar sidebarClass={this.state.isMenu}/>
                <div className="cp-user-main-wrapper cp-user-modal">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <ul className="nav cp-user-profile-nav" id="pills-tab" role="tablist">
                                    <li onClick={this.isProfile} className="nav-item">
                                        <Link to="#" className={this.state.isProfile ? "nav-link active" : "nav-link"}
                                              aria-selected="true">
                                <span className="cp-user-img">
                                    <img src={Profile} className="img-fluid img-normal" alt=""/>
                                    <img src={ActiveProfile} className="img-fluid img-active"
                                         alt=""/>
                                </span>
                                            profile
                                        </Link>
                                    </li>
                                    <li onClick={this.isEdit} className="nav-item">
                                        <Link to="#" className={this.state.isEdit ? "nav-link active" : "nav-link"}
                                              id="pills-edit-profile-tab" data-toggle="pill"
                                              aria-selected="true">
                                <span className="cp-user-img">
                                    <img src={EditProfile} className="img-fluid img-normal"
                                         alt=""/>
                                    <img src={ActiveEditProfile}
                                         className="img-fluid img-active" alt=""
                                    /></span>
                                            Edit Profile
                                        </Link>
                                    </li>
                                    <li onClick={this.isPhoneVerify} className="nav-item">
                                        <Link to="#" className={this.state.isPhoneVerify ? "nav-link active" : "nav-link"}
                                              id="pills-phone-verify-tab" data-toggle="pill"
                                              aria-selected="true">
                                <span className="cp-user-img">
                                    <img src={PhoneVefiry} className="img-fluid img-normal"
                                         alt=""/>
                                    <img src={ActivePhoneVefiry}
                                         className="img-fluid img-active" alt=""
                                    /></span>
                                            Phone Verification
                                        </Link>
                                    </li>
                                    <li onClick={this.isIdVerify} className="nav-item">
                                        <Link to="#" className={this.state.isIdVerify ? "nav-link active" : "nav-link"}
                                              id="pills-id-verify-tab" data-toggle="pill"
                                              aria-selected="true">
                                <span className="cp-user-img">
                                    <img src={IdVerify} className="img-fluid img-normal"
                                         alt=""/>
                                    <img src={ActiveIdVerify}
                                         className="img-fluid img-active" alt=""/>
                                </span>
                                            ID Verification
                                        </Link>
                                    </li>
                                    <li onClick={this.isResetPass} className="nav-item">
                                        <Link to="#" className={this.state.isResetPass ? "nav-link active" : "nav-link"}
                                           id="pills-reset-pass-tab" data-toggle="pill"
                                           aria-selected="true">
                                <span className="cp-user-img">
                                    <img src={ResetPass} className="img-fluid img-normal"
                                         alt=""/>
                                    <img src={ActiveResetPass}
                                         className="img-fluid img-active" alt=""/>
                                </span>
                                            Reset Password
                                        </Link>
                                    </li>
                                    <li onClick={this.isActiveLog} className="nav-item">
                                        <Link to="#" className={this.state.isActiveLog ? "nav-link active" : "nav-link"}
                                           id="pills-activity-log-tab" data-toggle="pill"
                                           aria-selected="true">
                                <span className="cp-user-img">
                                    <img src={ActiveLog} className="img-fluid img-normal"
                                         alt=""/>
                                    <img src={AciveActiveLog}
                                         className="img-fluid img-active" alt=""
                                    />
                                </span>
                                            Activity Log
                                        </Link>
                                    </li>
                                </ul>
                                <div className="tab-content cp-user-profile-tab-content" id="pills-tabContent">
                                    {this.state.isProfile &&
                                    <div className="tab-pane fade show active" id="pills-profile" role="tabpanel"
                                         aria-labelledby="pills-profile-tab">
                                        <div className="row">
                                            <div className="col-xl-4 mb-xl-0 mb-4">
                                                <div className="card cp-user-custom-card">
                                                    <div className="card-body">
                                                        <div className="user-profile-area">
                                                            <div className="user-profile-img">
                                                                <img src={ProfileImage} className="img-fluid"
                                                                     alt=""/>
                                                            </div>
                                                            <div className="user-cp-user-profile-info">
                                                                <h4>William Jeremi</h4>
                                                                <p>Williamjeremi32@gmail.com</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-8">
                                                <div className="card cp-user-custom-card">
                                                    <div className="card-body">
                                                        <div className="cp-user-profile-header">
                                                            <h5>Profile Information</h5>
                                                        </div>
                                                        <div className="cp-user-profile-info">
                                                            <ul>
                                                                <li>
                                                                    <span>Name</span>
                                                                    <span className="cp-user-dot">:</span>
                                                                    <span>William Jeremi</span>
                                                                </li>
                                                                <li>
                                                                    <span>Country</span>
                                                                    <span className="cp-user-dot">:</span>
                                                                    <span>United State</span>
                                                                </li>
                                                                <li>
                                                                    <span>Email</span>
                                                                    <span className="cp-user-dot">:</span>
                                                                    <span>williamjeremi23@gmail.com</span>
                                                                </li>
                                                                <li>
                                                                    <span>Email Verification</span>
                                                                    <span className="cp-user-dot">:</span>
                                                                    <span>Active</span>
                                                                </li>
                                                                <li>
                                                                    <span>Phone</span>
                                                                    <span className="cp-user-dot">:</span>
                                                                    <span>+985 678 876 3456</span>
                                                                </li>
                                                                <li>
                                                                    <span>Phone Verification</span>
                                                                    <span className="cp-user-dot">:</span>
                                                                    <span className="pending">Pending</span>
                                                                </li>
                                                                <li>
                                                                    <span>Role</span>
                                                                    <span className="cp-user-dot">:</span>
                                                                    <span>User</span>
                                                                </li>
                                                                <li>
                                                                    <span>Status</span>
                                                                    <span className="cp-user-dot">:</span>
                                                                    <span>Active</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.isEdit &&
                                    <div className="tab-pane fade show active" id="pills-edit-profile" role="tabpanel"
                                         aria-labelledby="pills-edit-profile-tab">
                                        <div className="row">
                                            <div className="col-xl-4 mb-xl-0 mb-4">
                                                <div className="card cp-user-custom-card">
                                                    <div className="card-body">
                                                        <div className="user-profile-area">
                                                            <div className="user-profile-img">
                                                                {this.state.isAvatar !== '' ?
                                                                    <img src={this.state.isAvatar} className="img-fluid"
                                                                         alt=""/> :
                                                                    <img src={ProfileImage} className="img-fluid"
                                                                         alt=""/>}
                                                            </div>
                                                            <div className="user-cp-user-profile-info">
                                                                <label htmlFor="upload-user-img"
                                                                       className="upload-user-img">
                                                                    <input onChange={this.profileImageUpload}
                                                                           type="file" id="upload-user-img"/>
                                                                    Upload New Image
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-8">
                                                <div className="card cp-user-custom-card">
                                                    <div className="card-body">
                                                        <div className="cp-user-profile-header">
                                                            <h5>Edit Profile Information</h5>
                                                        </div>
                                                        <div className="cp-user-profile-info">
                                                            <form>
                                                                <div className="form-group">
                                                                    <label>First Name</label>
                                                                    <input type="text" placeholder="William"
                                                                           className="form-control"/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Last Name</label>
                                                                    <input type="text" placeholder="Jeremi"
                                                                           className="form-control"/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Email Address</label>
                                                                    <input type="email"
                                                                           placeholder="williamjeremi23@gmail.com"
                                                                           className="form-control"/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Email Address</label>
                                                                    <input type="email"
                                                                           placeholder="williamjeremi23@gmail.com"
                                                                           className="form-control"/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Phone Number</label>
                                                                    <input type="text" placeholder="+967 897 678 9876"
                                                                           className="form-control"/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Country</label>
                                                                    <input type="text" placeholder="United State"
                                                                           className="form-control"/>
                                                                </div>
                                                                <div className="form-group m-0">
                                                                    <button onClick={this.fromSubmit}
                                                                            className="btn profile-edit-btn"
                                                                            type="submit">Update
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.isPhoneVerify &&
                                    <div className="tab-pane fade show active" id="pills-phone-verify" role="tabpanel"
                                         aria-labelledby="pills-phone-verify-tab">
                                        <div className="card cp-user-custom-card">
                                            <div className="card-body">
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-9">
                                                        <div className="cp-user-profile-header">
                                                            <h5>Phone Verification</h5>
                                                        </div>
                                                        <div className="cp-user-profile-info">
                                                            <form>
                                                                <div className="form-group">
                                                                    <label>Phone Number</label>
                                                                    <div className="input-group">
                                                                        <div
                                                                            className="input-group-prepend input-group-prepend-phone-verify">
                                                                            <div className="dropdown">
                                                                                <button
                                                                                    onClick={this.isPhoneNumnerPopup}
                                                                                    className="btn dropdown-toggle"
                                                                                    type="button"
                                                                                    id="dropdownMenuButton"
                                                                                    data-toggle="dropdown"
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded="false">
                                                                    <span className="flag mr-3">
                                                                        <img src={Flag} className="img-fluid"
                                                                             alt=""/>
                                                                    </span>
                                                                                    +88
                                                                                </button>
                                                                                {this.state.isPhoneNumnerPopup &&
                                                                                <div className="dropdown-menu"
                                                                                     aria-labelledby="dropdownMenuButton">
                                                                                    <a className="dropdown-item"
                                                                                       href="#">Action</a>
                                                                                    <a className="dropdown-item"
                                                                                       href="#">Another action</a>
                                                                                    <a className="dropdown-item"
                                                                                       href="#">Something else here</a>
                                                                                </div>}
                                                                            </div>
                                                                        </div>
                                                                        <input type="text" className="form-control"
                                                                               id="validationTooltipUsername"
                                                                               aria-describedby="validationTooltipUsernamePrepend"/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group m-0">
                                                                    <button onClick={this.fromSubmit}
                                                                            className="btn profile-edit-btn"
                                                                            type="submit">Save
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.isIdVerify &&
                                    <div className="tab-pane fade show active" id="pills-id-verify" role="tabpanel"
                                         aria-labelledby="pills-id-verify-tab">
                                        <div className="card cp-user-custom-card idverifycard">
                                            <div className="card-body">
                                                <div className="cp-user-profile-header">
                                                    <h5>Select Your ID Type</h5>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-10">
                                                        <div className="cp-user-profile-info-id-type">
                                                            <div onClick={this.modalShow} className="id-card-type">
                                                                <span className="tagname">NOT SUBMITTED</span>
                                                                <div className="id-card" data-toggle="modal"
                                                                     data-target=".cp-user-idverifymodal">
                                                                    <img src={Nid}
                                                                         className="img-fluid" alt=""/>
                                                                </div>
                                                                <h5>National ID Card</h5>
                                                            </div>
                                                            <div onClick={this.modalShow} className="id-card-type">
                                                                <span className="tagname">NOT SUBMITTED</span>
                                                                <div className="id-card" data-toggle="modal"
                                                                     data-target=".cp-user-idverifymodal">
                                                                    <img src={Passport}
                                                                         className="img-fluid" alt=""/>
                                                                </div>
                                                                <h5>Passport</h5>
                                                            </div>
                                                            <div onClick={this.modalShow} className="id-card-type">
                                                                <span className="tagname">NOT SUBMITTED</span>
                                                                <div className="id-card" data-toggle="modal"
                                                                     data-target=".cp-user-idverifymodal">
                                                                    <img src={License}
                                                                         className="img-fluid" alt=""/>
                                                                </div>
                                                                <h5>Driving License</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.isResetPass &&
                                    <div className="tab-pane fade show active" id="pills-reset-pass" role="tabpanel"
                                         aria-labelledby="pills-reset-pass-tab">
                                        <div className="card cp-user-custom-card">
                                            <div className="card-body">
                                                <div className="cp-user-profile-header">
                                                    <h5>Reset Password</h5>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-9">
                                                        <div className="cp-user-profile-info">
                                                            <form>
                                                                <div className="form-group">
                                                                    <label>Old Password</label>
                                                                    <input type="password"
                                                                           placeholder="Enter Your Old Password . . ."
                                                                           className="form-control"/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>New Password</label>
                                                                    <input type="password"
                                                                           placeholder="Enter New Password . . ."
                                                                           className="form-control"/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Confirm Password</label>
                                                                    <input type="password"
                                                                           placeholder="Re-Enter your New Password . . ."
                                                                           className="form-control"/>
                                                                </div>
                                                                <div className="form-group m-0">
                                                                    <button onClick={this.fromSubmit}
                                                                            className="btn profile-edit-btn"
                                                                            type="submit">Change Password
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {this.state.isActiveLog &&
                                    <div className="tab-pane fade show active" id="pills-activity-log" role="tabpanel"
                                         aria-labelledby="pills-activity-log-tab">
                                        <div className="card cp-user-custom-card">
                                            <div className="card-body">
                                                <div className="cp-user-profile-header">
                                                    <h5>All ActivityList</h5>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="cp-user-wallet-table table-responsive">
                                                            <table
                                                                className="table table-borderless cp-user-custom-table"
                                                                width="100%">
                                                                <thead>
                                                                <tr>
                                                                    <th className="all">Action</th>
                                                                    <th className="desktop">Source</th>
                                                                    <th className="desktop">IP Address</th>
                                                                    <th className="all">Location</th>
                                                                    <th className="all">Updated At</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    currentUser.map(activity => {
                                                                        return (
                                                                            <tr key={activity.id}>
                                                                                <td>{activity.action}</td>
                                                                                <td>{activity.source}</td>
                                                                                <td>{activity.ip}</td>
                                                                                <td>{activity.location}</td>
                                                                                <td>{activity.date}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }

                                                                </tbody>
                                                            </table>

                                                            <div className="cp-user-pagin">
                                                                <ul className="d-flex justify-content-center">
                                                                    <li><Link onClick={this.prevPage} to="#"><img
                                                                        src={Pagination01} className="img-fluid"
                                                                        alt=""/></Link></li>
                                                                    {
                                                                        pageNumber.map((number, index) => {
                                                                            return (
                                                                                <li onClick={() => this.paginateNumber(number)}
                                                                                    className="active" key={number}>
                                                                                    <Link to="#">{number}</Link></li>
                                                                            )
                                                                        })
                                                                    }
                                                                    <li><Link onClick={this.nextPage} to="#"><img
                                                                        src={RightArrow} className="img-fluid"
                                                                        alt=""/></Link></li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal className="abc_modal" show={this.state.show} onHide={this.handleClose} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>ID Verification</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <button type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                                <span aria-hidden="true"><img src=''
                                                                              className="img-fluid" alt=""/></span>
                            </button>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 mb-lg-0 mb-4">
                                        <div className="idcard">
                                            <h3 className="title">Front Side</h3>
                                            <div className="card-inner">
                                                {this.state.frontImage !== '' ? <img src={this.state.frontImage}
                                                                                     alt="" width="250" height="145"/> :
                                                    <img src={IdFront}
                                                         className="img-fluid" alt=""/>}

                                            </div>
                                            <form action="">
                                                <label htmlFor="idfront" className="iduploadbtn">
                                                    Select Image
                                                    <input onChange={this.frontImageHandler} type="file" name="imgone"
                                                           id="idfront" className="d-none"/>
                                                </label>
                                            </form>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-lg-0 mb-4">
                                        <div className="idcard">
                                            <h3 className="title">Back Side</h3>
                                            <div className="card-inner">
                                                {this.state.backImage !== '' ? <img src={this.state.backImage}
                                                                                    alt=""
                                                                                    width="250" height="145"/> :
                                                    <img src={IdBack}
                                                         className="img-fluid" alt=""/>}

                                            </div>
                                            <form action="">
                                                <label htmlFor="idfrontTwo" className="iduploadbtn">
                                                    Select Image
                                                    <input onChange={this.backImageHandler} type="file" id="idfrontTwo"
                                                           className="d-none"/>
                                                </label>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button type="button" className="btn carduploadbtn">Upload
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            </div>
        )
    }
}

export default MyProfile;