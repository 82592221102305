import React, {Component} from "react";
import QR from '../../../assets/user/images/QR.svg';
import User_Sidebar from "../Sidebar";
import User_Header from "../Header";
import {Link} from "react-router-dom";
class BitcoinDeposite extends Component{
    constructor(prop) {
        super(prop);
        this.state = {
            addClass: false,
            isMenu: false,
        }
    }
    fromSubmit = (e) =>{
        e.preventDefault()
    };
    toggleClass = () => {
        this.setState({
            isMenu: !this.state.isMenu
        })
    }
    render() {
        return(
            <div>
                <User_Header toggleClass={this.toggleClass} />
                <User_Sidebar sidebarClass={this.state.isMenu}/>
                <div className="cp-user-main-wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-xl-6 mb-xl-0 mb-4">
                                <div className="card cp-user-custom-card cp-user-cp-user-bit-coin-card">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <h4>Buy Our Coin From Here</h4>
                                        </div>
                                        <div className="cp-user-buy-coin-content-area">
                                            <p>Curabitur at metus vel risus posuere fringilla sit amet vel tortor.
                                                Praesent blandit dolor in mi sodales, ut dictum metus efficitur. Nulla
                                                pulvinar enim ligula.</p>
                                            <div className="cp-user-coin-info">
                                                <form onSubmit={this.fromSubmit}>
                                                    <div className="form-group">
                                                        <label>Coin Amount</label>
                                                        <input type="text" className="form-control"
                                                               placeholder="Your Amount . . ." name="coin-amout"/>
                                                    </div>
                                                    <div className="cp-user-payment-type">
                                                        <h3>Payment Type</h3>
                                                        <div className="form-group">
                                                            <Link to="/user-bitcoin">
                                                                <input type="radio" name="coin" defaultChecked id="cp-user-bit-coin"/>
                                                                <label htmlFor="cp-user-bit-coin">Bit Coin</label>
                                                            </Link>
                                                        </div>
                                                        <div className="form-group">
                                                            <Link to="/user-credit-card">
                                                                <input type="radio" name="coin" id="cp-user-credit-card"/>
                                                                <label htmlFor="cp-user-credit-card">Credit Card</label>
                                                            </Link>
                                                        </div>
                                                        <div className="form-group">
                                                            <Link to="/user-bank-info">
                                                                <input type="radio" name="coin" id="cp-user-bank-deposit"/>
                                                                <label htmlFor="cp-user-bank-deposit">Bank
                                                                    Deposit</label>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="card cp-user-custom-card">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <h4>Today’s Coin Rate</h4>
                                        </div>
                                        <div className="cp-user-coin-rate">
                                            <ul>
                                                <li>1 Cpocketo Coin</li>
                                                <li>=</li>
                                                <li>00.098642 USD</li>
                                            </ul>
                                            <div className="cp-user-coin-info-area">
                                                <h5>Please Scan Your QR Code</h5>
                                                <div className="cp-user-qr">
                                                    <img src={QR} className="img-fluid" alt=""/>
                                                </div>
                                                <div className="cp-user-copy">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <button className="btn">copy</button>
                                                        </div>
                                                        <input type="text" className="form-control"
                                                               placeholder="2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q2NCzoqbY7CXi"/>
                                                    </div>
                                                    <button className="btn cp-user-setupbtn">Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default BitcoinDeposite;