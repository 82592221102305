import React, {Component} from "react";
import Sidebar from "../../Sidebar";
import Header from "../../Header";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Upload from '../../../../assets/admin/images/upload-icon.svg';

class AddContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            isHeader: true,
            isContent: false,
            isLogo: '',
            isFavicon: '',
            isContentImg: '',
        }
    }

    handleClick = () => this.setState((currentState) => ({isActive: !currentState.isActive}));

    //Tabs Show Hide
    header = () => {
        this.setState({isContent: false});
        this.setState({isHeader: true});
    };
    content = () => {
        this.setState({isHeader: false});
        this.setState({isContent: true});
    };

    //Submit Image
    ImageSubmit = (e) => {
        e.preventDefault()
    }


    //For Image change
    ImageUploadLogo = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                isLogo: URL.createObjectURL(event.target.files[0])
            });
        }
        toast.success('Successfully Added!');
    };
    //For Image change
    ImageUploadFavicon = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                isFavicon: URL.createObjectURL(event.target.files[0])
            });
        }
        toast.success('Successfully Added!');
    };
    //For Image change
    ContentImg = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                isContentImg: URL.createObjectURL(event.target.files[0])
            });
        }
        toast.success('Successfully Added!');
    };


    render() {
        return (
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className={`main-wrapper ${this.state.isActive ? 'content-expend' : ''}`}>
                    <div className="container-fluid">
                        <div className="custom-breadcrumb">
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li>Landing Page</li>
                                        <li className="active-item">Add Static Content</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="user-management">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav user-management-nav justify-content-start mb-3" id="pills-tab"
                                        role="tablist">
                                        <li onClick={this.header} className="nav-item">
                                            <Link to="#"
                                                  className={this.state.isHeader ? "nav-link active" : "nav-link"}
                                                  id="pills-user-tab" data-toggle="pill"
                                                  aria-selected="true">
                                                <span>Header Settings</span>
                                            </Link>
                                        </li>
                                        <li onClick={this.content} className="nav-item">
                                            <Link to="#"
                                                  className={this.state.isContent ? "nav-link active" : "nav-link"}
                                                  id="pills-add-user-tab" data-toggle="pill"
                                                  aria-selected="true">
                                                <span>Static Content</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        {
                                            this.state.isHeader &&
                                            <div className="tab-pane show active" id="pills-user" role="tabpanel"
                                                 aria-labelledby="pills-user-tab">
                                                <div className="header-bar">
                                                    <div className="table-title">
                                                        <h3>Landing Page Settings</h3>
                                                    </div>
                                                </div>
                                                <div className="profile-info-form">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-xl-6 mb-xl-0 mb-4">
                                                                <div className="form-group">
                                                                    <label>Landing Page Title</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="The Secure, Shared Bitcoin Wallet"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 mb-xl-0 mb-4">
                                                                <div className="form-group">
                                                                    <label>Landing Page Android App Link</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="https://Mycoin/admin/landing-page-setting"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="form-group">
                                                                    <label>Landing Page Button Link</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="https://Mycoin/admin/landing-page-setting"/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Landing Page iOS App Link</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="https://Mycoin/admin/landing-page-setting"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="form-group">
                                                                    <label>Landing Page Description</label>
                                                                    <textarea className="form-control textarea">Secure your bitcoin with the open source, HD‑multisignature wallet from BitPay. </textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <div className="row">
                                                                    <div className="col-xl-4 col-md-6 mb-xl-0 mb-4">
                                                                        <form>
                                                                            <div className="form-group">
                                                                                <label className="d-block">Landing Page
                                                                                    Logo</label>
                                                                                <label className="logo">
                                                                                    {
                                                                                        this.state.isLogo !== '' ?
                                                                                            <img src={this.state.isLogo}
                                                                                                 width="300"
                                                                                                 height="150"
                                                                                                 alt=""/> :
                                                                                            <img src={Upload}
                                                                                                 className="img-fluid"
                                                                                                 alt=""/>
                                                                                    }

                                                                                    <input
                                                                                        onChange={this.ImageUploadLogo}
                                                                                        type="file"
                                                                                        className="d-none"/>
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <button onClick={this.ImageSubmit}
                                                                                        type="submit"
                                                                                        className="btn logo-upload-btn">Upload
                                                                                    Image
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="col-xl-4 col-md-6 mb-xl-0 mb-4">
                                                                        <form>
                                                                            <div className="form-group">
                                                                                <label className="d-block">Landing Page
                                                                                    Image
                                                                                </label>
                                                                                <label className="logo">
                                                                                    {
                                                                                        this.state.isLogo !== '' ?
                                                                                            <img
                                                                                                src={this.state.isFavicon}
                                                                                                width="300" height="150"
                                                                                                alt=""/> :
                                                                                            <img src={Upload}
                                                                                                 className="img-fluid"
                                                                                                 alt=""/>
                                                                                    }

                                                                                    <input
                                                                                        onChange={this.ImageUploadFavicon}
                                                                                        type="file"
                                                                                        className="d-none"/>
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <button onClick={this.ImageSubmit}
                                                                                        type="submit"
                                                                                        className="btn logo-upload-btn">Upload
                                                                                    Image
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <div className="form-group">
                                                                    <button onClick={this.ImageSubmit} type="submit"
                                                                            className="btn">Update
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>}

                                        {
                                            this.state.isContent &&
                                            <div className="tab-pane show active" id="pills-add-user" role="tabpanel"
                                                 aria-labelledby="pills-add-user-tab">
                                                <div className="header-bar">
                                                    <div className="table-title">
                                                        <h3>Add Page Content</h3>
                                                    </div>
                                                </div>
                                                <div className="profile-info-form">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-xl-6 mb-xl-0 mb-4">
                                                                <div className="form-group">
                                                                    <label>Page Title</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="Text"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 mb-xl-0 mb-4">
                                                                <div className="form-group">
                                                                    <label>Priority</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="1"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 order-xl-0 order-1">
                                                                <div className="form-group">
                                                                    <label className="d-block">Image</label>
                                                                    <label className="logo">
                                                                        {
                                                                            this.state.isContentImg !== '' ?
                                                                                <img src={this.state.isContentImg}
                                                                                     width="300"
                                                                                     height="150"
                                                                                     alt=""/> :
                                                                                <img src={Upload}
                                                                                     className="img-fluid"
                                                                                     alt=""/>
                                                                        }
                                                                        <input onChange={this.ContentImg} type="file"
                                                                               className="d-none"/>
                                                                    </label>
                                                                </div>
                                                                <div className="form-group">
                                                                    <button onClick={this.ImageSubmit} type="submit"
                                                                            className="btn logo-upload-btn">Upload Image
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 mb-xl-0 mb-4 order-xl-0 order-0">
                                                                <div className="form-group">
                                                                    <label>Description</label>
                                                                    <textarea className="form-control textarea">Write here. . .</textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4 order-xl-0 order-2">
                                                                <div className="form-group">
                                                                    <button onClick={this.ImageSubmit} type="submit"
                                                                            className="btn">Update
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddContent;