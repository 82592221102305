import React, { Component } from "react";
import '../../../assets/user/css/style.css';
import '../../../assets/user/css/responsive.css';
import User_Header from "../Header";
import User_Sidebar from "../Sidebar";
import User_Withdraw_Chart from "../Chart/Withdrawal";
import DepositChart from "../../Admin/Charts/DepositChart";
import Deposite_Chart from "../Chart/Deposite";
import UserMonthHistory from "../Chart/UserMonthHistory";

class User_Dashboard extends Component {
    constructor(prop) {
        super(prop);
        this.state = {
            isDeposite: false,
            isTransaction: false,
            addClass: false,
            isMenu: false,
        }
    }

    componentDidMount() {
        document.body.addEventListener('click', this.myHandler);
    }

    myHandler = () => {
        if (this.state.isDeposite === true) {
            this.setState({
                isDeposite: false
            })
        }
        if (this.state.isTransaction === true) {
            this.setState({
                isTransaction: false
            })
        }
    };

    depositeClickHandaler = () => {
        this.setState({
            isDeposite: !this.state.isDeposite
        })
    };
    transictionClickHandaler = () => {
        this.setState({
            isTransaction: !this.state.isTransaction
        })
    };

    toggleClass = () => {
        this.setState({
            isMenu: !this.state.isMenu
        })
    }


    render() {

        return (
            <div>
                <User_Header toggleClass={this.toggleClass} />
                <User_Sidebar sidebarClass={this.state.isMenu}/>
                <div className="cp-user-main-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-8 mb-xl-0 mb-4">
                                <div className="card cp-user-custom-card">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <div className="cp-user-title">
                                                <h4>Deposit & Withdraw</h4>
                                            </div>
                                            <div className="cp-user-chart-dropdown">
                                                <div className="btn-group">
                                                    <button onClick={this.depositeClickHandaler} type="button"
                                                        className="btn dropdown-toggle"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        This WeeK
                                                    </button>
                                                    {this.state.isDeposite &&
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <button className="dropdown-item" type="button">Last week
                                                        </button>
                                                            <button className="dropdown-item" type="button">Last week
                                                        </button>
                                                            <button className="dropdown-item" type="button">Last week
                                                        </button>
                                                            <button className="dropdown-item" type="button">Last week
                                                        </button>
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                        <Deposite_Chart />
                                        <ul className="cp-user-legend">
                                            <li><span className="cp-user-color-box cp-user-color-box-1"></span>Deposit
                                            </li>
                                            <li><span className="cp-user-color-box cp-user-color-box-2"></span>Withdraw
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <User_Withdraw_Chart />
                        </div>

                        <div className="row mt-4">
                            <div className="col-xl-4 mb-xl-0 mb-4">
                                <div className="card cp-user-custom-card">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <h4>Last 6 Month History</h4>
                                        </div>
                                        <UserMonthHistory />
                                        <ul className="cp-user-legend cp-user-history-legend">
                                            <li><span className="cp-user-color-box cp-user-color-box-1"></span>Deposit
                                            </li>
                                            <li><span className="cp-user-color-box cp-user-color-box-2"></span>Withdraw
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="card cp-user-custom-card">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <div className="cp-user-title">
                                                <h4>Transaction History</h4>
                                            </div>
                                            <div className="cp-user-chart-dropdown">
                                                <div className="btn-group">
                                                    <button onClick={this.transictionClickHandaler} type="button"
                                                        className="btn dropdown-toggle"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        Withdraw
                                                    </button>
                                                    {this.state.isTransaction &&
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <button className="dropdown-item" type="button">Withdraw
                                                        </button>
                                                            <button className="dropdown-item" type="button">Withdraw
                                                        </button>
                                                            <button className="dropdown-item" type="button">Withdraw
                                                        </button>
                                                            <button className="dropdown-item" type="button">Withdraw
                                                        </button>
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cp-user-transaction-history-table table-responsive">
                                            <table className="table cp-user-custom-table table-borderless text-center"
                                                width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>Address</th>
                                                        <th>Amount</th>
                                                        <th>Transaction Hash</th>
                                                        <th>Updated At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                        <td>12.000078</td>
                                                        <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                        <td>2019-01-24 12:50:17</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                        <td>12.000078</td>
                                                        <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                        <td>2019-01-24 12:50:17</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                        <td>12.000078</td>
                                                        <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                        <td>2019-01-24 12:50:17</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                        <td>12.000078</td>
                                                        <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                        <td>2019-01-24 12:50:17</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                        <td>12.000078</td>
                                                        <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                        <td>2019-01-24 12:50:17</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default User_Dashboard;
