import React, {Component} from "react";
import {Link, HashRouter} from "react-router-dom";
import Logo from "../../../assets/admin/images/logo.svg";
import LogoSmall from "../../../assets/admin/images/logo-small.svg";
import SidebarIcon from "../../../assets/admin/images/sidebar-icons/menu.svg";
import SidebarNotification from "../../../assets/admin/images/sidebar-icons/notification.svg";
import Avatar from "../../../assets/admin/images/avatar.jpg";

class Header extends Component {
    constructor(prop) {
        super(prop);
        this.state = {
            show: false,
            showtwo: false,
        }
        this.myHandler = this.myHandler.bind(this);
    }

    toggle = () => this.setState((currentState) => (
            {
                show: !currentState.show
            }
        )
    );
    toggleAvata = () => this.setState((currentState) => ({showtwo: !currentState.showtwo}));

    componentDidMount() {
        document.body.addEventListener('click', this.myHandler);
    }

    myHandler = () => {
        if (this.state.show === true) {
            this.setState({
                show: false
            })
        }
        if (this.state.showtwo === true) {
            this.setState({
                showtwo: false
            })
        }
    };

    render() {
        return (
            <div>
                <div className={this.props.isActive == true ? 'top-bar content-expend' : 'top-bar'}>
                    <div className="container-fluid">
                        <div className="row align-items-center justify-content-between">
                            <div
                                className={this.props.isActive ? 'col-xl-1 col-md-2 col-3 top-bar-logo' : 'col-xl-1 col-md-2 col-3 top-bar-logo top-bar-logo-hide'}>
                                <div className="logo">
                                    <Link to="/dashboard"><img src={Logo} className="img-fluid logo-large"
                                                               alt="cPocket"/></Link>
                                    <Link to="/dashboard"><img src={LogoSmall} className="img-fluid logo-small" alt=""/></Link>
                                </div>
                            </div>
                            <div className="col-xl-1 col-md-2 col-3">
                                <div className="menu-bars">
                                    <img onClick={this.props.isActiveMenu} src={SidebarIcon} className="img-fluid"
                                         alt=""/>
                                </div>
                            </div>
                            <div className="col-xl-10 col-md-8 col-6">
                                <div className="top-bar-right">
                                    <ul>
                                        <li onClick={this.toggle}>
                                            <div className="btn-group">
                                                <button type="button" className="btn notification-btn dropdown-toggle"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                    <span className="notification">
                                                        <img src={SidebarNotification} alt=""/>
                                                        <span className="notify-value">2</span>
                                                    </span>
                                                </button>
                                                {this.state.show &&
                                                <div className="dropdown-menu notification-list dropdown-menu-right">
                                                    <ul className="notification">
                                                        <li>
                                                            <a href="#">
                                                                <div className="icon">
                                                                    <i className="fa fa-bell"></i>
                                                                </div>
                                                                <div className="content">
                                                                    <h6>Lorem ipsum dolor sit amet.</h6>
                                                                    <p>Lorem ipsum dolor sit amet, consectetur
                                                                        adipisicing elit.</p>
                                                                    <span className="datetime">02/03/2020 7:35</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <div className="icon">
                                                                    <i className="fa fa-bell"></i>
                                                                </div>
                                                                <div className="content">
                                                                    <h6>Lorem ipsum dolor sit amet.</h6>
                                                                    <p>Lorem ipsum dolor sit amet, consectetur
                                                                        adipisicing elit.</p>
                                                                    <span className="datetime">02/03/2020 7:35</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <div className="icon">
                                                                    <i className="fa fa-bell"></i>
                                                                </div>
                                                                <div className="content">
                                                                    <h6>Lorem ipsum dolor sit amet.</h6>
                                                                    <p>Lorem ipsum dolor sit amet, consectetur
                                                                        adipisicing elit.</p>
                                                                    <span className="datetime">02/03/2020 7:35</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>}
                                            </div>
                                        </li>

                                        <li onClick={this.toggleAvata}>
                                            <div className="btn-group">
                                                <button type="button" className="btn">
                                                    <span className="avatar">
                                                        <img src={Avatar} className="img-fluid" alt=""/>
                                                        <span className="name">Viral B. Baker</span>
                                                    </span>
                                                </button>
                                                {
                                                    this.state.showtwo &&
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <ul className="user-profile">
                                                            <li><a href="#"><i className="fa fa-user-circle"></i>Profile</a></li>
                                                            <li><a href="#"><i className="fa fa-sign-out"></i>logout</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;