import React, {Component} from "react";
import Logo from '../../../assets/user/images/logo.svg';
import Dashborad from '../../../assets/user/images/sidebar-icons/dashboard.svg';
import HoverDashborad from '../../../assets/user/images/sidebar-icons/hover/dashboard.svg';
import Coin from '../../../assets/user/images/sidebar-icons/coin.svg';
import HoverCoin from '../../../assets/user/images/sidebar-icons/hover/coin.svg';
import Wallet from '../../../assets/user/images/sidebar-icons/wallet.svg';
import HoverWallet from '../../../assets/user/images/sidebar-icons/hover/wallet.svg';
import User from '../../../assets/user/images/sidebar-icons/user.svg';
import HoverUser from '../../../assets/user/images/sidebar-icons/hover/user.svg';
import referral from '../../../assets/user/images/sidebar-icons/referral.svg';
import Hoverreferral from '../../../assets/user/images/sidebar-icons/hover/referral.svg';
import settings from '../../../assets/user/images/sidebar-icons/settings.svg';
import Hoversettings from '../../../assets/user/images/sidebar-icons/hover/settings.svg';
import {Link} from "react-router-dom";
import User_Header from "../Header";

class User_Sidebar extends Component{
    constructor(props){
        super(props);
        this.state = {
            siteUrl: window.location.pathname,
            sideBarMneu: false,
        }
    }
    handleClick() {
        this.setState({
            siteUrl : window.location.pathname
        });
    }
    closeSIdebar = () =>{
        this.setState({
            sideBarMneu: !this.state.sideBarMneu,
        })
    }

    render() {
        console.log(this.props.sidebarClass)
        return(
            <div>
                <div className={ this.state.sideBarMneu !== this.props.sidebarClass ? "cp-user-sidebar cp-user-sidebar-show" : "cp-user-sidebar"}>
                    <span onClick={this.closeSIdebar} className="sidebarclose"><i className="fa fa-close"></i></span>
                    <div className="cp-user-logo d-lg-none d-block">
                        <Link to="/user-dashboard"><img src={Logo} className="img-fluid cp-user-logo-large" alt=""/></Link>
                    </div>
                    <div className="cp-user-sidebar-menu">
                        <nav>
                            <ul id="metismenu">
                                <li className={ this.state.siteUrl == '/user-dashboard' ?"cp-user-active-page" :''}>
                                    <Link to="/user-dashboard">
                            <span className="cp-user-icon">
                                <img src={Dashborad}
                                     className="img-fluid cp-user-side-bar-icon" alt=""/>
                                <img src={HoverDashborad}
                                     className="img-fluid cp-user-side-bar-icon-hover" alt=""/>
                            </span>
                                        <span className="cp-user-name">Dashboard</span>
                                    </Link>
                                </li>
                                <li className={ this.state.siteUrl == '/user-buy-coin' ?"cp-user-active-page" :''}>
                                    <Link to="/user-buy-coin">
                            <span className="cp-user-icon">
                                <img src={Coin} className="img-fluid cp-user-side-bar-icon"
                                     alt=""/>
                                <img src={HoverCoin}
                                     className="img-fluid cp-user-side-bar-icon-hover" alt=""/>
                            </span>
                                        <span className="cp-user-name">Buy Coin</span>
                                    </Link>
                                </li>
                                <li className={ this.state.siteUrl == '/user-my-wallet' ?"cp-user-active-page" :''}>
                                    <Link to="/user-my-wallet">
                            <span className="cp-user-icon">
                                <img src={Wallet} className="img-fluid cp-user-side-bar-icon"
                                     alt=""/>
                                <img src={HoverWallet}
                                     className="img-fluid cp-user-side-bar-icon-hover" alt=""/>
                            </span>
                                        <span className="cp-user-name">My Pocket</span>
                                    </Link>
                                </li>
                                <li className={ this.state.siteUrl == '/user-my-profile' ?"cp-user-active-page" :''}>
                                    <Link to="/user-my-profile">
                            <span className="cp-user-icon">
                                <img src={User} className="img-fluid cp-user-side-bar-icon"
                                     alt=""/>
                                <img src={HoverUser}
                                     className="img-fluid cp-user-side-bar-icon-hover" alt=""/>
                            </span>
                                        <span className="cp-user-name">My Profile</span>
                                    </Link>
                                </li>
                                <li className={ this.state.siteUrl == '/user-my-referral' ?"cp-user-active-page" :''}>
                                    <Link to="/user-my-referral">
                            <span className="cp-user-icon">
                                <img src={referral} className="img-fluid cp-user-side-bar-icon"
                                     alt=""/>
                                <img src={Hoverreferral}
                                     className="img-fluid cp-user-side-bar-icon-hover" alt=""/>
                            </span>
                                        <span className="cp-user-name">My Referral</span>
                                    </Link>
                                </li>
                                <li className={ this.state.siteUrl == '/user-settings' ?"cp-user-active-page" :''}>
                                    <Link to="/user-settings">
                            <span className="cp-user-icon">
                                <img src={settings} className="img-fluid cp-user-side-bar-icon"
                                     alt=""/>
                                <img src={Hoversettings}
                                     className="img-fluid cp-user-side-bar-icon-hover" alt=""/>
                            </span>
                                        <span className="cp-user-name">Settings</span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}
export default User_Sidebar;