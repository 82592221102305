import React, {Component} from "react";
import User_Header from "../Header";
import User_Sidebar from "../Sidebar";

class User_Withdraw extends Component{
    constructor(prop) {
        super(prop);
        this.state = {
            isWithdraw: false,
            addClass: false,
            isMenu: false,
        }
    }
    isWithdraw = () =>{
        this.setState({
            isWithdraw: !this.state.isWithdraw
        })
    };
    //From submit
    fromSubmit = (e) =>{
        e.preventDefault()
    };
    toggleClass = () => {
        this.setState({
            isMenu: !this.state.isMenu
        })
    }
    render() {
        return(
            <div>
                <User_Header toggleClass={this.toggleClass} />
                <User_Sidebar sidebarClass={this.state.isMenu}/>
                <div className="cp-user-main-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card cp-user-custom-card cp-user-withdraw-card">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <div className="cp-user-title">
                                                <h4>BTC Wallet 1</h4>
                                            </div>
                                            <div className="cp-user-chart-dropdown">
                                                <div className="btn-group">
                                                    <button onClick={this.isWithdraw} type="button" className="btn dropdown-toggle"
                                                            data-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false">
                                                        Withdraw
                                                    </button>
                                                    { this.state.isWithdraw &&  <div className="dropdown-menu dropdown-menu-right">
                                                        <button className="dropdown-item" type="button">Last week
                                                        </button>
                                                        <button className="dropdown-item" type="button">Last week
                                                        </button>
                                                        <button className="dropdown-item" type="button">Last week
                                                        </button>
                                                        <button className="dropdown-item" type="button">Last week
                                                        </button>
                                                    </div>}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-12">
                                                <form onSubmit={this.fromSubmit}>
                                                    <div className="row">
                                                        <div className="col-xl-6">
                                                            <div className="form-group">
                                                                <label>To</label>
                                                                <div className="cp-user-coin-address">
                                                                    <input type="text" placeholder="Coin Address . . ."
                                                                           className="form-control"/>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Amount</label>
                                                                <input type="text" placeholder="Amount in Bit . . ."
                                                                       className="form-control"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6">
                                                            <div className="form-group">
                                                                <label>Note</label>
                                                                <textarea className="form-control" rows="7"
                                                                          placeholder="Type your message here. . . . . .(Optional)"></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6">
                                                            <div className="form-group">
                                                                <button onClick={this.fromSubmit} className="btn submit-btn btn-block">Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default User_Withdraw;