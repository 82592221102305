import React, {Component} from "react";
import Sidebar from "../../Sidebar";
import Header from "../../Header";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import Upload from '../../../../assets/admin/images/upload-icon.svg'
import Search from '../../../../assets/admin/images/search.svg';
import AngleLeft from '../../../../assets/admin/images/angle-left.svg';
import AngleRight from '../../../../assets/admin/images/angle-right.svg';

const CoinList = [
    {
        id: 1,
        name: 'BTC',
        piority: 'Yes',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 2,
        name: 'DOG COIN',
        piority: 'No',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 3,
        name: 'TCOIN',
        piority: 'No',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 4,
        name: 'BTC',
        piority: 'Yes',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 5,
        name: 'BTC',
        piority: 'Yes',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 6,
        name: 'BTC',
        piority: 'Yes',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 7,
        name: 'BTC',
        piority: 'No',
        date: '2019-01-24 12:50:17',
    },
];
localStorage.setItem('CoinList', JSON.stringify(CoinList));

class GeneralSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            isSettings: true,
            isApi: false,
            isFee: false,
            isWithdraw: false,
            isCoin: false,
            isLogo: '',
            isFavicon: '',
            isBanner: '',
            CoinList: JSON.parse(localStorage.getItem('CoinList')),
            currentPage: 1,
            userPage: 5,
        }
    }

    getCoins() {
        return this.state.CoinList;
    }
    componentDidMount() {
        const CoinList = this.getCoins();
        this.setState({CoinList});
    }
    handleClick = () => this.setState((currentState) => ({isActive: !currentState.isActive}));
//Tabs Show Hide
    settings = () => {
        this.setState({isApi: false});
        this.setState({isFee: false});
        this.setState({isWithdraw: false});
        this.setState({isCoin: false});
        this.setState({isSettings: true});
    };
    api = () => {
        this.setState({isFee: false});
        this.setState({isWithdraw: false});
        this.setState({isCoin: false});
        this.setState({isSettings: false});
        this.setState({isApi: true});
    };
    fee = () => {
        this.setState({isFee: true});
        this.setState({isApi: false});
        this.setState({isWithdraw: false});
        this.setState({isCoin: false});
        this.setState({isSettings: false});
    };
    withdraw = () => {
        this.setState({isWithdraw: true});
        this.setState({isApi: false});
        this.setState({isFee: false});
        this.setState({isCoin: false});
        this.setState({isSettings: false});
    };
    coin = () => {
        this.setState({isWithdraw: false});
        this.setState({isApi: false});
        this.setState({isFee: false});
        this.setState({isSettings: false});
        this.setState({isCoin: true});
    };

    //Submit Image
    ImageSubmit = (e) =>{
        e.preventDefault()
    }

    //For Image change
    ImageUploadLogo = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                isLogo: URL.createObjectURL(event.target.files[0])
            });
        }
        toast.success('Successfully Added!');
    };
    //For Image change
    ImageUploadFavicon = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                isFavicon: URL.createObjectURL(event.target.files[0])
            });
        }
        toast.success('Successfully Added!');
    };
    //For Image change
    ImageUploadBanner = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                isBanner: URL.createObjectURL(event.target.files[0])
            });
        }
        toast.success('Successfully Added!');
    };

    // Search function
    searchsubmit(e) {
        e.preventDefault();
    }
    //Coin Search
    search = (e) => {
        const CoinList = this.state.CoinList;
        const search = e.target.value;
        if (search) {
            const filterUser = CoinList.filter(orderList => {
                return orderList.name.toLowerCase().includes(search.toLowerCase());
            });
            this.setState({CoinList: filterUser});
        } else {
            const filterUser = JSON.parse(localStorage.getItem('CoinList'));
            this.setState({CoinList: filterUser});
        }
    };
    // Pagination
    firstPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: 1
            })
        }

    };
    lastPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.orderList.length / this.state.userPage)) {
            this.setState({
                currentPage: Math.ceil(this.state.orderList.length / this.state.userPage)
            })
        }

    };
    prevPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }

    };
    nextPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.orderList.length / this.state.userPage)) {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    };
    paginateNumber = (id) => {
        this.setState({
            currentPage: id
        })
    };

    render() {
        const {CoinList, currentPage, userPage} = this.state;
        const lastIndex = currentPage * userPage;
        const firstIndex = lastIndex - userPage;
        const currentUser = CoinList ? CoinList.slice(firstIndex, lastIndex) : '';
        const totalPage = CoinList ? CoinList.length / userPage : '';

        const result = Math.ceil(totalPage);
        const pageNumber = [];
        for (let i = 1; i <= Math.ceil(totalPage); i++) {
            pageNumber.push(i);
        }
        return (
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className={`main-wrapper ${this.state.isActive ? 'content-expend' : ''}`}>
                    <div className="container-fluid">
                        <div className="custom-breadcrumb">
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li>Settings</li>
                                        <li className="active-item">General settings</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="user-management">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav user-management-nav mb-3" id="pills-tab" role="tablist">
                                        <li onClick={this.settings} className="nav-item">
                                            <Link to="#" className={this.state.isSettings ? "nav-link active" : "nav-link"}
                                                  id="pills-user-tab" role="tab" aria-controls="pills-user"
                                                  aria-selected="true">
                                                <span>General Settings</span>
                                            </Link>
                                        </li>
                                        <li onClick={this.api} className="nav-item">
                                            <Link to="#" className={this.state.isApi ? "nav-link active" : "nav-link"}
                                                  id="pills-add-user-tab" role="tab" aria-controls="pills-add-user"
                                                  aria-selected="true">
                                                <span>API Settings</span>
                                            </Link>
                                        </li>
                                        <li onClick={this.fee} className="nav-item">
                                            <Link to="#" className={this.state.isFee ? "nav-link active" : "nav-link"}
                                                  id="pills-suspended-user-tab" role="tab"
                                                  aria-controls="pills-suspended-user" aria-selected="true">
                                                <span>Fee Settings</span>
                                            </Link>
                                        </li>
                                        <li onClick={this.withdraw} className="nav-item">
                                            <Link to="#" className={this.state.isWithdraw ? "nav-link active" : "nav-link"}
                                                  id="pills-deleted-user-tab" role="tab"
                                                  aria-controls="pills-deleted-user"
                                                  aria-selected="true">
                                                <span>Withdrawal Settings</span>
                                            </Link>
                                        </li>
                                        <li onClick={this.coin} className="nav-item">
                                            <Link to="#" className={this.state.isCoin ? "nav-link active" : "nav-link"}
                                                  id="pills-email-tab" role="tab" aria-controls="pills-email"
                                                  aria-selected="true">
                                                <span>Coin</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        {
                                            this.state.isSettings &&
                                            <div className="tab-pane show active" id="pills-user" role="tabpanel"
                                                 aria-labelledby="pills-user-tab">
                                                <div className="header-bar">
                                                    <div className="table-title">
                                                        <h3>Settings Manager</h3>
                                                    </div>
                                                </div>
                                                <div className="profile-info-form">
                                                        <div className="row">
                                                            <div className="col-xl-6 mb-xl-0 mb-4">
                                                                <div className="form-group">
                                                                    <label>Language Settings</label>
                                                                    <div className="setting-select">
                                                                        <select className="form-control">
                                                                            <option value="">English</option>
                                                                            <option value="">Bangla</option>
                                                                            <option value="">Hindi</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 mb-xl-0 mb-4">
                                                                <div className="form-group">
                                                                    <label>Twilo Sid</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="AC4eaae36a0417646f205a430d8bc70fab"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 mb-xl-0 mb-4">
                                                                <div className="form-group">
                                                                    <label>Company Name</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="My Wallet"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="form-group">
                                                                    <label>Twilo Token</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="8dc65b17f6d2d842726b46662d5d449b"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="form-group">
                                                                    <label>Copyright Text</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="Copyright 2019 Cpocket Wallet"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="form-group">
                                                                    <label>Sender Phone Number</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="+098 3456 8765 345"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="form-group">
                                                                    <label>Primary Email</label>
                                                                    <input type="email" className="form-control"
                                                                           placeholder="test@email.com"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 mb-xl-0 mb-4">
                                                                <div className="form-group">
                                                                    <label>SSl</label>
                                                                    <div className="setting-select">
                                                                        <select className="form-control">
                                                                            <option value="">No</option>
                                                                            <option value="">Yes</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <div className="row">
                                                                    <div className="col-xl-4 col-md-6 mb-xl-0 mb-4">
                                                                        <form>
                                                                            <div className="form-group">
                                                                                <label className="d-block">Logo</label>
                                                                                <label className="logo">
                                                                                    {
                                                                                        this.state.isLogo !== '' ?
                                                                                            <img src={this.state.isLogo} width="300" height="150"
                                                                                                 alt=""/> :
                                                                                            <img src={Upload}
                                                                                                 className="img-fluid"
                                                                                                 alt=""/>
                                                                                    }

                                                                                    <input  onChange={this.ImageUploadLogo}  type="file"
                                                                                           className="d-none"/>
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <button onClick={this.ImageSubmit} type="submit"
                                                                                        className="btn logo-upload-btn">Upload
                                                                                    Image
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="col-xl-4 col-md-6 mb-xl-0 mb-4">
                                                                        <form>
                                                                            <div className="form-group">
                                                                                <label className="d-block">Logo</label>
                                                                                <label className="logo">
                                                                                    {
                                                                                        this.state.isFavicon !== '' ?
                                                                                            <img src={this.state.isFavicon}
                                                                                                 width="300" height="150"
                                                                                                 alt=""/> :
                                                                                            <img src={Upload}
                                                                                                 className="img-fluid"
                                                                                                 alt=""/>
                                                                                    }
                                                                                    <input
                                                                                        onChange={this.ImageUploadFavicon}
                                                                                        type="file"
                                                                                        className="d-none"/>
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <button onClick={this.ImageSubmit} type="submit"
                                                                                        className="btn logo-upload-btn">Upload
                                                                                    Image
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="col-xl-4 col-md-6">
                                                                        <form>
                                                                            <div className="form-group">
                                                                                <label className="d-block">Logo In
                                                                                    Logo</label>
                                                                                <label className="logo">
                                                                                    {
                                                                                        this.state.isBanner !== '' ?
                                                                                            <img src={this.state.isBanner}
                                                                                                 width="300" height="150"
                                                                                                 alt=""/> :
                                                                                            <img src={Upload}
                                                                                                 className="img-fluid"
                                                                                                 alt=""/>
                                                                                    }
                                                                                    <input
                                                                                        onChange={this.ImageUploadBanner}
                                                                                        type="file"
                                                                                        className="d-none"/>
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <button onClick={this.ImageSubmit} type="submit"
                                                                                        className="btn logo-upload-btn">Upload
                                                                                    Image
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <button onClick={this.ImageSubmit} type="submit" className="btn">Update
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>}
                                        {this.state.isApi &&
                                        <div className="tab-pane show active" id="pills-add-user" role="tabpanel"
                                             aria-labelledby="pills-add-user-tab">
                                            <div className="header-bar">
                                                <div className="table-title">
                                                    <h3>BTC</h3>
                                                </div>
                                            </div>
                                            <div className="profile-info-form">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-xl-6 mb-xl-0 mb-4">
                                                            <div className="form-group">
                                                                <label>User</label>
                                                                <input type="text" className="form-control"
                                                                       placeholder="btctestnet"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 mb-xl-0 mb-4">
                                                            <div className="form-group">
                                                                <label>Password</label>
                                                                <input type="text" className="form-control"
                                                                       placeholder="***********"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6">
                                                            <div className="form-group">
                                                                <label>Host Name</label>
                                                                <input type="text" className="form-control"
                                                                       placeholder="142.44.244.65"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6">
                                                            <div className="form-group">
                                                                <label>Port</label>
                                                                <input type="text" className="form-control"
                                                                       placeholder="18332"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <button onClick={this.ImageSubmit}  type="submit" className="btn">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>}

                                        {this.state.isFee &&
                                        <div className="tab-pane show active" id="pills-suspended-user" role="tabpanel"
                                             aria-labelledby="pills-suspended-user-tab">
                                            <div className="header-bar">
                                                <div className="table-title">
                                                    <h3>Fees Settings</h3>
                                                </div>
                                            </div>
                                            <div className="profile-info-form">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-xl-6 mb-xl-0 mb-4">
                                                            <div className="form-group">
                                                                <label>Withdrawal Fees Method</label>
                                                                <div className="setting-select">
                                                                    <select className="form-control">
                                                                        <option value="">Percentage</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 mb-xl-0 mb-4">
                                                            <div className="form-group">
                                                                <label>Withdrawal Fixed Fees</label>
                                                                <input type="text" className="form-control" placeholder="1"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 mb-xl-0 mb-4">
                                                            <label>Withdrawal Fees Percent</label>
                                                            <div className="input-group mb-2 mr-sm-2">
                                                                <input type="text" className="form-control"
                                                                       placeholder="0.8"/>
                                                                <div className="input-group-prepend">
                                                                    <div className="input-group-text">%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-xl-4 mt-0">
                                                            <div className="form-group">
                                                                <button onClick={this.ImageSubmit}  type="submit" className="btn">Update</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>}

                                        {this.state.isWithdraw &&
                                        <div className="tab-pane show active" id="pills-deleted-user" role="tabpanel"
                                             aria-labelledby="pills-deleted-user-tab">
                                            <div className="header-bar">
                                                <div className="table-title">
                                                    <h3>Send Coin Limit Settings</h3>
                                                </div>
                                            </div>
                                            <div className="profile-info-form">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-xl-6 mb-xl-0 mb-4">
                                                            <div className="form-group">
                                                                <label>Max. Send Limit</label>
                                                                <input type="text" className="form-control" placeholder="10"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-xl-4 mt-0">
                                                            <div className="form-group">
                                                                <button onClick={this.ImageSubmit} type="submit" className="btn">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>}

                                        {this.state.isCoin &&
                                        <div className="tab-pane show active" id="pills-email" role="tabpanel"
                                             aria-labelledby="pills-email-tab">
                                            <div className="header-bar">
                                                <div className="table-title">
                                                    <h3>Coin List</h3>
                                                </div>
                                                <div className="search">
                                                    <form onSubmit={this.searchsubmit}>
                                                        <div className="form-group">
                                                            <input type="search" name="search" onChange={this.search} className="form-control"
                                                                   placeholder="Search"/>
                                                            <button className="btn btn-search">
                                                                <img src={Search} className="img-fluid" alt=""/></button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="table-area">
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-borderless custom-table display text-center"
                                                        width="100%">
                                                        <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Is Promary ?</th>
                                                            <th>Updated At</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            currentUser.map(coinList => {
                                                                return (
                                                                    <tr key={coinList.id}>
                                                                        <td>{ coinList.name }</td>
                                                                        <td>{ coinList.piority }</td>
                                                                        <td>{ coinList.date }</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="table-pagination">
                                                    <ul>
                                                        <li><Link onClick={this.prevPage} to="#"><img src={AngleLeft} className="img-fluid"
                                                                                                      alt=""/></Link></li>
                                                        {
                                                            pageNumber.map((number, index) =>{
                                                                return(
                                                                    <li onClick={() => this.paginateNumber(number)} className="active" key={number}><Link to="#">{number}</Link></li>
                                                                )
                                                            })
                                                        }
                                                        <li><Link onClick={this.nextPage} to="#"><img src={AngleRight} className="img-fluid"
                                                                                                      alt=""/></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GeneralSetting;