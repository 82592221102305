import React, {Component} from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Joi from 'joi-browser';
import { Link, withRouter } from 'react-router-dom'



class Login extends Component {
    state = {
        email: 'demo@gmail.com',
        password: '1234578',
        remember: false,
        showpass: false,
        error: {}
    }

    schema = {
        email: Joi.string().email({ minDomainAtoms: 2 }).required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "string.email":
                        err.message = "Email Mast be a Valid email";
                        break;
                    default: err.message = "EMAIL CAN NOT BE EMPTY";
                        break;
                }
            });
            return errors;
        }),
        password: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default: err.message = "EMAIL CAN NOT BE EMPTY";
                        break;
                }
            });
            return errors;
        }),
    }
    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    };

    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] }
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null
    };

    validate = () => {
        const options = { abortEarly: false }
        const form = {
            password: this.state.password,
            email: this.state.email,
        }
        const { error } = Joi.validate(form, this.schema, options)
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message
        return errors;
    };

    openDashboard = () => {
        this.props.history.push('/dashboard')
    }

    checkHandler = () => {
        this.setState({
            remember: !this.state.remember
        })
    }
    showpasshandler = () => {
        this.setState({
            showpass: !this.state.showpass
        })
    }
    submitHandler = (e) => {
        e.preventDefault()
        const error = this.validate()
        if (!error) {
            toast.success('Successfully Login')
            this.props.history.push('/dashboard')
        } else {
            this.setState({
                error: error || {}
            })
        }
    }


    render() {

        return (
            <div className="user-content-wrapper">
                <div className="user-content-wrapper-inner">
                    <div className="user-form">
                        <div className="right">
                            <div className="form-top">
                                <h2>Login</h2>
                                <p>Log into your pages account</p>
                            </div>
                            <form className="accountWrapper" onSubmit={this.submitHandler}>
                                <fieldset>
                                    <div className="form-group">
                                        <label>Email address</label>
                                        <input onChange={this.changeHandler}
                                               value={this.state.email} type="email" name="email" className="form-control" placeholder="your Email here.."/>
                                        <span style={{color: "red"}}>{this.state.error.email && this.state.error.email}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input onChange={this.changeHandler}
                                               value={this.state.password} type="password" name="password" className="form-control form-control-password"
                                               placeholder="Your Password here.."/>
                                        <span style={{color: "red"}}>{this.state.error.password && this.state.error.password}</span>
                                        <span className="eye"><i className="fa fa-eye-slash"></i></span>
                                    </div>
                                    <div className="d-flex justify-content-between rememberme align-items-center mb-4">
                                        <div>
                                            <div className="form-group form-check mb-0">
                                                <input className="styled-checkbox form-check-input"
                                                       id="styled-checkbox-1"
                                                       type="checkbox" value="value1"/>
                                                <label htmlFor="styled-checkbox-1">Remember Me</label>
                                            </div>
                                        </div>
                                        <div className="text-right"><Link to="/">Forgot Password?</Link></div>
                                    </div>
                                    <button type="submit" className="btn btn-primary nimmu-user-sibmit-button">Login
                                    </button>
                                </fieldset>
                            </form>
                            <div className="form-bottom text-center">
                                <h4 className="or">OR</h4>
                                <div className="social-media">
                                    <ul>
                                        <li><Link to="/" className="facebook"><i
                                            className="fa fa-facebook"></i></Link></li>
                                        <li><Link to="/" className="twitter"><i className="fa fa-twitter"></i></Link>
                                        </li>
                                        <li><Link to="/" className="linkedin"><i
                                            className="fa fa-linkedin"></i></Link></li>
                                    </ul>
                                </div>
                                <p>Already have an account? <Link to="/signup">Sign Up</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Login;