import React, {Component} from "react";
import Sidebar from "../../Sidebar";
import Header from "../../Header";

class BulkEmail extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
        }
    }
    // Submit defautlt
    searchsubmit(e) {
        e.preventDefault();
    }
    render() {
        return(
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className={`main-wrapper ${this.state.isActive ? 'content-expend' : ''}`}>
                    <div className="container-fluid">
                        <div className="custom-breadcrumb">
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li>Settings</li>
                                        <li className="active-item">Bulk Email</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="user-management add-custom-page">
                            <div className="row">
                                <div className="col-12">
                                    <div className="header-bar">
                                        <div className="table-title">
                                            <h3>Bulk Email</h3>
                                        </div>
                                    </div>
                                    <div className="profile-info-form">
                                        <form>
                                            <div className="row">
                                                <div className="col-xl-6 mb-xl-0 mb-4">
                                                    <div className="form-group">
                                                        <label>Header Text</label>
                                                        <textarea
                                                            className="form-control textarea">Write here. . .</textarea>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Subject</label>
                                                        <input type="text" className="form-control" placeholder="Text"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Message</label>
                                                        <textarea
                                                            className="form-control textarea">Write here. . .</textarea>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6">
                                                    <div className="form-group">
                                                        <label>Role</label>
                                                        <div className="setting-select">
                                                            <select className="form-control">
                                                                <option value="">Admin</option>
                                                                <option value="">Super Admin</option>
                                                                <option value="">User</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Email Type</label>
                                                        <input type="text" className="form-control" placeholder="Email"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Footer text</label>
                                                        <textarea
                                                            className="form-control textarea">Write here. . .</textarea>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <button onClick={this.searchsubmit} type="submit" className="btn">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default BulkEmail;