import React, {Component} from "react";
import Chart from "react-apexcharts";

class UserMonthHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                data: [150, 130, 120, 110, 100, 110, 120]
            }, {
                data: [100, 100, 90, 80, 90, 70, 100]
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 430,
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        colors: ['#fff']
                    }
                },
                stroke: {
                    show: false,
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
                    labels: {
                        show: true,
                        style: {
                            colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
                        },
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                },
                yaxis: {
                    labels: {
                        show: true,
                        style: {
                            colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
                        },
                    },
                },
                fill: {
                    colors:["#2CA4FA",'#0DE06D'],
                },
                grid: {
                    show: true,
                    borderColor: '#111A35',
                    strokeDashArray: 8,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: true,
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false,
                        }
                    },
                    row: {
                        colors: undefined,
                        opacity: 0.5
                    },
                    column: {
                        colors: undefined,
                        opacity: 0.5
                    },
                },

                tooltip: {
                    enabled: false,
                },
            },
        };
    }

    render() {
        return (
            <div id="cp-user-history">
                <Chart options={this.state.options} series={this.state.series} type="bar" height={390} />
            </div>
        )
    }
}

export default UserMonthHistory;