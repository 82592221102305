import React, {Component} from "react";
import Chart from "react-apexcharts";

class ActiveUser extends Component{

    constructor(props) {
        super(props);
        this.state = {
            series1: [74.86],
            chart: {
                height: 400,
                type: 'radialBar',
            },
            options1: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '50',
                        },
                        dataLabels: {
                            value: {
                                color: "#B4B8D7",
                                fontSize: "20px",
                                offsetY: -5,
                                show: true
                            }
                        }
                    },
                },
                labels: [''],
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#309EF9", "#7ACEFF"],
                        stops: [5, 100]
                    }
                },
            },

            series2: [24.14],
            options2: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '50',
                        },
                        dataLabels: {
                            value: {
                                color: "#B4B8D7",
                                fontSize: "20px",
                                offsetY: -5,
                                show: true
                            }
                        }
                    },
                },
                labels: [''],
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#F89A6B"],
                        stops: [0, 40]
                    }
                },
            },
        };
    }


    render() {
        return(
            <div className="user-chart">
                <div className="row">
                    <div className="col-lg-6 mb-lg-0 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-top">
                                    <h4>Active user</h4>
                                </div>
                                <div id="active-user-chart">
                                    <Chart options={this.state.options1} series={this.state.series1} type="radialBar" height={390} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-top">
                                    <h4>Deleted user</h4>
                                </div>
                                <div id="deleted-user-chart">
                                    <Chart options={this.state.options2} series={this.state.series2} type="radialBar" height={390} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ActiveUser;