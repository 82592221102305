import React, {Component} from "react";
import {Link} from "react-router-dom";
import Pagination01 from "../../../../../assets/admin/images/angle-left.svg";
import RightArrow from "../../../../../assets/admin/images/angle-right.svg";
import View from "../../../../../assets/admin/images/user-management-icons/activity/view.svg";
import User from "../../../../../assets/admin/images/user-management-icons/activity/user-1.svg";
import BlockUser from "../../../../../assets/admin/images/user-management-icons/activity/check-square.svg";
import Search from "../../../../../assets/admin/images/search.svg";
import {toast} from "react-toastify";

const deletUser = [
    {
        first_name: 'Ana',
        last_name: 'Albert',
        email: 'ana43@gmail.com',
        phone: '1234567890',
        role: 'User',
        date: '2019-01-31 08:38:11',
        id: '1',
    },
    {
        first_name: 'Mona',
        last_name: 'William',
        email: 'mona876@email.com',
        phone: '1234567890',
        role: 'User',
        date: '2019-01-31 08:38:11',
        id: '2',
    },
    {
        first_name: 'Tina',
        last_name: 'John',
        email: 'tinajohn87@gmail.com',
        phone: '1234567890',
        role: 'User',
        date: '2019-01-31 08:38:11',
        id: '3',
    },

];
localStorage.setItem('deletUser', JSON.stringify(deletUser));

class DeletedUser extends Component{
    constructor(props) {
        super(props);
        this.state ={
            deleteUser: JSON.parse(localStorage.getItem('deletUser')),
            UserLists: JSON.parse(localStorage.getItem('users')),
            users: [],
            currentPage: 1,
            userPage: 5,
        }
    }

    getUsers() {
        return this.state.deleteUser;
    }
    componentDidMount()     {
        const deleteUser = this.getUsers();
        this.setState({deleteUser});
    }

    userAtive = id => (e) =>{
        e.preventDefault();
        const deleteUser = this.getUsers();
        const reActiveUser = deleteUser.filter(UserLists => {
            return UserLists.id === id;
        });
        if (JSON.parse(localStorage.getItem('users')) !== null){
            var users = JSON.parse(localStorage.getItem('users'));
        }else {
            var users = [];
        }
        users.push({
            first_name: reActiveUser[0].first_name,
            last_name: reActiveUser[0].last_name,
            email: reActiveUser[0].email,
            phone: reActiveUser[0].phone,
            role: reActiveUser[0].role,
            date: reActiveUser[0].date,
            id: reActiveUser[0].id,
        });
        localStorage.setItem('users', JSON.stringify(users));

        const filterUser = deleteUser.filter(UserLists => {
            return UserLists.id !== id;
        });
        this.setState({deleteUser: filterUser});
        localStorage.setItem('deletUser', JSON.stringify(filterUser));
        toast.success('Successfully!');
    }

    search = (e) => {
        const deleteUser = this.getUsers();
        const search = e.target.value;
        if (search) {
            const filterUser = deleteUser.filter(deleteUser => {
                return deleteUser.first_name.toLowerCase().includes(search.toLowerCase());
            });
            this.setState({deleteUser: filterUser});
        } else {
            const filterUser = JSON.parse(localStorage.getItem('deletUser'));
            this.setState({deleteUser: filterUser});
        }
        // localStorage.setItem('users', JSON.stringify(filterUser));
    };
    searchsubmit(e) {
        e.preventDefault();
    }
    firstPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: 1
            })
        }

    }
    lastPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.deleteUser.length / this.state.userPage)) {
            this.setState({
                currentPage: Math.ceil(this.state.deleteUser.length / this.state.userPage)
            })
        }

    }
    prevPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }

    }
    nextPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.deleteUser.length / this.state.userPage)) {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    }
    paginateNumber = (id) => {
        this.setState({
            currentPage: id
        })
    }

    render() {
        const {deleteUser, currentPage, userPage} = this.state;
        const lastIndex = currentPage * userPage;
        const firstIndex = lastIndex - userPage;
        const currentUser = deleteUser ? deleteUser.slice(firstIndex, lastIndex) : '';
        const totalPage = deleteUser ? deleteUser.length / userPage : '';

        const result= Math.ceil(totalPage);
        const pageNumber = [];
        for (let i = 1; i <= Math.ceil(totalPage); i++){
            pageNumber.push(i);
        }
        return(
            <div className="tab-pane" id="pills-deleted-user" role="tabpanel"
                 aria-labelledby="pills-deleted-user-tab">
                <div className="header-bar">
                    <div className="table-title">
                        <h3>Deleted User</h3>
                    </div>
                    <div className="search">
                        <form onSubmit={this.searchsubmit}>
                            <div className="form-group">
                                <input type="search" name="search" onChange={this.search} className="form-control"
                                       placeholder="Search"/>
                                <button className="btn btn-search">
                                    <img src={Search} className="img-fluid" alt=""/></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="table-area">
                    <div className="table-responsive">
                        <table className="table table-borderless custom-table display text-center"
                               width="100%">
                            <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email ID</th>
                                <th>Updated At</th>
                                <th>Activity</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.deleteUser == null
                                ?
                                    <tr>
                                        <td>No data found! Please add some data</td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>

                                :
                                    currentUser.map(deleteUser => {
                                        return(
                                            <tr >
                                                <td>{deleteUser.first_name}</td>
                                                <td>{deleteUser.last_name}</td>
                                                <td>{deleteUser.email}</td>
                                                <td>{deleteUser.date}</td>
                                                <td>
                                                    <ul className="d-flex align-items-center justify-content-center">
                                                        <li><Link to="/"><img
                                                            src={View}
                                                            className="img-fluid" alt=""/></Link></li>
                                                        <li><Link to="/"><img
                                                            src={User} alt=""/></Link>
                                                        </li>
                                                        <li><Link onClick={this.userAtive(deleteUser.id)}><img
                                                            src={BlockUser}
                                                            alt=""/></Link></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        )
                                    })

                            }
                            </tbody>
                        </table>
                    </div>

                    <div className="table-pagination">
                        <ul>
                            <li><Link onClick={this.prevPage} to="#"><img src={Pagination01} className="img-fluid"
                                                                          alt=""/></Link></li>
                            {
                                pageNumber.map((number, index) =>{
                                    return(
                                        <li onClick={() => this.paginateNumber(number)} className="active" key={number}><Link to="#">{number}</Link></li>
                                    )
                                })
                            }
                            <li><Link onClick={this.nextPage} to="#"><img src={RightArrow} className="img-fluid"
                                                                          alt=""/></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default DeletedUser;