import React, {Component} from "react";
import Sidebar from "../../Sidebar";
import Header from "../../Header";

import Search from "../../../../assets/admin/images/search.svg";
import View from "../../../../assets/admin/images/user-management-icons/activity/view.svg";
import User from "../../../../assets/admin/images/user-management-icons/activity/user.svg";
import BlockUser from "../../../../assets/admin/images/user-management-icons/activity/block-user.svg";
import DeleteUser from "../../../../assets/admin/images/user-management-icons/activity/delete-user.svg";
import Pagination01 from "../../../../assets/admin/images/angle-left.svg";
import RightArrow from "../../../../assets/admin/images/angle-right.svg";
import {Link} from "react-router-dom";

const userWallets = [
    {
        id : 1,
        first_name : 'Ana',
        last_name : 'Albert',
        email : 'ana43@gmail.com',
        balance : '15.00000',
        date : '2019-01-31 08:38:11',
    },
    {
        id : 2,
        first_name : 'Mona',
        last_name : 'Albert',
        email : 'Mona@gmail.com',
        balance : '17.00000',
        date : '2019-01-31 08:38:11',
    },
    {
        id : 3,
        first_name : 'Mona',
        last_name : 'Albert',
        email : 'Mona@gmail.com',
        balance : '19.00000',
        date : '2019-01-31 08:38:11',
    },
    {
        id : 4,
        first_name : 'Raina',
        last_name : 'Albert',
        email : 'Raina@gmail.com',
        balance : '22.00000',
        date : '2019-01-31 08:38:11',
    },
    {
        id : 5,
        first_name : 'Aliey',
        last_name : 'Albert',
        email : 'Aliey@gmail.com',
        balance : '35.00000',
        date : '2019-01-31 08:38:11',
    },
    {
        id : 6,
        first_name : 'Jhon',
        last_name : 'Ray',
        email : 'Jhon@gmail.com',
        balance : '27.00000',
        date : '2019-01-31 08:38:11',
    },
    {
        id : 7,
        first_name : 'Albert',
        last_name : 'Peraira',
        email : 'ana43@gmail.com',
        balance : '13.00000',
        date : '2019-01-31 08:38:11',
    },
];
class UserWallet extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            userWallet: userWallets,
            currentPage: 1,
            userPage: 5,
        }
    }
    handleClick = () => this.setState((currentState) => ({isActive: !currentState.isActive}));

    // Search function
    searchsubmit(e) {
        e.preventDefault();
    }
    search = (e) => {
        const userWallet = this.state.userWallet;
        const search = e.target.value;
        if (search) {
            const filterUser = userWallet.filter(userWallet => {
                return userWallet.first_name.toLowerCase().includes(search.toLowerCase());
            });
            this.setState({userWallet: filterUser});
        } else {
            const filterUser = userWallets;
            this.setState({userWallet: filterUser});
        }
    };

    // Pagination
    firstPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: 1
            })
        }

    }
    lastPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.suspendedUser.length / this.state.userPage)) {
            this.setState({
                currentPage: Math.ceil(this.state.suspendedUser.length / this.state.userPage)
            })
        }

    }
    prevPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }

    }
    nextPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.suspendedUser.length / this.state.userPage)) {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    }
    paginateNumber = (id) => {
        this.setState({
            currentPage: id
        })
    }
    render() {

        // Pagination Function
        const {userWallet, currentPage, userPage} = this.state;
        const lastIndex = currentPage * userPage;
        const firstIndex = lastIndex - userPage;
        const currentUser = userWallet ? userWallet.slice(firstIndex, lastIndex) : '';
        const totalPage = userWallet ? userWallet.length / userPage : '';

        const result= Math.ceil(totalPage);
        const pageNumber = [];
        for (let i = 1; i <= Math.ceil(totalPage); i++){
            pageNumber.push(i);
        }

        return(
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className="main-wrapper">
                    <div className="container-fluid">

                        <div className="custom-breadcrumb">
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li>My wallet</li>
                                        <li className="active-item">User wallets</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="user-management padding-30">
                            <div className="row">
                                <div className="col-12">
                                    <div className="header-bar">
                                        <div className="table-title">
                                            <h3>User Wallet</h3>
                                        </div>
                                        <div className="search">
                                            <form onSubmit={this.searchsubmit}>
                                                <div className="form-group">
                                                    <input type="search" name="search" onChange={this.search} className="form-control"
                                                           placeholder="Search"/>
                                                    <button className="btn btn-search">
                                                        <img src={Search} className="img-fluid" alt=""/></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="table-area">
                                        <div className="table-responsive">
                                            <table className="table table-borderless custom-table display text-center"
                                                   width="100%">
                                                <thead>
                                                <tr>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Email ID</th>
                                                    <th>Balance</th>
                                                    <th>Update Date</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    currentUser.map(userWallet => {
                                                        return(
                                                            <tr key={userWallet.id}>
                                                                <td>{userWallet.first_name}</td>
                                                                <td>{userWallet.last_name}</td>
                                                                <td>{userWallet.email}</td>
                                                                <td>{userWallet.balance}</td>
                                                                <td>{userWallet.date}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="table-pagination">
                                            <ul>
                                                <li><Link onClick={this.prevPage} to="#"><img src={Pagination01} className="img-fluid"
                                                                                              alt=""/></Link></li>
                                                {
                                                    pageNumber.map((number, index) =>{
                                                        return(
                                                            <li onClick={() => this.paginateNumber(number)} className="active" key={number}><Link to="#">{number}</Link></li>
                                                        )
                                                    })
                                                }
                                                <li><Link onClick={this.nextPage} to="#"><img src={RightArrow} className="img-fluid"
                                                                                              alt=""/></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default UserWallet;