import React, {Component} from "react";
import Joi from 'joi-browser'
import { ToastContainer, toast } from 'react-toastify';
import { Link, withRouter } from 'react-router-dom'

class Signup extends Component{

    state = {
        email: '',
        password: '',
        name: '',
        confirm_password: '',
        remember: false,
        showpass: false,
        error: {}
    }

    schema = {
        email: Joi.string().email({ minDomainAtoms: 2 }).required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "string.email":
                        err.message = "Email Mast be a Valid email";
                        break;
                    default: err.message = "EMAIL CAN NOT BE EMPTY";
                        break;
                }
            });
            return errors;
        }),
        password: Joi.string()
            .min(8)
            .required()
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        case "string.min":
                            err.message = "Password length must be at least 8 characters long";
                        // eslint-disable-next-line no-unused-expressions
                        default: err.message;
                            break;
                    }
                });
                return errors;
            }),
        name: Joi.string()
            .required()
            .label('Full Name')
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        // eslint-disable-next-line no-unused-expressions
                        default: err.message;
                            break;
                    }
                });
                return errors;
            }),
    }
    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        //
        // if(event.target.name === 'confirm_password'){
        //   if(this.state.password !== this.state.confirm_password){
        //     error[event.target.name] = 'NEW';
        //   }
        //   else {
        //     delete error[event.target.name];
        //   }
        // }


        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    };

    onkeyUpHandler = (event) => {

        const error = { ...this.state.error };
        if(event.target.name === 'confirm_password'){
            if(this.state.password !== this.state.confirm_password){
                error[event.target.name] = "Password don't match";
            }
            else {
                delete error[event.target.name];
            }
        }

        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    }

    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] }
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null
    };

    validate = () => {
        const options = { abortEarly: false }
        const form = {
            password: this.state.password,
            email: this.state.email,
            name: this.state.name,
        }
        const { error } = Joi.validate(form, this.schema, options)

        let cfPasserrors = {};
        if(this.state.password !== this.state.confirm_password || this.state.confirm_password === ''){
            cfPasserrors.confirm_password = "Password don't match";
        }
        else {
            delete cfPasserrors.confirm_password;
            cfPasserrors = null;
        }

        if (!error && !cfPasserrors) return null;
        if (!error && cfPasserrors) return cfPasserrors;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message

        if(this.state.password !== this.state.confirm_password || this.state.confirm_password === ''){
            errors.confirm_password = "Password don't match";
        }
        else {
            delete errors.confirm_password;
        }

        return errors;
    };

    checkHandler = () => {
        this.setState({
            remember: !this.state.remember
        })
    };
    showpasshandler = () => {
        this.setState({
            showpass: !this.state.showpass
        })
    };
    submitHandler = (e) => {
        e.preventDefault()
        const error = this.validate()

        if (!error) {
            toast.success('Successfully signup')
            this.props.history.push('/dashboard')
        } else {
            this.setState({
                error: error || {}
            })
        }

    };


    render() {
        return(
            <div className="user-content-wrapper">
                <div className="user-content-wrapper-inner">
                    <div className="user-form">
                        <div className="right">
                            <div className="form-top">
                                <h2>Signup</h2>
                                <p>Sign into your pages account</p>
                            </div>
                            <form  className="accountWrapper" onSubmit={this.submitHandler}>
                                <div className="form-group">
                                    <label>Full Name</label>
                                    <input type="text" name="name" onChange={this.changeHandler}
                                           value={this.state.name} className="form-control" placeholder="Your name here..."/>
                                    <span style={{color: "red"}}>{this.state.error.name && this.state.error.name}</span>
                                </div>
                                <div className="form-group">
                                    <label>Email address</label>
                                    <input type="email" name="email" onChange={this.changeHandler}
                                           value={this.state.email} className="form-control" placeholder="your Email here.."/>
                                    <span style={{color: "red"}}>{this.state.error.email && this.state.error.email}</span>
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" onChange={this.changeHandler}
                                           value={this.state.password} name="password" className="form-control form-control-password"
                                           placeholder="Your Password here.."/>
                                        <span className="eye"><i className="fa fa-eye-slash"></i></span>
                                    <span style={{color: "red"}}>{this.state.error.password && this.state.error.password}</span>
                                </div>
                                <div className="form-group">
                                    <label>Confirem Password</label>
                                    <input type="password" name="confirm_password" onChange={this.onkeyUpHandler}
                                           onKeyUp={this.onkeyUpHandler}
                                           value={this.state.confirm_password} className="form-control form-control-password"
                                           placeholder="Your Password here.."/>
                                        <span className="eye"><i className="fa fa-eye-slash"></i></span>
                                    <span style={{color: "red"}}>{this.state.error.confirm_password && this.state.error.confirm_password}</span>
                                </div>
                                <button type="submit" className="btn btn-primary nimmu-user-sibmit-button">Signup
                                </button>
                            </form>
                            <div className="form-bottom text-center">
                                <h4 className="or">OR</h4>
                                <div className="social-media">
                                    <ul>
                                        <li><Link to="/" className="facebook"><i
                                            className="fa fa-facebook"></i></Link></li>
                                        <li><Link to="/" className="twitter"><i className="fa fa-twitter"></i></Link>
                                        </li>
                                        <li><Link to="/" className="linkedin"><i
                                            className="fa fa-linkedin"></i></Link></li>
                                    </ul>
                                </div>
                                <p>Already have an account? <Link to="/">Return to Sign In</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
    }
}
export default Signup;