import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Collapse, CardBody, Card} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Icon01 from "../../../assets/admin/images/sidebar-icons/dashboard.svg";
import Icon02 from "../../../assets/admin/images/sidebar-icons/user.svg";
import Icon03 from "../../../assets/admin/images/sidebar-icons/wallet.svg";
import Icon04 from "../../../assets/admin/images/sidebar-icons/coin-order-list.svg";
import Icon05 from "../../../assets/admin/images/sidebar-icons/profile.svg";
import Icon06 from "../../../assets/admin/images/sidebar-icons/settings.svg";

import Logo from "../../../assets/admin/images/logo.svg";


const menus = [
    {
        id: 1,
        title: 'Dashboard',
        icon: Icon01,
        link: '/dashboard',
    },
    {
        id: 6,
        title: 'My wallet',
        icon: Icon03,
        link: '/',
        submenu: [
            {
                id: 7,
                title: 'User Wallet',
                link: '/user-wallet'
            },
            {
                id: 8,
                title: 'All Transaction',
                link: '/wallet-transaction'
            },
            {
                id: 9,
                title: 'Node History',
                link: '/node-history'
            },
            {
                id: 10,
                title: 'Pending Withdrawal',
                link: '/pending-withdrawal'
            },
        ]
    },
    {
        id: 11,
        title: 'Buy coin order list',
        icon: Icon04,
        link: '/order-list',
    },
    {
        id: 12,
        title: 'Profile',
        icon: Icon05,
        link: '/profile',
    },
    {
        id: 2,
        title: 'User managemert',
        icon: Icon02,
        link: '/',
        submenu: [
            {
                id: 3,
                title: 'User',
                link: '/user'
            },
            {
                id: 5,
                title: 'Pending ID Varification',
                link: '/pending-id-verification'
            },
        ]
    },
    {
        id: 13,
        title: 'Settings',
        icon: Icon06,
        link: '/',
        submenu: [
            {
                id: 14,
                title: 'General Settings',
                link: '/general-settings'
            },
            {
                id: 15,
                title: 'Landing Page',
                link: '/landing-page'
            },
            {
                id: 16,
                title: 'Custom Page',
                link: '/custom-page'
            },
            {
                id: 17,
                title: 'FAQs',
                link: '/faq'
            },
            {
                id: 18,
                title: 'Bulk Email',
                link: '/bulk-email'
            },
        ]
    },
]

class Sidebar extends Component {

    state = {
        isMenuShow: false,
        isOpen: 0,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = id => () => {
        this.setState({
            isOpen: id === this.state.isOpen ? 0 : id
        })
    }

    _handleClick(menus) {
        this.setState({ active: menus });
    }

    render() {
        const {isMenuShow, isOpen} = this.state;
        let websiteUrl = this.props.websiteUrl;

        return (
            <div>
                <div className={ this.props.isActive == true ? 'sidebar sidebar-hide' : 'sidebar'}>
                    <div className="logo">
                        <Link to="/dashboard">
                            <img src={Logo} className="img-fluid" alt=""/>
                        </Link>
                    </div>

                    <div className="sidebar-menu">
                        <nav>
                            <ul id="metismenu">
                                {menus.map(item => {
                                    return (
                                        <li key={item.id.toString()}>
                                            {item.submenu ? <a onClick={this.setIsOpen(item.id)}>
                                                <span className="icon"><img src={item.icon} className="img-fluid" alt=""/></span>
                                                <span className="name">{item.title}</span>
                                                {item.submenu ? '' : ''}
                                            </a> :
                                                <Link to={item.link}><span className="icon"><img src={item.icon} className="img-fluid" alt=""/></span><span className="name">{item.title}</span></Link>}
                                            {item.submenu ?
                                                <Collapse isOpen={item.id === isOpen}>
                                                    <ul>
                                                        {item.submenu.map(submenu => (
                                                            <li key={submenu.id}><Link className="active" to={submenu.link}>{submenu.title}</Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Collapse>
                                                : ''}

                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sidebar;