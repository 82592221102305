import React, {Component} from "react";
import Sidebar from "../../Sidebar";
import Header from "../../Header";
import Search from '../../../../assets/admin/images/search.svg';
import Edit from '../../../../assets/admin/images/edit.svg';
import Delete from '../../../../assets/admin/images/delete.svg';
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import AngleLeft from "../../../../assets/admin/images/angle-left.svg";
import AngleRight from "../../../../assets/admin/images/angle-right.svg";

const customPage = [
    {
        id: 1,
        title: 'There are many variations of passages',
        type: 'Dummy',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 2,
        title: 'Lorem Ipsum',
        type: 'Dummy',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 3,
        title: 'Laspar Idurar',
        type: 'Dummy',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 4,
        title: 'Ispahu aliba dkuetl a usaa',
        type: 'Demo',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 5,
        title: 'Ispahu aliba dkuetl a usaa',
        type: 'Demo',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 6,
        title: 'There are many variations of passages',
        type: 'Dummy',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 7,
        title: 'Lorem Ipsum',
        type: 'Dummy',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 8,
        title: 'Laspar Idurar',
        type: 'Dummy',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 9,
        title: 'Ispahu aliba dkuetl a usaa',
        type: 'Demo',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 10,
        title: 'Ispahu aliba dkuetl a usaa',
        type: 'Demo',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 11,
        title: 'aaaaa',
        type: 'Demo',
        date: '2019-01-24 12:50:17',
    },
];
localStorage.setItem('customPage', JSON.stringify(customPage));

class CustomPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            customPage: JSON.parse(localStorage.getItem('customPage')),
            currentPage: 1,
            userPage: 5,
        }
    }

    handleClick = () => this.setState((currentState) => ({isActive: !currentState.isActive}));

    getCoins() {
        return this.state.customPage;
    }

    componentDidMount() {
        const customPage = this.getCoins();
        this.setState({customPage});
    }

    // Search function
    searchsubmit(e) {
        e.preventDefault();
    }

    //Coin Search
    search = (e) => {
        const customPage = this.state.customPage;
        const search = e.target.value;
        if (search) {
            const filterUser = customPage.filter(landingContent => {
                return landingContent.title.toLowerCase().includes(search.toLowerCase());
            });
            this.setState({customPage: filterUser});
        } else {
            const filterUser = JSON.parse(localStorage.getItem('customPage'));
            this.setState({customPage: filterUser});
        }
    };

    //User Delete Function
    onDelete = id => (e) => {
        e.preventDefault();
        const filterUser = customPage.filter(UserLists => {
            return UserLists.id !== id;
        });
        this.setState({customPage: filterUser});
        localStorage.setItem('customPage', JSON.stringify(customPage));
        toast.error('Successfully Deleted!');
    };

    // Pagination
    firstPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: 1
            })
        }

    };
    lastPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.customPage.length / this.state.userPage)) {
            this.setState({
                currentPage: Math.ceil(this.state.customPage.length / this.state.userPage)
            })
        }

    };
    prevPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }

    };
    nextPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.customPage.length / this.state.userPage)) {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    };
    paginateNumber = (id) => {
        this.setState({
            currentPage: id
        })
    };


    render() {
        const {customPage, currentPage, userPage} = this.state;
        const lastIndex = currentPage * userPage;
        const firstIndex = lastIndex - userPage;
        const currentUser = customPage ? customPage.slice(firstIndex, lastIndex) : '';
        const totalPage = customPage ? customPage.length / userPage : '';

        const result = Math.ceil(totalPage);
        const pageNumber = [];
        for (let i = 1; i <= Math.ceil(totalPage); i++) {
            pageNumber.push(i);
        }
        return (
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className={`main-wrapper ${this.state.isActive ? 'content-expend' : ''}`}>
                    <div className="container-fluid">
                        <div className="custom-breadcrumb">
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li>Settings</li>
                                        <li className="active-item">Custom Page</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="user-management padding-30">
                            <div className="row">
                                <div className="col-12">
                                    <div className="header-bar">
                                        <div className="table-title">
                                            <h3>Custom Page</h3>
                                        </div>
                                        <div className="right d-flex align-items-center">
                                            <div className="search mr-3">
                                                <form onSubmit={this.searchsubmit}>
                                                    <div className="form-group">
                                                        <input type="search" name="search"
                                                               onChange={this.search}
                                                               className="form-control"
                                                               placeholder="Search"/>
                                                        <button className="btn btn-search">
                                                            <img src={Search} className="img-fluid" alt=""/>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="add-btn">
                                                <Link to="/custom-page-add">+ Add</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-area">
                                        <div className="table-responsive">
                                            <table className="table table-borderless custom-table display text-center"
                                                   width="100%">
                                                <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Page Type</th>
                                                    <th>Updated At</th>
                                                    <th>Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    currentUser.map(customPage => {
                                                        return (
                                                            <tr key={customPage.id}>
                                                                <td>{customPage.title}</td>
                                                                <td>{customPage.type}</td>
                                                                <td>{customPage.date}</td>
                                                                <td>
                                                                    <ul className="d-flex align-items-center justify-content-center">
                                                                        <li><Link><img src={Edit}
                                                                                       className="img-fluid"
                                                                                       alt=""/></Link>
                                                                        </li>
                                                                        <li onClick={this.onDelete(customPage.id)}><Link><img src={Delete}
                                                                                       className="img-fluid"
                                                                                       alt=""/></Link>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="table-pagination">
                                            <ul>
                                                <li><Link onClick={this.prevPage} to="#"><img
                                                    src={AngleLeft} className="img-fluid"
                                                    alt=""/></Link></li>
                                                {
                                                    pageNumber.map((number, index) => {
                                                        return (
                                                            <li onClick={() => this.paginateNumber(number)}
                                                                className="active" key={number}><Link
                                                                to="#">{number}</Link></li>
                                                        )
                                                    })
                                                }
                                                <li><Link onClick={this.nextPage} to="#"><img
                                                    src={AngleRight} className="img-fluid"
                                                    alt=""/></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CustomPage;