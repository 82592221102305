import React, {Component} from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import userImage from '../../../assets/admin/images/user-management-icons/user.svg';
import editProfile from '../../../assets/admin/images/edit-profile.svg';
import resetPasswordImg from '../../../assets/admin/images/reset-pass.svg';
import avatartImg from '../../../assets/admin/images/avater.jpg';
import {Link} from "react-router-dom";
import axios from 'axios';
import {toast} from "react-toastify";


class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            currentPage: 1,
            userPage: 5,
            isProfile: true,
            isEdit: false,
            isReset: false,
            selectFile: '',
        }
    }

    //Profiles Tab
    profile = () => {
        this.setState({isReset: false});
        this.setState({isEdit: false});
        this.setState({isProfile: true});
    };
    profileEdit = () => {
        this.setState({isProfile: false});
        this.setState({isReset: false});
        this.setState({isEdit: true});
    };
    resetProfile = () => {
        this.setState({isProfile: false});
        this.setState({isEdit: false});
        this.setState({isReset: true});
    };

    //From submit funcion
    submitFrom = (e) => {
        e.preventDefault();
    };
    handleClick = () => this.setState((currentState) => ({isActive: !currentState.isActive}));

    //For Image change
    profileImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                selectFile: URL.createObjectURL(event.target.files[0])
            });
        }
        toast.success('Successfully Added!');
    };

    render() {
        return (
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className={`main-wrapper ${this.state.isActive ? 'content-expend' : ''}`}>
                    <div className="container-fluid">
                        <div className="custom-breadcrumb">
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li className="active-item">Prorfile</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="user-management profile">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav user-management-nav profile-nav mb-3" id="pills-tab"
                                        role="tablist">
                                        <li onClick={this.profile} className="nav-item">
                                            <Link to="#" className={this.state.isProfile ? "nav-link active" : "nav-link"}
                                                  id="pills-user-tab" data-toggle="pill"
                                                  role="tab" aria-controls="pills-user"
                                                  aria-selected="true">
                                                <img src={userImage} className="img-fluid"
                                                     alt=""/>
                                                <span>Profile</span>
                                            </Link>
                                        </li>
                                        <li onClick={this.profileEdit} className="nav-item">
                                            <Link to="#" className={this.state.isEdit ? "nav-link active" : "nav-link"}
                                                  id="pills-add-user-tab" data-toggle="pill" role="tab"
                                                  aria-controls="pills-add-user"
                                                  aria-selected="true">
                                                <img src={editProfile} className="img-fluid" alt=""/>
                                                <span>Edit Profile</span>
                                            </Link>
                                        </li>
                                        <li onClick={this.resetProfile} className="nav-item">
                                            <Link to="#" className={this.state.isReset ? "nav-link active" : "nav-link"}
                                                  id="pills-suspended-user-tab" data-toggle="pill" role="tab"
                                                  aria-controls="pills-suspended-user" aria-selected="true">
                                                <img src={resetPasswordImg} className="img-fluid" alt=""/>
                                                <span>Reset Password</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        {
                                            this.state.isProfile &&
                                            <div className="tab-pane show active" id="pills-user" role="tabpanel"
                                                 aria-labelledby="pills-user-tab">
                                                <div className="profile-info">
                                                    <div className="row">
                                                        <div className="col-xl-4 mb-xl-0 mb-4">
                                                            <div className="user-info text-center">
                                                                <div className="avater-img">
                                                                    <img src={avatartImg} alt=""/>
                                                                </div>
                                                                <h4>John Doe</h4>
                                                                <p>johndoe45@gmail.com</p>
                                                                <span>United State</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-8">
                                                            <div className="profile-info-table">
                                                                <ul>
                                                                    <li>
                                                                        <span>First Name</span>
                                                                        <span className="dot">:</span>
                                                                        <span><strong>John Doe</strong></span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Last Name</span>
                                                                        <span className="dot">:</span>
                                                                        <span><strong>Doe</strong></span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Role</span>
                                                                        <span className="dot">:</span>
                                                                        <span><strong>Admin</strong></span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Status</span>
                                                                        <span className="dot">:</span>
                                                                        <span><strong>Active</strong></span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Contact</span>
                                                                        <span className="dot">:</span>
                                                                        <span><strong>+987 6789 9876 456</strong></span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            this.state.isEdit &&
                                            <div className="tab-pane show active" id="pills-add-user" role="tabpanel"
                                                 aria-labelledby="pills-add-user-tab">
                                                <div className="profile-info">
                                                    <div className="row">
                                                        <div className="col-xl-4 mb-xl-0 mb-4">
                                                            <div className="user-info text-center">
                                                                <div className="avater-img">
                                                                    {
                                                                        this.state.selectFile !== '' ?
                                                                            <img src={this.state.selectFile} alt=""
                                                                                 width="184" height='184'/> :
                                                                            <img src={avatartImg} alt=""/>
                                                                    }
                                                                </div>
                                                                <label className="profile-img-upload">
                                                                    <input type="file" onChange={this.profileImage}
                                                                           className="d-none"/>
                                                                    Upload Image
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-8">
                                                            <div className="profile-info-form">
                                                                <form>
                                                                    <div className="form-group">
                                                                        <label>First Name</label>
                                                                        <input type="text" className="form-control"
                                                                               placeholder="John"/>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Last Name</label>
                                                                        <input type="text" className="form-control"
                                                                               placeholder="Doe"/>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Contact</label>
                                                                        <input type="text" className="form-control"
                                                                               placeholder="+897 6789 9876 456"/>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            this.state.isReset &&
                                            <div className="tab-pane show active" id="pills-suspended-user"
                                                 role="tabpanel"
                                                 aria-labelledby="pills-suspended-user-tab">
                                                <div className="profile-info-form">
                                                    <form onSubmit={this.submitFrom}>
                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                <div className="form-group">
                                                                    <label>Email</label>
                                                                    <input type="email" className="form-control"
                                                                           placeholder="johndoe45@gmail.com"/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Current Password</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="********"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="form-group">
                                                                    <label>New Password</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="********"/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Confirm Password</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="********"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <button type="submit" className="btn">Update
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile;