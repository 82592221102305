import React, {Component} from "react";
import {Link} from "react-router-dom";
import Pagination01 from "../../../../../assets/admin/images/angle-left.svg";
import RightArrow from "../../../../../assets/admin/images/angle-right.svg";
import View from "../../../../../assets/admin/images/user-management-icons/activity/view.svg";
import User from "../../../../../assets/admin/images/user-management-icons/activity/user-1.svg";
import BlockUser from "../../../../../assets/admin/images/user-management-icons/activity/check-square.svg";
import Search from "../../../../../assets/admin/images/search.svg";
import {toast} from "react-toastify";


const emailPending = [
    {
        id : 1,
        first_name : 'Ana',
        last_name : 'Albert',
        email : 'ana43@gmail.com',
        balance : '15.00000',
        date : '2019-01-31 08:38:11',
    },
    {
        id : 2,
        first_name : 'Mona',
        last_name : 'Albert',
        email : 'Mona@gmail.com',
        balance : '17.00000',
        date : '2019-01-31 08:38:11',
    },
    {
        id : 3,
        first_name : 'Mona',
        last_name : 'Albert',
        email : 'Mona@gmail.com',
        balance : '19.00000',
        date : '2019-01-31 08:38:11',
    },
    {
        id : 4,
        first_name : 'Raina',
        last_name : 'Albert',
        email : 'Raina@gmail.com',
        balance : '22.00000',
        date : '2019-01-31 08:38:11',
    },
    {
        id : 5,
        first_name : 'Aliey',
        last_name : 'Albert',
        email : 'Aliey@gmail.com',
        balance : '35.00000',
        date : '2019-01-31 08:38:11',
    },
    {
        id : 6,
        first_name : 'Jhon',
        last_name : 'Ray',
        email : 'Jhon@gmail.com',
        balance : '27.00000',
        date : '2019-01-31 08:38:11',
    },
    {
        id : 7,
        first_name : 'Albert',
        last_name : 'Peraira',
        email : 'ana43@gmail.com',
        balance : '13.00000',
        date : '2019-01-31 08:38:11',
    },
];
localStorage.setItem('emalPending', JSON.stringify(emailPending));

class EmailPending extends Component{

    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            pendingEmail: JSON.parse(localStorage.getItem('emalPending')),
            UserLists: JSON.parse(localStorage.getItem('users')),
            users: [],
            currentPage: 1,
            userPage: 5,
        };
    }

    getUsers() {
        return this.state.pendingEmail;
    }
    componentDidMount()     {
        const pendingEmail = this.getUsers();
        this.setState({pendingEmail});
    }

    search = (e) => {
        const pendingEmail = this.getUsers();
        const search = e.target.value;
        if (search) {
            const filterUser = pendingEmail.filter(pendingEmail => {
                return pendingEmail.first_name.toLowerCase().includes(search.toLowerCase());
            });
            this.setState({pendingEmail: filterUser});
        } else {
            const filterUser = JSON.parse(localStorage.getItem('emalPending'));
            this.setState({pendingEmail: filterUser});
        }
        // localStorage.setItem('users', JSON.stringify(filterUser));
    };
    searchsubmit(e) {
        e.preventDefault();
    }
    firstPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: 1
            })
        }

    }
    lastPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.pendingEmail.length / this.state.userPage)) {
            this.setState({
                currentPage: Math.ceil(this.state.pendingEmail.length / this.state.userPage)
            })
        }

    }
    prevPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }

    }
    nextPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.pendingEmail.length / this.state.userPage)) {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    };
    paginateNumber = (id) => {
        this.setState({
            currentPage: id
        })
    };
    userAtive = id => (e) =>{
        e.preventDefault();
        const suspendedUser = this.getUsers();
        const reActiveUser = suspendedUser.filter(UserLists => {
            return UserLists.id === id;
        });
        if (JSON.parse(localStorage.getItem('users')) !== null){
            var users = JSON.parse(localStorage.getItem('users'));
        }else {
            var users = [];
        }
        users.push({
            first_name: reActiveUser[0].first_name,
            last_name: reActiveUser[0].last_name,
            email: reActiveUser[0].email,
            phone: reActiveUser[0].phone,
            role: reActiveUser[0].role,
            date: reActiveUser[0].date,
            id: reActiveUser[0].id,
        });
        localStorage.setItem('users', JSON.stringify(users));

        const filterUser = suspendedUser.filter(UserLists => {
            return UserLists.id !== id;
        });
        this.setState({pendingEmail: filterUser});
        localStorage.setItem('emalPending', JSON.stringify(filterUser));
        toast.success('Successfully!');
    }

    render() {
        const {pendingEmail, currentPage, userPage} = this.state;
        const lastIndex = currentPage * userPage;
        const firstIndex = lastIndex - userPage;
        const currentUser = pendingEmail ? pendingEmail.slice(firstIndex, lastIndex) : '';
        const totalPage = pendingEmail ? pendingEmail.length / userPage : '';

        const result= Math.ceil(totalPage);
        const pageNumber = [];
        for (let i = 1; i <= Math.ceil(totalPage); i++){
            pageNumber.push(i);
        }
        return(
            <div className="tab-pane" id="pills-email" role="tabpanel"
                 aria-labelledby="pills-email-tab">
                <div className="header-bar">
                    <div className="table-title">
                        <h3>Email Pending</h3>
                    </div>
                    <div className="search">
                        <form onSubmit={this.searchsubmit}>
                            <div className="form-group">
                                <input type="search" name="search" onChange={this.search} className="form-control"
                                       placeholder="Search"/>
                                <button className="btn btn-search">
                                    <img src={Search} className="img-fluid" alt=""/></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="table-area">
                    <div className="table-responsive">
                        <table className="table table-borderless custom-table display text-center"
                               width="100%">
                            <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email ID</th>
                                <th>Updated At</th>
                                <th>Activity</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                currentUser.map(EmailPendings => {
                                  return(
                                      <tr key={EmailPendings.id}>
                                          <td>{EmailPendings.first_name}</td>
                                          <td>{EmailPendings.last_name}</td>
                                          <td>{EmailPendings.email}</td>
                                          <td>{EmailPendings.date}</td>
                                          <td>
                                              <ul className="d-flex align-items-center justify-content-center">
                                                  <li><Link to="/user-view"><img
                                                      src={View}
                                                      className="img-fluid" alt=""/></Link></li>
                                                  <li><Link to="/"><img
                                                      src={User} alt=""/></Link>
                                                  </li>
                                                  <li><Link to="#" onClick={this.userAtive(EmailPendings.id)}><img
                                                      src={BlockUser}
                                                      alt=""/></Link></li>
                                              </ul>
                                          </td>
                                      </tr>
                                  )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="table-pagination">
                        <ul>
                            <li><Link onClick={this.prevPage} to="#"><img src={Pagination01} className="img-fluid"
                                                                          alt=""/></Link></li>
                            {
                                pageNumber.map((number, index) =>{
                                    return(
                                        <li onClick={() => this.paginateNumber(number)} className="active" key={number}><Link to="#">{number}</Link></li>
                                    )
                                })
                            }
                            <li><Link onClick={this.nextPage} to="#"><img src={RightArrow} className="img-fluid"
                                                                          alt=""/></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default EmailPending;