import React, {Component} from "react";
import Search from "../../../../../assets/admin/images/search.svg";
import View from "../../../../../assets/admin/images/user-management-icons/activity/view.svg";
import User from "../../../../../assets/admin/images/user-management-icons/activity/user.svg";
import BlockUser from "../../../../../assets/admin/images/user-management-icons/activity/block-user.svg";
import DeleteUser from "../../../../../assets/admin/images/user-management-icons/activity/delete-user.svg";
import Pagination01 from "../../../../../assets/admin/images/angle-left.svg";
import RightArrow from "../../../../../assets/admin/images/angle-right.svg";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

const users = [
    {
        first_name: 'Ana',
        last_name: 'Albert',
        email: 'ana43@gmail.com',
        phone: '1234567890',
        role: 'User',
        date: '2019-01-31 08:38:11',
        id: '1',
    },
    {
        first_name: 'Mona',
        last_name: 'William',
        email: 'mona876@email.com',
        phone: '1234567890',
        role: 'User',
        date: '2019-01-31 08:38:11',
        id: '2',
    },
    {
        first_name: 'Tina',
        last_name: 'John',
        email: 'tinajohn87@gmail.com',
        phone: '1234567890',
        role: 'User',
        date: '2019-01-31 08:38:11',
        id: '3',
    },
    {
        first_name: 'Raina',
        last_name: 'Alex',
        email: 'rina@gmail.com',
        phone: '1234567890',
        role: 'User',
        date: '2019-01-31 08:38:11',
        id: '4',
    },
    {
        first_name: 'Elena',
        last_name: 'Jon',
        email: 'elena@gmail.com',
        phone: '1234567890',
        role: 'User',
        date: '2019-01-31 08:38:11',
        id: '5',
    },
    {
        first_name: 'Jekia',
        last_name: 'Foren',
        email: 'jekia@gmail.com',
        phone: '1234567890',
        role: 'User',
        date: '2019-01-31 08:38:11',
        id: '6',
    },

];
localStorage.setItem('users', JSON.stringify(users));
class UserList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            UserLists: JSON.parse(localStorage.getItem('users')),
            isEdit: false,
            users: [],
            suspendedUser: [],
            date: '',
            email: '',
            first_name: '',
            last_name: '',
            phone: '',
            role: '',
            currentPage: 1,
            userPage: 5,
        }
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onEditSubmit = this.onEditSubmit.bind(this);
        this.paginateNumber = this.paginateNumber.bind(this);
        this.suspendeUser = this.suspendeUser.bind(this);
    }

    getKey() {
        return Math.random().toString(16).slice(-4);
    }

    getUsers() {
        return this.state.UserLists;
    }

    //Data Mount
    componentDidMount() {
        const UserLists = this.getUsers();
        this.setState({UserLists});
    }

    //User Delete Function
    onDelete = id => (e) => {
        e.preventDefault();
        const UserLists = this.getUsers();
        const deleteUser = UserLists.filter(UserLists => {
            return UserLists.id === id;
        });

        if (JSON.parse(localStorage.getItem('deletUser')) !== null) {
            var deletUser = JSON.parse(localStorage.getItem('deletUser'));
        } else {
            var deletUser = [];
        }
        deletUser.push({
            first_name: deleteUser[0].first_name,
            last_name: deleteUser[0].last_name,
            email: deleteUser[0].email,
            phone: deleteUser[0].phone,
            role: deleteUser[0].role,
            date: deleteUser[0].date,
            id: deleteUser[0].id,
        });
        localStorage.setItem('deletUser', JSON.stringify(deletUser));

        const filterUser = UserLists.filter(UserLists => {
            return UserLists.id !== id;
        });
        this.setState({UserLists: filterUser});
        localStorage.setItem('users', JSON.stringify(filterUser));
        toast.warn('Successfully Deleted!');
    };

    suspendeUser = id => (e) => {
        const UserLists = this.getUsers();
        const filterUser = UserLists.filter(UserLists => {
            return UserLists.id === id;
        });

        if (JSON.parse(localStorage.getItem('suspendedUser')) !== null) {
            var suspendedUser = JSON.parse(localStorage.getItem('suspendedUser'));
        } else {
            var suspendedUser = [];
        }
        suspendedUser.push({
            first_name: filterUser[0].first_name,
            last_name: filterUser[0].last_name,
            email: filterUser[0].email,
            phone: filterUser[0].phone,
            role: filterUser[0].role,
            date: filterUser[0].date,
            id: filterUser[0].id,
        });
        localStorage.setItem('suspendedUser', JSON.stringify(suspendedUser));
        const suspendUser = UserLists.filter(UserLists => {
            return UserLists.id !== id;
        });
        this.setState({UserLists: suspendUser});
        localStorage.setItem('users', JSON.stringify(suspendUser));
        toast.warn('Successfully Suspended!');

    };
    search = (e) => {
        console.log(e.target.value);
        const UserLists = this.getUsers();
        const search = e.target.value;

        if (search) {
            const filterUser = UserLists.filter(UserLists => {
                return UserLists.first_name.toLowerCase().includes(search.toLowerCase());
            });
            this.setState({UserLists: filterUser});
        } else {
            const filterUser = JSON.parse(localStorage.getItem('users'));
            this.setState({UserLists: filterUser});
        }
    };

    searchsubmit(e) {
        e.preventDefault();
    }

    onEdit = id => (e) => {
        e.preventDefault();
        this.setState({
            isEdit: true,
            editId: id
        })
    };
    onEditSubmit = id => (e) => {
        e.preventDefault();
        this.setState({isEdit: true})
    };
    changeHandler = event => {
        console.log(event.target.name);
        console.log(event.target.value);
        this.setState({
            [event.target.name]: event.target.value,
        })
    };
    onEditSubmitForm = event => {
        event.preventDefault();
    };
    enter = id => e => {
        if (e.key === 'Enter') {
            const UserLists = this.getUsers();


            const filterUser = UserLists.filter(UserLists => {
                return UserLists.id !== id;
            });
            this.setState({UserLists: filterUser});
            localStorage.setItem('users', JSON.stringify(filterUser));


            const filterdata = UserLists.filter(UserLists => {
                return UserLists.id === id;
            });

            var users = JSON.parse(localStorage.getItem('users'));
            if (this.state.first_name != '') {
                var f_name = this.state.first_name;
            } else {
                var f_name = filterdata[0].first_name;
            }
            if (this.state.last_name != '') {
                var l_name = this.state.last_name;
            } else {
                var l_name = filterdata[0].last_name;
            }
            if (this.state.email != '') {
                var mail = this.state.email;
            } else {
                var mail = filterdata[0].email;
            }
            users.push({
                first_name: f_name,
                last_name: l_name,
                email: mail,
                phone: filterdata[0].phone,
                role: filterdata[0].role,
                date: filterdata[0].date,
                id: this.getKey() + 1,
            });
            this.setState({UserLists: users});
            localStorage.setItem('users', JSON.stringify(users));


            this.setState({
                isEdit: false,
                id: id
            });

        }
    };

    firstPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: 1
            })
        }

    }
    lastPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.UserLists.length / this.state.userPage)) {
            this.setState({
                currentPage: Math.ceil(this.state.UserLists.length / this.state.userPage)
            })
        }

    }
    prevPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }

    }
    nextPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.UserLists.length / this.state.userPage)) {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    }
    paginateNumber = (id) => {
        this.setState({
            currentPage: id
        })
    }


    render() {

        const {UserLists, currentPage, userPage} = this.state;
        const lastIndex = currentPage * userPage;
        const firstIndex = lastIndex - userPage;
        const currentUser = UserLists ? UserLists.slice(firstIndex, lastIndex) : '';
        const totalPage = UserLists ? UserLists.length / userPage : '';

        const result = Math.ceil(totalPage);
        const pageNumber = [];
        for (let i = 1; i <= Math.ceil(totalPage); i++) {
            pageNumber.push(i);
        }

        return (
            <div>
                <div className="tab-pane show active" id="pills-user" role="tabpanel"
                     aria-labelledby="pills-user-tab">
                    <div className="header-bar">
                        <div className="table-title">
                            <h3>User List</h3>
                        </div>
                        <div className="search">
                            <form onSubmit={this.searchsubmit}>
                                <div className="form-group">
                                    <input type="search" name="search" onChange={this.search} className="form-control"
                                           placeholder="Search"/>
                                    <button className="btn btn-search">
                                        <img src={Search} className="img-fluid" alt=""/></button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="table-area">
                        <div className="table-responsive">
                            <table className="table table-borderless custom-table display text-center"
                                   width="100%">
                                <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email ID</th>
                                    <th>Updated At</th>
                                    <th>Activity</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    UserLists == null ?
                                        <tr>
                                            <td>No data found! Please add some data</td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                        </tr> : currentUser.map(UserLists => {
                                            return (
                                                this.state.editId == UserLists.id
                                                    ?
                                                    (
                                                        <tr key={UserLists.id}>
                                                            <td>
                                                                <form action="" onSubmit={this.onEditSubmitForm}
                                                                      onKeyDown={this.enter(UserLists.id)}><input
                                                                    placeholder="First Name" name="first_name" type="text"
                                                                    onChange={this.changeHandler}
                                                                    defaultValue={UserLists.first_name}
                                                                /></form>
                                                            </td>
                                                            <td>
                                                                <form action="" onSubmit={this.onEditSubmitForm}
                                                                      onKeyDown={this.enter(UserLists.id)}>
                                                                    <input placeholder="Last Name" name="last_name"
                                                                           type="text" onChange={this.changeHandler}
                                                                           defaultValue={UserLists.last_name}
                                                                    />
                                                                </form>
                                                            </td>
                                                            <td>
                                                                <form action="" onSubmit={this.onEditSubmitForm}
                                                                      onKeyDown={this.enter(UserLists.id)}>
                                                                    <input placeholder="Email" type="text" name="email"
                                                                           onChange={this.changeHandler}
                                                                           defaultValue={UserLists.email}
                                                                    />
                                                                </form>
                                                            </td>
                                                            <td>{UserLists.date}</td>
                                                            <td>
                                                                <ul className="d-flex align-items-center justify-content-center">
                                                                    <li><Link to="/user-view"><img
                                                                        src={View}
                                                                        className="img-fluid" alt=""/></Link></li>
                                                                    <li><Link onClick={this.onEdit(UserLists.id)}
                                                                              to="/"><img
                                                                        src={User} alt=""/></Link>
                                                                    </li>
                                                                    <li><Link onClick={this.suspendeUser(UserLists.id)}><img
                                                                        src={BlockUser}
                                                                        alt=""/></Link></li>
                                                                    <li><Link onClick={this.onDelete(UserLists.id)}
                                                                              to="/"><img
                                                                        src={DeleteUser}
                                                                        alt=""/></Link></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )
                                                    :
                                                    (
                                                        <tr key={UserLists.id}>
                                                            <td>{UserLists.first_name}</td>
                                                            <td>{UserLists.last_name}</td>
                                                            <td>{UserLists.email}</td>
                                                            <td>{UserLists.date}</td>
                                                            <td>
                                                                <ul className="d-flex align-items-center justify-content-center">
                                                                    <li><Link to="/user-view"><img
                                                                        src={View}
                                                                        className="img-fluid" alt=""/></Link></li>
                                                                    <li><Link onClick={this.onEdit(UserLists.id)}
                                                                              to="/"><img
                                                                        src={User} alt=""/></Link>
                                                                    </li>
                                                                    <li><Link to="#"
                                                                              onClick={this.suspendeUser(UserLists.id)}><img
                                                                        src={BlockUser}
                                                                        alt=""/></Link></li>
                                                                    <li><Link onClick={this.onDelete(UserLists.id)}
                                                                              to="/"><img
                                                                        src={DeleteUser}
                                                                        alt=""/></Link></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )
                                            )
                                        })

                                }
                                </tbody>
                            </table>
                        </div>
                        <div className="table-pagination">
                            <ul>
                                <li><Link onClick={this.prevPage} to="#"><img src={Pagination01} className="img-fluid"
                                                                              alt=""/></Link></li>
                                {
                                    pageNumber.map((number, index) => {
                                        return (
                                            <li onClick={() => this.paginateNumber(number)} className="active"
                                                key={number}><Link to="#">{number}</Link></li>
                                        )
                                    })
                                }
                                <li><Link onClick={this.nextPage} to="#"><img src={RightArrow} className="img-fluid"
                                                                              alt=""/></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserList;