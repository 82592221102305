import React, {Component} from "react";

import Money from "../../../assets/admin/images/status-icons/money.svg";
import funds from "../../../assets/admin/images/status-icons/funds.svg";
import team from "../../../assets/admin/images/status-icons/team.svg";

class DashboardStatus extends Component{
    render() {
        return(
            <div className="dashboard-status">
                <div className="row">
                    <div className="col-xl-4 col-md-6 col-12 mb-xl-0 mb-4">
                        <div className="card status-card status-card-bg-blue">
                            <div className="card-body">
                                <div className="status-card-inner">
                                    <div className="content">
                                        <p>Todays Fee</p>
                                        <h3>0.00000000</h3>
                                    </div>
                                    <div className="icon">
                                        <img src={Money} className="img-fluid" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-12 mb-xl-0 mb-4">
                        <div className="card status-card status-card-bg-green">
                            <div className="card-body">
                                <div className="status-card-inner">
                                    <div className="content">
                                        <p>Total Fee</p>
                                        <h3>0.00000000</h3>
                                    </div>
                                    <div className="icon">
                                        <img src={funds} className="img-fluid" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-12">
                        <div className="card status-card status-card-bg-read">
                            <div className="card-body">
                                <div className="status-card-inner">
                                    <div className="content">
                                        <p>Total User</p>
                                        <h3>1590575</h3>
                                    </div>
                                    <div className="icon">
                                        <img src={team} className="img-fluid" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default DashboardStatus;