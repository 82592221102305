import React, {Component} from "react";
import Sidebar from "../../Sidebar";
import Header from "../../Header";
import {Link} from "react-router-dom";
import Upload from '../../../../assets/admin/images/upload-icon.svg'
import Search from '../../../../assets/admin/images/search.svg';
import {toast} from "react-toastify";
import Landing01 from '../../../../assets/admin/images/landing-setting-page/img1.png'
import Landing02 from '../../../../assets/admin/images/landing-setting-page/img2.png'
import Landing03 from '../../../../assets/admin/images/landing-setting-page/img3.png'
import Edit from '../../../../assets/admin/images/edit.svg'
import Delete from '../../../../assets/admin/images/delete.svg'
import AngleLeft from "../../../../assets/admin/images/angle-left.svg";
import AngleRight from "../../../../assets/admin/images/angle-right.svg";

const LandingContent = [
    {
        id: 1,
        title: 'Lorem Ipsuem',
        Priority: '1',
        image: Landing01,
    },
    {
        id: 2,
        title: 'Torato afzasta khulaka',
        Priority: '2',
        image: Landing02,
    },
    {
        id: 3,
        title: 'variations Lorem',
        Priority: '3',
        image: Landing03,
    }, {
        id: 4,
        title: 'There are many variations Lorem',
        Priority: '1',
        image: Landing01,
    },
    {
        id: 5,
        title: 'There are many',
        Priority: '2',
        image: Landing02,
    },
    {
        id: 6,
        title: 'variations Lorem',
        Priority: '3',
        image: Landing03,
    }, {
        id: 7,
        title: 'There are many variations Lorem',
        Priority: '1',
        image: Landing01,
    },
    {
        id: 8,
        title: 'There are many',
        Priority: '2',
        image: Landing02,
    },
    {
        id: 9,
        title: 'variations Lorem',
        Priority: '3',
        image: Landing03,
    },
];
localStorage.setItem('LandingContent', JSON.stringify(LandingContent));

class LandinPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            isHeader: true,
            isContent: false,
            isLogo: '',
            isFavicon: '',
            LandingContent: JSON.parse(localStorage.getItem('LandingContent')),
            currentPage: 1,
            userPage: 5,
        }
    }

    getCoins() {
        return this.state.LandingContent;
    }

    componentDidMount() {
        const LandingContent = this.getCoins();
        this.setState({LandingContent});
    }

    handleClick = () => this.setState((currentState) => ({isActive: !currentState.isActive}));

    //Tabs Show Hide
    header = () => {
        this.setState({isContent: false});
        this.setState({isHeader: true});
    };
    content = () => {
        this.setState({isHeader: false});
        this.setState({isContent: true});
    };

    //Submit Image
    ImageSubmit = (e) => {
        e.preventDefault()
    }

    //For Image change
    ImageUploadLogo = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                isLogo: URL.createObjectURL(event.target.files[0])
            });
        }
        toast.success('Successfully Added!');
    };
    //For Image change
    ImageUploadFavicon = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                isFavicon: URL.createObjectURL(event.target.files[0])
            });
        }
        toast.success('Successfully Added!');
    };

    // Search function
    searchsubmit(e) {
        e.preventDefault();
    }
    //User Delete Function
    onDelete = id => (e) => {
        e.preventDefault();
        const filterUser = LandingContent.filter(UserLists => {
            return UserLists.id !== id;
        });
        this.setState({LandingContent: filterUser});
        localStorage.setItem('LandingContent', JSON.stringify(LandingContent));
        toast.error('Successfully Deleted!');
    };

    //Coin Search
    search = (e) => {
        const LandingContent = this.state.LandingContent;
        const search = e.target.value;
        if (search) {
            const filterUser = LandingContent.filter(landingContent => {
                return landingContent.title.toLowerCase().includes(search.toLowerCase());
            });
            this.setState({LandingContent: filterUser});
        } else {
            const filterUser = JSON.parse(localStorage.getItem('LandingContent'));
            this.setState({LandingContent: filterUser});
        }
    };
    // Pagination
    firstPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: 1
            })
        }

    };
    lastPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.orderList.length / this.state.userPage)) {
            this.setState({
                currentPage: Math.ceil(this.state.orderList.length / this.state.userPage)
            })
        }

    };
    prevPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }

    };
    nextPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.orderList.length / this.state.userPage)) {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    };
    paginateNumber = (id) => {
        this.setState({
            currentPage: id
        })
    };

    render() {
        const {LandingContent, currentPage, userPage} = this.state;
        const lastIndex = currentPage * userPage;
        const firstIndex = lastIndex - userPage;
        const currentUser = LandingContent ? LandingContent.slice(firstIndex, lastIndex) : '';
        const totalPage = LandingContent ? LandingContent.length / userPage : '';

        const result = Math.ceil(totalPage);
        const pageNumber = [];
        for (let i = 1; i <= Math.ceil(totalPage); i++) {
            pageNumber.push(i);
        }
        return (
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className={`main-wrapper ${this.state.isActive ? 'content-expend' : ''}`}>
                    <div className="container-fluid">

                        <div className="custom-breadcrumb">
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li>Settings</li>
                                        <li className="active-item">Landing Page</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="user-management">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav user-management-nav justify-content-start mb-3" id="pills-tab"
                                        role="tablist">
                                        <li onClick={this.header} className="nav-item">
                                            <Link to="#" className={this.state.isHeader ? "nav-link active" : "nav-link"}
                                                  id="pills-user-tab" data-toggle="pill"
                                                  aria-selected="true">
                                                <span>Header Settings</span>
                                            </Link>
                                        </li>
                                        <li onClick={this.content} className="nav-item">
                                            <Link to="#" className={this.state.isContent ? "nav-link active" : "nav-link"}
                                                  id="pills-add-user-tab" data-toggle="pill"
                                                  aria-selected="true">
                                                <span>Static Content</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        {
                                            this.state.isHeader &&
                                            <div className="tab-pane show active" id="pills-user" role="tabpanel"
                                                 aria-labelledby="pills-user-tab">
                                                <div className="header-bar">
                                                    <div className="table-title">
                                                        <h3>Landing Page Settings</h3>
                                                    </div>
                                                </div>
                                                <div className="profile-info-form">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-xl-6 mb-xl-0 mb-4">
                                                                <div className="form-group">
                                                                    <label>Landing Page Title</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="The Secure, Shared Bitcoin Wallet"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 mb-xl-0 mb-4">
                                                                <div className="form-group">
                                                                    <label>Landing Page Android App Link</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="https://Cpocketcoin/admin/landing-page-setting"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="form-group">
                                                                    <label>Landing Page Button Link</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="https://Cpocketcoin/admin/landing-page-setting"/>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Landing Page iOS App Link</label>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="https://Cpocketcoin/admin/landing-page-setting"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="form-group">
                                                                    <label>Landing Page Description</label>
                                                                    <textarea className="form-control textarea">Secure your bitcoin with the open source, HD‑multisignature wallet from BitPay. </textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <div className="row">
                                                                    <div className="col-xl-4 col-md-6 mb-xl-0 mb-4">
                                                                        <form>
                                                                            <div className="form-group">
                                                                                <label className="d-block">Landing Page
                                                                                    Logo</label>
                                                                                <label className="logo">
                                                                                    {
                                                                                        this.state.isLogo !== '' ?
                                                                                            <img src={this.state.isLogo}
                                                                                                 width="300"
                                                                                                 height="150"
                                                                                                 alt=""/> :
                                                                                            <img src={Upload}
                                                                                                 className="img-fluid"
                                                                                                 alt=""/>
                                                                                    }

                                                                                    <input
                                                                                        onChange={this.ImageUploadLogo}
                                                                                        type="file"
                                                                                        className="d-none"/>
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <button onClick={this.ImageSubmit}
                                                                                        type="submit"
                                                                                        className="btn logo-upload-btn">Upload
                                                                                    Image
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="col-xl-4 col-md-6 mb-xl-0 mb-4">
                                                                        <form>
                                                                            <div className="form-group">
                                                                                <label className="d-block">Landing Page
                                                                                    Image
                                                                                </label>
                                                                                <label className="logo">
                                                                                    {
                                                                                        this.state.isLogo !== '' ?
                                                                                            <img
                                                                                                src={this.state.isFavicon}
                                                                                                width="300" height="150"
                                                                                                alt=""/> :
                                                                                            <img src={Upload}
                                                                                                 className="img-fluid"
                                                                                                 alt=""/>
                                                                                    }

                                                                                    <input
                                                                                        onChange={this.ImageUploadFavicon}
                                                                                        type="file"
                                                                                        className="d-none"/>
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <button onClick={this.ImageSubmit}
                                                                                        type="submit"
                                                                                        className="btn logo-upload-btn">Upload
                                                                                    Image
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <div className="form-group">
                                                                    <button onClick={this.ImageSubmit} type="submit"
                                                                            className="btn">Update
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>}

                                        {
                                            this.state.isContent &&
                                            <div className="tab-pane show active" id="pills-add-user" role="tabpanel"
                                                 aria-labelledby="pills-add-user-tab">
                                                <div className="header-bar">
                                                    <div className="table-title">
                                                        <h3>Landing Page Content</h3>
                                                    </div>
                                                    <div className="right d-flex align-items-center">
                                                        <div className="search mr-3">
                                                            <form onSubmit={this.searchsubmit}>
                                                                <div className="form-group">
                                                                    <input type="search" name="search"
                                                                           onChange={this.search}
                                                                           className="form-control"
                                                                           placeholder="Search"/>
                                                                    <button className="btn btn-search">
                                                                        <img src={Search} className="img-fluid" alt=""/>
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div className="add-btn">
                                                            <Link to="/add-content">+ Add</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-area">
                                                    <div className="table-responsive">
                                                        <table
                                                            className="table table-borderless custom-table display text-center"
                                                            width="100%">
                                                            <thead>
                                                            <tr>
                                                                <th>Image</th>
                                                                <th>Title</th>
                                                                <th>Priority</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                currentUser.map(landingList => {
                                                                    return (
                                                                        <tr key={landingList.id}>
                                                                            <td><img src={landingList.image} alt=""/>
                                                                            </td>
                                                                            <td>{landingList.title}</td>
                                                                            <td>{landingList.Priority}</td>
                                                                            <td>
                                                                                <ul className="d-flex justify-content-center">
                                                                                    <li><Link ><img
                                                                                        src={Edit} className="img-fluid"
                                                                                        alt=""/></Link></li>
                                                                                    <li onClick={this.onDelete(landingList.id)}><Link ><img
                                                                                        src={Delete}
                                                                                        className="img-fluid"
                                                                                        alt=""/></Link></li>
                                                                                </ul>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="table-pagination">
                                                        <ul>
                                                            <li><Link onClick={this.prevPage} to="#"><img
                                                                src={AngleLeft} className="img-fluid"
                                                                alt=""/></Link></li>
                                                            {
                                                                pageNumber.map((number, index) => {
                                                                    return (
                                                                        <li onClick={() => this.paginateNumber(number)}
                                                                            className="active" key={number}><Link
                                                                            to="#">{number}</Link></li>
                                                                    )
                                                                })
                                                            }
                                                            <li><Link onClick={this.nextPage} to="#"><img
                                                                src={AngleRight} className="img-fluid"
                                                                alt=""/></Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LandinPage;