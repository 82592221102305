import React, { Component } from "react";
import User_Header from "../Header";
import User_Sidebar from "../Sidebar";

class MyReferral extends Component{
    constructor(prop) {
        super(prop);
        this.state = {
            isMenu: false,
        }
    }

    fromSubmit = (e) => {
        e.preventDefault()
    }
    toggleClass = () => {
        this.setState({
            isMenu: !this.state.isMenu
        })
    }
    render() {
        let sidebarClass = ["cp-user-sidebar"];
        if (this.state.addClass) {
            sidebarClass.push('cp-user-sidebar-show');
        }
        return (
            <div>

                <User_Header toggleClass={this.toggleClass} />
                <User_Sidebar sidebarClass={this.state.isMenu}/>
                <div className="cp-user-main-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card cp-user-custom-card">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area cp-user-card-header-bb">
                                            <h4>Invite your Contact</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="cp-user-referral-content">
                                                    <form>
                                                        <div className="form-group">
                                                            <label>Share This Link to Your Contact</label>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <button onClick={this.fromSubmit} className="btn copy-url-btn">Copy URL
                                                                    </button>
                                                                </div>
                                                                <input type="text" className="form-control"
                                                                    placeholder="2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q2NCzoqbY7CXi" />
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="cp-user-content-bottom">
                                                        <span className="or">or</span>
                                                        <h5 className="cp-user-share-title">Share Your Code On</h5>
                                                        <div className="cp-user-share-buttons">
                                                            <ul>
                                                                <li><a className="fb" href="javscript:;"><i
                                                                    className="fa fa-facebook"></i> Facebook</a></li>
                                                                <li><a className="twit" href="javscript:;"><i
                                                                    className="fa fa-twitter"></i> Twitter</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card cp-user-custom-card mt-5">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <h4>My Referral</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="cp-user-myreferral">
                                                    <div className="table-responsive">
                                                        <table
                                                            className="table cp-user-custom-table table-borderless text-center"
                                                            width="100%">
                                                            <thead>
                                                                <tr>
                                                                    <th>Level 1</th>
                                                                    <th>Level 2</th>
                                                                    <th>Level 2</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>2</td>
                                                                    <td>3</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card cp-user-custom-card mt-5">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <h4>My Earnings</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="cp-user-myreferral">
                                                    <div className="table-responsive">
                                                        <table
                                                            className="table cp-user-custom-table table-borderless text-center"
                                                            width="100%">
                                                            <thead>
                                                                <tr>
                                                                    <th>Period</th>
                                                                    <th>Commission</th>
                                                                    <th>Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>2019-01-24</td>
                                                                    <td>15.0000098</td>
                                                                    <td>15.0000098</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>2019-01-24</td>
                                                                    <td>15.0000098</td>
                                                                    <td>15.0000098</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>2019-01-24</td>
                                                                    <td>15.0000098</td>
                                                                    <td>15.0000098</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>2019-01-24</td>
                                                                    <td>15.0000098</td>
                                                                    <td>15.0000098</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>2019-01-24</td>
                                                                    <td>15.0000098</td>
                                                                    <td>15.0000098</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>2019-01-24</td>
                                                                    <td>15.0000098</td>
                                                                    <td>15.0000098</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default MyReferral;