import React, {Component} from "react";
import Chart from "react-apexcharts";

class DepositChart extends Component{
    constructor(props) {
        super(props);

        this.state = {
            series: [{
                name: 'PRODUCT D',
                data: [30, 26, 22, 13, 28, 25, 20, 25, 13, 22, 8]
            },
                {
                    name: 'PRODUCT D',
                    data: [30, 26, 22, 13, 28, 25, 20, 25, 13, 22, 8]
                },
            ],
            options: {
                chart: {
                    type: 'line',
                    height: 400,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {}
                }],
                plotOptions: {
                    bar: {
                        horizontal: false,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    colors: ['#54BE01', '#F7941E'],
                    opacity: 1,
                },
                fill: {
                    colors: undefined,
                    opacity: 0,
                    type: 'solid',
                },
                legend: {
                    show: false,
                },
                markers: {
                    size: 0,
                    hover: {
                        sizeOffset: 6
                    }
                },
                xaxis: {
                    categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
                        '10 Jan', '11 Jan', '12 Jan'
                    ],
                    labels: {
                        show: true,
                        rotate: -45,
                        rotateAlways: false,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: true,
                        minHeight: undefined,
                        maxHeight: 120,
                        style: {
                            colors: ['#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff'],
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            cssClass: 'apexcharts-xaxis-label apexcharts-text',
                        },
                    },
                },
                yaxis: {
                    categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
                        '10 Jan', '11 Jan', '12 Jan'
                    ],
                    labels: {
                        show: true,
                        rotateAlways: false,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: true,
                        minHeight: undefined,
                        maxHeight: 120,
                        style: {
                            colors: ['#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff'],
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            cssClass: 'apexcharts-xaxis-label apexcharts-text',
                        },
                    },
                },
                tooltip: {
                    y: [
                        {
                            title: {
                                formatter: function (val) {
                                    return val + " (mins)"
                                }
                            }
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val + " per session"
                                }
                            }
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                }
                            }
                        }
                    ]
                },
                grid: {
                    borderColor: '#182346',
                    strokeDashArray: 1,
                }
            },
        };
    }

    render() {
        return(
            <div className="user-chart">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-top">
                                    <h4>Deposit & Withdraw</h4>
                                    <ul>
                                        <li className="active">Day</li>
                                        <li>Month</li>
                                        <li>Year</li>
                                    </ul>
                                </div>
                                <div id="deposit-withdraw-chart">
                                    <Chart options={this.state.options} series={this.state.series} type="area" height={390} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default DepositChart;