import React, {Component} from "react";
import Sidebar from "../../Sidebar";
import Header from "../../Header";

class AllTransaction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
        }
    }

    handleClick = () => this.setState((currentState) => ({isActive: !currentState.isActive}));

    render() {
        return (
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className="main-wrapper">
                    <div className="container-fluid">
                        <div className="custom-breadcrumb">
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li>My wallet</li>
                                        <li className="active-item">All Transaction</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="user-management wallet-transaction-area">
                            <div className="row">
                                <div className="col-12">
                                    <div className="header-bar">
                                        <ul className="nav wallet-transaction mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="pills-deposit-tab" data-toggle="pill"
                                                   href="#pills-deposit" role="tab" aria-controls="pills-deposit"
                                                   aria-selected="true">
                                                    Deposit
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="pills-withdraw-tab" data-toggle="pill"
                                                   href="#pills-withdraw" role="tab" aria-controls="pills-withdraw"
                                                   aria-selected="true">
                                                    Withdraw
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="search">
                                            <form>
                                                <div className="form-group">
                                                    <input type="search" className="form-control" placeholder="Search"/>
                                                    <button className="btn btn-search"><img src="images/search.svg"
                                                                                            className="img-fluid"
                                                                                            alt=""/></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-deposit" role="tabpanel"
                                             aria-labelledby="pills-deposit-tab">
                                            <div className="table-area">
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-borderless custom-table display text-left"
                                                        width="100%">
                                                        <thead>
                                                        <tr>
                                                            <th>Address</th>
                                                            <th>Amount</th>
                                                            <th>Transaction ID</th>
                                                            <th>Update At</th>
                                                            <th>Status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-success">Successfull</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-success">Successfull</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-warning">Pending</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-danger">Cancel</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-danger">Cancel</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-success">Successfull</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-warning">Pending</span></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="table-pagination">
                                                    <ul>
                                                        <li><a href="javascript:;"><img src="images/angle-left.svg"
                                                                                        className="img-fluid"
                                                                                        alt=""/></a>
                                                        </li>
                                                        <li className="active"><a href="javascript:;">1</a></li>
                                                        <li><a href="javascript:;">2</a></li>
                                                        <li><a href="javascript:;">3</a></li>
                                                        <li><a href="javascript:;"><img src="images/angle-right.svg"
                                                                                        className="img-fluid"
                                                                                        alt=""/></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-withdraw" role="tabpanel"
                                             aria-labelledby="pills-withdraw-tab">
                                            <div className="table-area">
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-borderless custom-table display text-left"
                                                        width="100%">
                                                        <thead>
                                                        <tr>
                                                            <th>Address</th>
                                                            <th>Amount</th>
                                                            <th>Transaction ID</th>
                                                            <th>Update At</th>
                                                            <th>Status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-success">Successfull</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-success">Successfull</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-warning">Pending</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-danger">Cancel</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-danger">Cancel</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-success">Successfull</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>12.000078</td>
                                                            <td>2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                            <td>2019-01-24 12:50:17</td>
                                                            <td><span className="text-warning">Pending</span></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="table-pagination">
                                                    <ul>
                                                        <li><a href="javascript:;"><img src="images/angle-left.svg"
                                                                                        className="img-fluid"
                                                                                        alt=""/></a>
                                                        </li>
                                                        <li className="active"><a href="javascript:;">1</a></li>
                                                        <li><a href="javascript:;">2</a></li>
                                                        <li><a href="javascript:;">3</a></li>
                                                        <li><a href="javascript:;"><img src="images/angle-right.svg"
                                                                                        className="img-fluid"
                                                                                        alt=""/></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AllTransaction;