import React, {Component} from "react";
import Sidebar from "../../../../components/Admin/Sidebar";
import Header from "../../../../components/Admin/Header";


class Breadcrumb extends Component{
    render() {
        return(
            <div>
                <div className="custom-breadcrumb">
                    <div className="row">
                        <div className="col-12">
                            <ul>
                                <li className="active-item">{this.props.name}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Breadcrumb;