import React, {Component} from "react";
import Menu from '../../../assets/user/images/menu.svg';
import Logo from '../../../assets/user/images/logo.svg';
import Avatar from '../../../assets/user/images/avatar.jpg';
import {Link} from "react-router-dom";

class User_Header extends Component {
    constructor(prop) {
        super(prop);
        this.state = {
            isAvataDown: false,
        }
    }
    componentDidMount() {
        document.body.addEventListener('click', this.myHandler);
    }

    myHandler = () => {
        if (this.state.isAvataDown === true) {
            this.setState({
                isAvataDown: false
            })
        }
        if (this.state.isTransaction === true) {
            this.setState({
                isTransaction: false
            })
        }
    };

    // Dropdown
    avataClickHandaler = (e) => {
        this.setState({
            isAvataDown: !this.state.isAvataDown,
        })
    }

    render() {
        return (
            <div>
                <div className="cp-user-top-bar ">
                    <div className="cp-user-sidebar-toggler" onClick={this.props.toggleClass}>
                        <img src={Menu} className="img-fluid d-lg-none d-block" alt=""/>
                    </div>
                    <div className="container-fluid">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-xl-2 col-lg-2 d-lg-block d-none">
                                <div className="cp-user-logo">
                                    <Link to="/"><img src={Logo}
                                                              className="img-fluid cp-user-logo-large" alt=""/></Link>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 col-md-9">
                                <ul className="cp-user-top-bar-status-area">
                                    <li className="cp-user-date-time">
                                        <p className="cp-user-title">Date & Time</p>
                                        <div className="cp-user-content">
                                            <p className="cp-user-last-visit"><span>Last Visit :</span> 12:35,
                                                20:04:2019</p>
                                            <p className="cp-user-today"><span>Today :</span> 12:35, 20:04:2019</p>
                                        </div>
                                    </li>
                                    <li className="cp-user-available-balance">
                                        <p className="cp-user-title">Available Balance</p>
                                        <div className="cp-user-content">
                                            <p className="cp-user-btc"><span>9067.7800000</span> BTC</p>
                                            <p className="cp-user-usd"><span>127509750.800</span> USD</p>
                                        </div>
                                    </li>
                                    <li className="cp-user-pending-withdrawal">
                                        <p className="cp-user-title">Pending withdrawal</p>
                                        <div className="cp-user-content">
                                            <p className="cp-user-btc"><span>9067.7800000</span> BTC</p>
                                            <p className="cp-user-usd"><span>127509750.800</span> USD</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-3">
                                <div className="cp-user-top-bar-right">
                                    <ul>
                                        <li>
                                            <div className="btn-group">
                                                <button onClick={this.avataClickHandaler} type="button" className="btn dropdown-toggle"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                    <span className="cp-user-avater">
                                        <span className="cp-user-img">
                                            <img src={Avatar} className="img-fluid" alt=""/>
                                        </span>
                                        <span className="cp-user-avater-info">
                                            <span>Welcome Back!</span>
                                            <p>James Bond</p>
                                        </span>
                                    </span>
                                                </button>
                                                { this.state.isAvataDown && <div className="dropdown-menu dropdown-menu-right">
                                                    <button className="dropdown-item" type="button">Action</button>
                                                    <button className="dropdown-item" type="button">Another action
                                                    </button>
                                                    <button className="dropdown-item" type="button">Something else
                                                        here
                                                    </button>
                                                </div>}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default User_Header;