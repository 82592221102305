import React, {Component} from "react";
import "../../../assets/admin/css/bootstrap.min.css";
import "../../../assets/admin/css/metisMenu.min.css";
import "../../../assets/admin/css/font-awesome.min.css";
import "../../../assets/admin/css/style.css";
import "../../../assets/admin/css/responsive.css";

import Sidebar from "../Sidebar";
import Header from "../Header";
import Breadcrumb from "../../../containers/Admin/Dashboard/Breadcrumb";
import DashboardStatus from "../DashboardStatus";
import UserChat from "../Charts/UserChart";
import DepositChart from "../Charts/DepositChart";
import ActiveUser from "../Charts/ActiveUser";

class Admin extends Component{
    state = {
        isActive: false
    }
    handleClick = () => this.setState((currentState) => ({isActive: !currentState.isActive}));

    render() {
        return(
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className={`main-wrapper ${this.state.isActive ? 'content-expend' : ''}`}>
                    <div className="container-fluid">
                        <Breadcrumb name="Dashboard"/>
                        <DashboardStatus/>
                        <UserChat/>
                        <DepositChart/>
                        <ActiveUser/>
                    </div>
                </div>
            </div>
        )
    }
}
export default Admin;