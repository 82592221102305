import React, {Component} from "react";
import {Nav, Tab, Tabs} from "react-bootstrap";
import Sidebar from "../../Sidebar";
import Header from "../../Header";
import Search from "../../../../assets/admin/images/search.svg";
import {Link} from "react-router-dom";
import Pagination01 from "../../../../assets/admin/images/angle-left.svg";
import RightArrow from "../../../../assets/admin/images/angle-right.svg";


class PendingWithdrawal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            getWallet: JSON.parse(localStorage.getItem('walletValue')),
            currentPage: 1,
            userPage: 5,
        }
    }

    handleClick = () => this.setState((currentState) => ({isActive: !currentState.isActive}));

    getUsers() {
        return this.state.getWallet;
    }

    componentDidMount() {
        const getWallet = this.getUsers();
        this.setState({getWallet});
    }

    searchsubmit(e) {
        e.preventDefault();
    }

    search = (e) => {
        const getWallet = this.state.getWallet;
        const search = e.target.value;
        if (search) {
            const filterUser = getWallet.filter(getWallet => {
                return getWallet.address.toLowerCase().includes(search.toLowerCase());
            });
            this.setState({getWallet: filterUser});
        } else {
            const filterUser = JSON.parse(localStorage.getItem('walletValue'));
            this.setState({getWallet: filterUser});
        }
    };

    // Pagination
    firstPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: 1
            })
        }

    }
    lastPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.getWallet.length / this.state.userPage)) {
            this.setState({
                currentPage: Math.ceil(this.state.getWallet.length / this.state.userPage)
            })
        }

    }
    prevPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }

    }
    nextPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.getWallet.length / this.state.userPage)) {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    }
    paginateNumber = (id) => {
        this.setState({
            currentPage: id
        })
    }

    render() {
        const {getWallet, currentPage, userPage} = this.state;
        const lastIndex = currentPage * userPage;
        const firstIndex = lastIndex - userPage;
        const currentUser = getWallet ? getWallet.slice(firstIndex, lastIndex) : '';
        const totalPage = getWallet ? getWallet.length / userPage : '';

        const result = Math.ceil(totalPage);
        const pageNumber = [];
        for (let i = 1; i <= Math.ceil(totalPage); i++) {
            pageNumber.push(i);
        }
        return (
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className={`main-wrapper ${this.state.isActive ? 'content-expend' : ''}`}>
                    <div className="container-fluid">

                        <div className="custom-breadcrumb">
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li>My wallet</li>
                                        <li className="active-item">Pending Withdrawal</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <Tab.Container defaultActiveKey="first">
                            <div className="user-management wallet-transaction-area">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="header-bar">
                                            <Nav variant="pills">
                                                <Nav.Item className="nav wallet-transaction mb-3">
                                                    <Nav.Link className="nav-link" eventKey="first">Pending Withdrawal
                                                        List</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="nav wallet-transaction mb-3">
                                                    <Nav.Link eventKey="second">Rejected Withdrawal List</Nav.Link>
                                                </Nav.Item>
                                            </Nav>

                                            <div className="search">
                                                <form onSubmit={this.searchsubmit}>
                                                    <div className="form-group">
                                                        <input type="search" name="search" onChange={this.search}
                                                               className="form-control"
                                                               placeholder="Search"/>
                                                        <button className="btn btn-search">
                                                            <img src={Search} className="img-fluid" alt=""/></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="tab-content" id="pills-tabContent">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <div className="tab-pane fade show active" id="pills-deposit"
                                                         role="tabpanel"
                                                         aria-labelledby="pills-deposit-tab">
                                                        <div className="table-area">
                                                            <div className="table-responsive">
                                                                <table
                                                                    className="table table-borderless custom-table display text-left"
                                                                    width="100%">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Address</th>
                                                                        <th>Amount</th>
                                                                        <th>Transaction ID</th>
                                                                        <th>Update At</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        currentUser.map(deposite => {
                                                                            return (
                                                                                <tr key={deposite.id}>
                                                                                    <td>{deposite.address}</td>
                                                                                    <td>{deposite.balance}</td>
                                                                                    <td>{deposite.transaction}</td>
                                                                                    <td>{deposite.date}</td>

                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="table-pagination">
                                                                <ul>
                                                                    <li><Link onClick={this.prevPage} to="#"><img
                                                                        src={Pagination01} className="img-fluid"
                                                                        alt=""/></Link></li>
                                                                    {
                                                                        pageNumber.map((number, index) => {
                                                                            return (
                                                                                <li onClick={() => this.paginateNumber(number)}
                                                                                    className="active" key={number}>
                                                                                    <Link to="#">{number}</Link></li>
                                                                            )
                                                                        })
                                                                    }
                                                                    <li><Link onClick={this.nextPage} to="#"><img
                                                                        src={RightArrow} className="img-fluid"
                                                                        alt=""/></Link></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <div className="tab-pane fade show active" id="pills-deposit"
                                                         role="tabpanel"
                                                         aria-labelledby="pills-deposit-tab">
                                                        <div className="table-area">
                                                            <div className="table-responsive">
                                                                <table
                                                                    className="table table-borderless custom-table display text-left"
                                                                    width="100%">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Address</th>
                                                                        <th>Amount</th>
                                                                        <th>Transaction ID</th>
                                                                        <th>Update At</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        currentUser.map(deposite => {
                                                                            return (
                                                                                <tr key={deposite.id}>
                                                                                    <td>{deposite.address}</td>
                                                                                    <td>{deposite.balance}</td>
                                                                                    <td>{deposite.transaction}</td>
                                                                                    <td>{deposite.date}</td>

                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="table-pagination">
                                                                <ul>
                                                                    <li><Link onClick={this.prevPage} to="#"><img
                                                                        src={Pagination01} className="img-fluid"
                                                                        alt=""/></Link></li>
                                                                    {
                                                                        pageNumber.map((number, index) => {
                                                                            return (
                                                                                <li onClick={() => this.paginateNumber(number)}
                                                                                    className="active" key={number}>
                                                                                    <Link to="#">{number}</Link></li>
                                                                            )
                                                                        })
                                                                    }
                                                                    <li><Link onClick={this.nextPage} to="#"><img
                                                                        src={RightArrow} className="img-fluid"
                                                                        alt=""/></Link></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        )
    }
}

export default PendingWithdrawal;