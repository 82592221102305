import React, {Component} from 'react';
import { BrowserRouter as Router,Switch, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import logo from './logo.svg';
import './App.css';

import Login from "./app/components/Admin/Login";
import Signup from "./app/components/Admin/Signup";
import Dashboard from "./app/components/Admin/Dashboard";
import User from "./app/components/Admin/UserManagement";
import UserView from "./app/components/Admin/UserManagement/User/UserList/UserView";
import WalletTransaction from "./app/components/Admin/UserManagement/User/UserList/WalletTransaction";
import UserAdd from "./app/components/Admin/UserManagement/User/AddUser";
import UserWallet from "./app/components/Admin/CryptWallet/UserWallet";
import PendingIdVerification from "./app/components/Admin/UserManagement/User/PendingIdVerification";
import Verify from "./app/components/Admin/UserManagement/User/PendingIdVerification/verify";
import AllTransaction from "./app/components/Admin/CryptWallet/AllTransaction";
import NodeHistory from "./app/components/Admin/CryptWallet/NodeHistory";
import PendingWithdrawal from "./app/components/Admin/CryptWallet/PendingWithdrawal";
import OrderList from "./app/components/Admin/OrderList";
import Profile from "./app/components/Admin/Profile";
import GeneralSetting from "./app/components/Admin/Settings/GeneralSettings";
import LandinPage from "./app/components/Admin/Settings/LandingPage/inde";
import AddContent from "./app/components/Admin/Settings/AddContent";
import CustomPage from "./app/components/Admin/Settings/CustomPage";
import AddCustomPage from "./app/components/Admin/Settings/AddCustomPage";
import FAQ from "./app/components/Admin/Settings/Faq";
import AddFaq from "./app/components/Admin/Settings/AddFaq";
import BulkEmail from "./app/components/Admin/Settings/BulkEmail";
import User_Dashboard from "./app/components/User/Dashboard";
import BuyCoin from "./app/components/User/BuyCoin";
import MyWallet from "./app/components/User/MyPocket";
import MyProfile from "./app/components/User/MyProfile";
import MyReferral from "./app/components/User/MyReferral";
import User_Settings from "./app/components/User/Settings";
import BankInfo from "./app/components/User/BuyCoin/bankInfo";
import BitcoinDeposite from "./app/components/User/BuyCoin/bitcoin";
import Craditcard from "./app/components/User/BuyCoin/craditcard";
import User_Withdraw from "./app/components/User/Withdraw";
import UserLogin from "./app/components/User/Login";

toast.configure();

class App extends Component{
    render() {
        return (
            <div onClick={this.anyclick} className="App">
                <header className="App-header">
                    <Router>
                        <Switch>
                            <Route exact path='/' component={Login}/>
                            <Route path='/signup' component={Signup}/>
                            <Route path='/dashboard' component={Dashboard}/>
                            <Route path='/user' component={User}/>
                            <Route path='/user/add' component={UserAdd}/>
                            <Route path='/user-view' component={UserView}/>
                            <Route path='/wallet-transaction' component={WalletTransaction}/>
                            <Route path='/pending-id-verification' component={PendingIdVerification}/>
                            <Route path='/pending-verify' component={Verify}/>
                            <Route path='/user-wallet' component={UserWallet}/>
                            <Route path='/all-transaction' component={AllTransaction}/>
                            <Route path='/node-history' component={NodeHistory}/>
                            <Route path='/pending-withdrawal' component={PendingWithdrawal}/>
                            <Route path='/order-list' component={OrderList}/>
                            <Route path='/profile' component={Profile}/>
                            <Route path='/general-settings' component={GeneralSetting}/>
                            <Route path='/landing-page' component={LandinPage}/>
                            <Route path='/add-content' component={AddContent}/>
                            <Route path='/custom-page' component={CustomPage}/>
                            <Route path='/custom-page-add' component={AddCustomPage}/>
                            <Route path='/faq' component={FAQ}/>
                            <Route path='/add-faq' component={AddFaq}/>
                            <Route path='/bulk-email' component={BulkEmail}/>


                            <Route path='/user-login' component={UserLogin}/>
                            <Route path='/user-dashboard' component={User_Dashboard}/>
                            <Route path='/user-buy-coin' component={BuyCoin}/>
                            <Route path='/user-my-wallet' component={MyWallet}/>
                            <Route path='/user-my-profile' component={MyProfile}/>
                            <Route path='/user-my-referral' component={MyReferral}/>
                            <Route path='/user-settings' component={User_Settings}/>
                            <Route path='/user-bank-info' component={BankInfo}/>
                            <Route path='/user-bitcoin' component={BitcoinDeposite}/>
                            <Route path='/user-credit-card' component={Craditcard}/>
                            <Route path='/user-withdraw' component={User_Withdraw}/>
                        </Switch>
                    </Router>
                </header>
            </div>
        );
    }
}

export default App;
