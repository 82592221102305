import React, {Component} from "react";
import User_Header from "../Header";
import User_Sidebar from "../Sidebar";
import Gauth from '../../../assets/user/images/gauth.svg'

class User_Settings extends Component{
    constructor(prop) {
        super(prop);
        this.state = {
            isMenu: false,
        }
    }

    fromSubmit = (e) => {
        e.preventDefault()
    }
    toggleClass = () => {
        this.setState({
            isMenu: !this.state.isMenu
        })
    }
    render() {
        let sidebarClass = ["cp-user-sidebar"];
        if (this.state.addClass) {
            sidebarClass.push('cp-user-sidebar-show');
        }
        return(
            <div>
                <User_Header toggleClass={this.toggleClass} />
                <User_Sidebar sidebarClass={this.state.isMenu}/>
                <div className="cp-user-main-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 mb-xl-0 mb-4">
                                <div className="card cp-user-custom-card cp-user-setting-card">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <div className="cp-user-title">
                                                <h4>Google Authentication Settings</h4>
                                            </div>
                                        </div>
                                        <div className="cp-user-setting-card-inner">
                                            <div className="cp-user-auth-icon">
                                                <img src={Gauth} className="img-fluid" alt=""/>
                                            </div>
                                            <div className="cp-user-content">
                                                <h5>Authenticator app</h5>
                                                <p>Use the Authenticator app to get free verification codes, even when
                                                    your phone is offline. Available for Android and iPhone.</p>
                                                <button onClick={this.fromSubmit} className="btn cp-user-setupbtn">Set Up</button>
                                            </div>
                                            <div className="cp-user-content">
                                                <h5>Security</h5>
                                                <p>Please on this option to enable two factor authentication at log
                                                    In.</p>
                                                <label className="switch">
                                                    <input type="checkbox"/>
                                                        <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="card cp-user-custom-card cp-user-setting-card">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <div className="cp-user-title">
                                                <h4>Preference Settings</h4>
                                            </div>
                                        </div>
                                        <div
                                            className="cp-user-setting-card-inner cp-user-setting-card-inner-preference">
                                            <div className="cp-user-content">
                                                <form>
                                                    <div className="form-group">
                                                        <label>Language</label>
                                                        <div className="cp-user-preferance-setting">
                                                            <select className="form-control">
                                                                <option value="">English</option>
                                                                <option value="">Bangla</option>
                                                                <option value="">Hindi</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Currency</label>
                                                        <div className="cp-user-preferance-setting">
                                                            <select className="form-control">
                                                                <option value="">USD</option>
                                                                <option value="">BDT</option>
                                                                <option value="">GBP</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <button onClick={this.fromSubmit}className="btn cp-user-setupbtn">Set Up</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default User_Settings;