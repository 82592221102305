import React, {Component} from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Breadcrumb from "../../../containers/Admin/Dashboard/Breadcrumb";
import UserList from "./User/UserList";
import UserAdd from "./User/AddUser";
import SuspendedUser from "./User/SuspendedUser";
import DeletedUser from "./User/DeletedUser";
import EmailPending from "./User/EmailPending";
import {Tab, Tabs} from "react-bootstrap";

import View from "../../../assets/admin/images/user-management-icons/user.svg";
import Users from "../../../assets/admin/images/user-management-icons/activity/user.svg";
import BlockUser from "../../../assets/admin/images/user-management-icons/activity/block-user.svg";
import DeleteUser from "../../../assets/admin/images/user-management-icons/activity/delete-user.svg";
import EmailUser from "../../../assets/admin/images/user-management-icons/email.svg";

class User extends Component {
    state = {
        isActive: false
    }
    handleClick = () => this.setState((currentState) => ({isActive: !currentState.isActive}));

    render() {
        return (
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className={`main-wrapper ${this.state.isActive ? 'content-expend' : ''}`}>
                    <div className="container-fluid">
                        <Breadcrumb name="User"/>
                        <div className="user-management">
                            <div className="row">
                                <div className="col-12">
                                    <Tabs defaultActiveKey="home" className="user-management-nav" transition={false}
                                          id="noanim-tab-example">
                                        <Tab eventKey="home" title="User list">
                                            <UserList/>
                                        </Tab>
                                        <Tab eventKey="add_user" title="Add user">
                                            <UserAdd/>
                                        </Tab>
                                        <Tab eventKey="suspended_user" title="Suspended user">
                                            <SuspendedUser/>
                                        </Tab>
                                        <Tab eventKey="deleted_user" title="Deleted user">
                                            <DeletedUser/>
                                        </Tab>
                                        <Tab eventKey="email_pending" title="Email pending">
                                            <EmailPending/>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default User;