import React, {Component} from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import SearchImg from '../../../assets/admin/images/search.svg';
import CheckSqiareImg from '../../../assets/admin/images/check-square.svg';
import DeleteImg from '../../../assets/admin/images/delete.svg';
import {Link} from "react-router-dom";
import Pagination01 from "../../../assets/admin/images/angle-left.svg";
import RightArrow from "../../../assets/admin/images/angle-right.svg";
import {toast} from "react-toastify";
import Modal from "react-bootstrap/Modal";

const Orderlist = [
    {
        id: 1,
        email: 'ana43@gmail.com',
        balance: '15.00000',
        paymetType: 'Bank Deposit',
        date: '2019-01-31 08:38:11'
    },
    {
        id: 2,
        email: 'mona876@email.com',
        balance: '16.00000',
        paymetType: 'Credit Card',
        date: '2019-01-31 08:38:11'
    },
    {
        id: 3,
        email: 'mona876@email.com',
        balance: '28.00000',
        paymetType: 'Bank Deposit',
        date: '2019-01-31 08:38:11'
    },
    {
        id: 4,
        email: 'ana43@gmail.com',
        balance: '15.00000',
        paymetType: 'Bank Deposit',
        date: '2019-01-31 08:38:11'
    },
    {
        id: 5,
        email: 'iona876@email.com',
        balance: '15.00000',
        paymetType: 'Credit Card',
        date: '2019-01-31 08:38:11'
    },
    {
        id: 6,
        email: 'ana43@gmail.com',
        balance: '36.00000',
        paymetType: 'Bank Deposit',
        date: '2019-01-31 08:38:11'
    },
    {
        id: 7,
        email: 'ana43@gmail.com',
        balance: '44.00000',
        paymetType: 'Bank Deposit',
        date: '2019-01-31 08:38:11'
    },
    {
        id: 8,
        email: 'ana43@gmail.com',
        balance: '12.00000',
        paymetType: 'Bank Deposit',
        date: '2019-01-31 08:38:11'
    },
];
localStorage.setItem('orderList', JSON.stringify(Orderlist));

class OrderList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            orderList: JSON.parse(localStorage.getItem('orderList')),
            currentPage: 1,
            userPage: 5,
            show: false,
        }
        this.modalShow = this.modalShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick = () => this.setState((currentState) => ({isActive: !currentState.isActive}));

    getUsers() {
        return this.state.orderList;
    }

    componentDidMount() {
        const orderList = this.getUsers();
        this.setState({orderList});
    }

    // Modal Show Hide
    modalShow(){
        this.setState(
            {
                show: true,
            }
        )
    }
    handleClose(){
        this.setState(
            {
                show: false,
            }
        )
    }

    searchsubmit(e) {
        e.preventDefault();
    }

    onDelete = id => (e) => {
        e.preventDefault();
        const UserLists = this.getUsers();
        const deleteUser = UserLists.filter(UserLists => {
            return UserLists.id !== id;
        });
        this.setState({orderList:deleteUser});
        toast.error('Successfully Deleted!');
    };

    search = (e) => {
        const orderList = this.state.orderList;
        const search = e.target.value;
        if (search) {
            const filterUser = orderList.filter(orderList => {
                return orderList.email.toLowerCase().includes(search.toLowerCase());
            });
            this.setState({orderList: filterUser});
        } else {
            const filterUser = JSON.parse(localStorage.getItem('orderList'));
            this.setState({orderList: filterUser});
        }
    };

    // Pagination
    firstPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: 1
            })
        }

    }
    lastPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.orderList.length / this.state.userPage)) {
            this.setState({
                currentPage: Math.ceil(this.state.orderList.length / this.state.userPage)
            })
        }

    }
    prevPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }

    }
    nextPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.orderList.length / this.state.userPage)) {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    }
    paginateNumber = (id) => {
        this.setState({
            currentPage: id
        })
    }

    render() {
        const {orderList, currentPage, userPage} = this.state;
        const lastIndex = currentPage * userPage;
        const firstIndex = lastIndex - userPage;
        const currentUser = orderList ? orderList.slice(firstIndex, lastIndex) : '';
        const totalPage = orderList ? orderList.length / userPage : '';

        const result = Math.ceil(totalPage);
        const pageNumber = [];
        for (let i = 1; i <= Math.ceil(totalPage); i++) {
            pageNumber.push(i);
        }
        return (
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className={`main-wrapper ${this.state.isActive ? 'content-expend' : ''}`}>
                    <div className="container-fluid">
                        <div className="custom-breadcrumb">
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li className="active-item">Buy Coin Order List</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="user-management padding-40">
                            <div className="row">
                                <div className="col-12">
                                    <div className="header-bar">
                                        <div className="table-title">
                                            <h3>Buy Coin Order List</h3>
                                        </div>
                                        <div className="search">
                                            <form onSubmit={this.searchsubmit}>
                                                <div className="form-group">
                                                    <input type="search" name="search" onChange={this.search} className="form-control"
                                                           placeholder="Search"/>
                                                    <button className="btn btn-search">
                                                        <img src={SearchImg} className="img-fluid" alt=""/></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="table-area">
                                        <div className="table-responsive">
                                            <table className="table table-borderless custom-table display text-left"
                                                   width="100%">
                                                <thead>
                                                <tr>
                                                    <th>Email</th>
                                                    <th>Coin amount</th>
                                                    <th>Paymeny Type</th>
                                                    <th>Date</th>
                                                    <th className="text-center">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    currentUser.map(orderlist => {
                                                        return (
                                                            <tr key={orderlist.id}>
                                                                <td>{orderlist.email}</td>
                                                                <td>{orderlist.balance}</td>
                                                                <td><span
                                                                    className={orderlist.paymetType == "Bank Deposit" ? "text-success" : "text-primary"}>{orderlist.paymetType}</span>
                                                                </td>
                                                                <td>{orderlist.date}</td>
                                                                <td>
                                                                    <ul className="d-flex align-items-center justify-content-center">
                                                                        <li onClick={this.modalShow}><Link to="#" ><img
                                                                            src={CheckSqiareImg} className="img-fluid"
                                                                            alt=""/></Link></li>
                                                                        <li onClick={this.onDelete(orderlist.id)}><Link to="#" ><img src={DeleteImg}
                                                                                                        className="img-fluid"
                                                                                                        alt=""/></Link>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="table-pagination">
                                            <ul>
                                                <li><Link onClick={this.prevPage} to="#"><img src={Pagination01} className="img-fluid"
                                                                                              alt=""/></Link></li>
                                                {
                                                    pageNumber.map((number, index) =>{
                                                        return(
                                                            <li onClick={() => this.paginateNumber(number)} className="active" key={number}><Link to="#">{number}</Link></li>
                                                        )
                                                    })
                                                }
                                                <li><Link onClick={this.nextPage} to="#"><img src={RightArrow} className="img-fluid"
                                                                                              alt=""/></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal show={this.state.show} onHide={this.handleClose} animation={false}>
                            <Modal.Header closeButton>
                                <Modal.Title>Cause of Rejection</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="idverifyrejection">
                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <ul className="card-info">
                                                <li className="d-flex justify-content-between">
                                                    <span>Card Holder Name :</span>
                                                    <span>Alfred John</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <span>Card Number :</span>
                                                    <span>0987 5678 9876 1234</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <span>Expaire Date :</span>
                                                    <span>02 - 12- 2022</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <span>CVV Number :</span>
                                                    <span>*******</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>

                    </div>
                </div>
            </div>
        )
    }
}

export default OrderList;