import React, {Component} from "react";
import Chart from "react-apexcharts";

class User_Withdraw_Chart extends Component{
    constructor(props) {
        super(props);
        this.state = {
            series1: [74.86],
            chart: {
                height: 400,
                type: 'radialBar',
            },
            options1: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '50',
                        },
                        dataLabels: {
                            value: {
                                color: "#B4B8D7",
                                fontSize: "20px",
                                offsetY: -5,
                                show: true
                            }
                        }
                    },
                },
                labels: [''],
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#309EF9", "#7ACEFF"],
                        stops: [5, 100]
                    }
                },
            },

            series2: [24.14],
            options2: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '50',
                        },
                        dataLabels: {
                            value: {
                                color: "#B4B8D7",
                                fontSize: "20px",
                                offsetY: -5,
                                show: true
                            }
                        }
                    },
                },
                labels: [''],
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#F89A6B"],
                        stops: [0, 40]
                    }
                },
            },
        };
    }
    render() {
        return(
            <div className="col-xl-4">
                <div className="card cp-user-custom-card">
                    <div className="card-body">
                        <div className="cp-user-card-header-area">
                            <div className="cp-user-title">
                                <h4>Withdrawal Status</h4>
                            </div>
                        </div>
                        <div id="cp-user-withdrawal-chart">
                            <Chart options={this.state.options2} series={this.state.series2} type="radialBar" height={390} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default User_Withdraw_Chart;