import React, {Component} from "react";
import Chart from "react-apexcharts";

class UserChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [{
                data: [
                    [1327359600000, 11],
                    [1327446000000, 15],
                    [1327532400000, 16],
                    [1327618800000, 18],
                    [1327878000000, 13.00],
                    [1327964400000, 22.95],
                    [1328050800000, 23.24],
                    [1328137200000, 20.29],
                    [1328223600000, 17.85],
                    [1328482800000, 16.86],
                    [1328569200000, 18.28],
                    [1328655600000, 21.10],
                    [1328742000000, 26.65],
                    [1328828400000, 24.21],
                    [1329087600000, 29.35],
                    [1329174000000, 32.44],
                    [1329260400000, 18.46],
                    [1329346800000, 24.86],
                    [1329433200000, 22.75],
                    [1329778800000, 18.54],
                    [1329865200000, 19.33],
                    [1329951600000, 14.97],
                    [1330038000000, 15.41],
                    [1330297200000, 12.27],
                    [1330383600000, 8.27],
                    [1330470000000, 9.89],
                    [1330556400000, 8.10],
                    [1330642800000, 7.73],
                    [1330902000000, 11.22],
                    [1330988400000, 13.99],
                    [1331074800000, 18.41],
                    [1331161200000, 20.05],
                    [1331247600000, 29.64],
                    [1331506800000, 33.56],
                    [1331593200000, 42.22],
                    [1331679600000, 35.77],
                    [1331766000000, 31.17],
                    [1331852400000, 47.82],
                    [1332111600000, 52.51],
                    [1332198000000, 46.16],
                    [1332284400000, 41.56],
                    [1332370800000, 43.71],
                    [1332457200000, 40.81],
                    [1332712800000, 39.40],
                    [1332799200000, 34.63],
                    [1332885600000, 29.46],
                    [1332972000000, 34.48],
                    [1333058400000, 37.31],
                    [1333317600000, 42.70],
                    [1333404000000, 49.31],
                    [1333490400000, 46.46],
                    [1333576800000, 47.59],
                    [1333922400000, 55.22],
                    [1334008800000, 59.61],
                    [1334095200000, 58.01],
                    [1334181600000, 57.55],
                    [1334268000000, 61.18],
                    [1334527200000, 59.84],
                    [1334613600000, 67.84],
                    [1334700000000, 65.39],
                    [1334786400000, 61.91],
                    [1334872800000, 66.06],
                    [1335132000000, 69.62],
                    [1335218400000, 71.40],
                    [1335304800000, 67.13],
                    [1335391200000, 63.26],
                    [1335477600000, 46.58],
                    [1335736800000, 32.55],
                    [1335823200000, 21.77],
                    [1335909600000, 18.76],
                    [1335996000000, 21.32],
                    [1336082400000, 28.61],
                    [1336341600000, 27.52],
                    [1336428000000, 36.67],
                    [1336514400000, 33.52],
                    [1336600800000, 31.92],
                    [1336687200000, 30.20],
                    [1336946400000, 34.23],
                    [1337032800000, 38.33],
                    [1337119200000, 37.36],
                    [1337205600000, 44.01],
                    [1337292000000, 49.31],
                    [1337551200000, 53.01],
                    [1337637600000, 57.01],
                    [1337724000000, 60.18],
                    [1337810400000, 61.54],
                    [1337896800000, 69.60],
                    [1338242400000, 67.05],
                    [1338328800000, 72.29],
                    [1338415200000, 77.05],
                    [1338501600000, 75.82],
                    [1338760800000, 70.31],
                    [1338847200000, 72.70],
                    [1338933600000, 70.69],
                    [1339020000000, 72.32],
                    [1339106400000, 73.65],
                    [1339365600000, 67.13],
                    [1339452000000, 68.77],
                    [1339538400000, 65.79],
                    [1339624800000, 71.67],
                    [1339711200000, 75.39],
                    [1339970400000, 74.63],
                    [1340056800000, 71.89],
                    [1340143200000, 76.99],
                    [1340229600000, 77.23],
                    [1340316000000, 81.57],
                    [1340575200000, 83.84],
                    [1340661600000, 80.07],
                    [1340748000000, 81.41],
                    [1340834400000, 87.17],
                    [1340920800000, 82.37],
                    [1341180000000, 86.19],
                    [1341266400000, 80.51],
                    [1341439200000, 87.53],
                    [1341525600000, 83.37],
                    [1341784800000, 85.43],
                    [1341871200000, 84.44],
                    [1341957600000, 82.20],
                    [1342044000000, 73.14],
                    [1342130400000, 71.65],
                    [1342389600000, 75.40],
                    [1342476000000, 79.65],
                    [1342562400000, 70.43],
                    [1342648800000, 73.89],
                    [1342735200000, 61.38],
                    [1342994400000, 62.64],
                    [1343080800000, 66.02],
                    [1343167200000, 65.33],
                    [1343253600000, 73.95],
                    [1343340000000, 79.89],
                    [1343599200000, 78.01],
                    [1343685600000, 78.88],
                    [1343772000000, 78.69],
                    [1343858400000, 74.58],
                    [1343944800000, 73.02],
                    [1344204000000, 75.14],
                    [1344290400000, 70.37],
                    [1344376800000, 77.51],
                    [1344463200000, 67.65],
                    [1344549600000, 62.64],
                    [1344808800000, 64.27],
                    [1344895200000, 61.10],
                    [1344981600000, 59.91],
                    [1345068000000, 53.65],
                    [1345154400000, 54.80],
                    [1345413600000, 56.92],
                    [1345500000000, 59.75],
                ]
            }],
            options: {
                series: [74.86],
                colors: ["#5D58E7"],
                chart: {
                    type: 'radialBar',
                    height: 400,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {}
                }],
                annotations: {
                    xaxis: [{
                        x: new Date('14 Nov 2012').getTime(),
                        borderColor: '#999',
                        yAxisIndex: 0,
                        label: {
                            show: true,
                            text: 'Rally',
                            style: {
                                color: "#fff",
                                background: '#775DD0'
                            }
                        }
                    }]
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                    style: 'hollow',
                },
                tooltip: {
                    x: {
                        format: 'dd MMM yyyy'
                    }
                },
                fill: {
                    colors: undefined,
                    opacity: 0.4,
                    type: 'solid',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '50',
                        },
                        dataLabels: {
                            value: {
                                color: "#B4B8D7",
                                fontSize: "20px",
                                offsetY: -5,
                                show: true
                            }
                        }
                    },
                },
                stroke: {
                    curve: 'smooth',
                    colors: ['#5E55E6', '#F7941E'],
                    width: 3,
                },
                legend: {
                    show: false,
                },
                grid: {
                    show: true,
                    borderColor: '#f2f2f2',
                    opacity: 0.2,
                    strokeDashArray: 0.1,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    row: {
                        colors: undefined,
                        opacity: 0.2
                    },
                    column: {
                        colors: undefined,
                        opacity: 0.2
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        show: true,
                        rotate: -45,
                        rotateAlways: false,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: true,
                        minHeight: undefined,
                        maxHeight: 120,
                        style: {
                            colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            cssClass: 'apexcharts-xaxis-label apexcharts-text',
                        },
                    },
                },
                yaxis: {
                    type: 'datetime',
                    labels: {
                        show: true,
                        rotateAlways: false,
                        hideOverlappingLabels: true,
                        showDuplicates: false,
                        trim: true,
                        minHeight: undefined,
                        maxHeight: 120,
                        style: {
                            colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            cssClass: 'apexcharts-xaxis-label apexcharts-text',
                        },
                    },
                },
            },
            selection: 'one_year',
        };
    }
    render() {
        return (
            <div>
                <div className="user-chart">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-top">
                                        <h4>User</h4>
                                        <ul>
                                            <li className="active">Day</li>
                                            <li>Month</li>
                                            <li>Year</li>
                                        </ul>
                                    </div>
                                    <div id="user-chart">
                                        <Chart options={this.state.options} series={this.state.series} type="area"
                                               height={390}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserChart;