import React, {Component} from "react";
import Sidebar from "../../Sidebar";
import Header from "../../Header";

class NodeHistory extends Component{

    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
        }
    }
    handleClick = () => this.setState((currentState) => ({isActive: !currentState.isActive}));

    onSubmitFrom = (e) =>{
        e.preventDefault();
    }

    render() {
        return(
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className={`main-wrapper ${this.state.isActive ? 'content-expend' : ''}`}>
                    <div className="container-fluid">
                        <div className="custom-breadcrumb">
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li>My wallet</li>
                                        <li className="active-item">Node history</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="user-management pidverify">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="node-history">
                                            <form onSubmit={this.onSubmitFrom}>
                                                <div className="form-group">
                                                    <label>Node Transaction By Hash</label>
                                                    <input type="text" className="form-control"
                                                           placeholder="Transaction Hash"/>
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" className="btn">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default NodeHistory;