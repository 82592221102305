import React, {Component, useState} from "react";
import Sidebar from "../../../Sidebar";
import Header from "../../../Header";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import FrontImage from '../../../../../assets/admin/images/id-front.svg';
import BackImage from '../../../../../assets/admin/images/id-back.svg';
import {Link} from "react-router-dom";
import {toast} from "react-toastify";


class Verify extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            show: false
        }
        this.modalShow = this.modalShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    handleClick = () => this.setState((currentState) => ({isActive: !currentState.isActive}));

    modalShow(){
        this.setState(
            {
                show: true,
            }
        )
    }
    handleClose(){
        this.setState(
            {
                show: false,
            }
        )
    }
    approveId(){
        toast.success('Successfully Approved!');
    }

    render() {
        return(
            <div>
                <Sidebar isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <Header isActive={this.state.isActive} isActiveMenu={this.handleClick}/>
                <div className="main-wrapper">
                    <div className="container-fluid">

                        <div className="custom-breadcrumb">
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li>User management</li>
                                        <li className="active-item">Pending ID varification</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="user-management pidverify">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="header-bar">
                                            <div className="table-title">
                                                <h3>Pending ID varification</h3>
                                            </div>
                                        </div>
                                        <div className="id-varify">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-4 mb-xl-0 mb-4">
                                                    <div className="card-wrapper">
                                                        <div className="card-area">
                                                            <img src={FrontImage} className="img-fluid" alt=""/>
                                                        </div>
                                                        <h4>NID Front Side</h4>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div className="card-wrapper">
                                                        <div className="card-area">
                                                            <img src={BackImage} className="img-fluid" alt=""/>
                                                        </div>
                                                        <h4>NID Back Side</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="id-verify-btn-group">
                                                <li onClick={this.approveId}><Link to="#" className="approve-btn">Approve</Link></li>
                                                <li onClick={this.modalShow}><Link to="#" data-toggle="modal" data-target="#pidverify"
                                                       className="reject-btn">Reject</Link></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <Modal show={this.state.show} onHide={this.handleClose} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Cause of Rejection</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="modal-body pidverify-page">
                                <div className="idverifyrejection">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <form>
                                                <div className="form-group m-0">
                                                    <textarea className="form-control">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</textarea>
                                                </div>
                                            </form>
                                            <button type="button" className="btn">Send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            </div>
        )
    }
}
export default Verify;