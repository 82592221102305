import React, {Component} from "react";
import {Link} from "react-router-dom";
import Pagination01 from "../../../../../assets/admin/images/angle-left.svg";
import RightArrow from "../../../../../assets/admin/images/angle-right.svg";
import View from "../../../../../assets/admin/images/user-management-icons/activity/view.svg";
import User from "../../../../../assets/admin/images/user-management-icons/activity/user-1.svg";
import BlockUser from "../../../../../assets/admin/images/user-management-icons/activity/check-square.svg";
import Search from "../../../../../assets/admin/images/search.svg";
import {toast} from "react-toastify";

const suspendedUser = [
    {
        first_name: 'Ana',
        last_name: 'Albert',
        email: 'ana43@gmail.com',
        phone: '1234567890',
        role: 'User',
        date: '2019-01-31 08:38:11',
        id: '1',
    },
    {
        first_name: 'Mona',
        last_name: 'William',
        email: 'mona876@email.com',
        phone: '1234567890',
        role: 'User',
        date: '2019-01-31 08:38:11',
        id: '2',
    },
    {
        first_name: 'Tina',
        last_name: 'John',
        email: 'tinajohn87@gmail.com',
        phone: '1234567890',
        role: 'User',
        date: '2019-01-31 08:38:11',
        id: '3',
    },

];
localStorage.setItem('suspendedUser', JSON.stringify(suspendedUser));

class SuspendedUser extends Component{

    constructor(props) {
        super(props);
        this.state ={
            suspendedUser: JSON.parse(localStorage.getItem('suspendedUser')),
            UserLists: JSON.parse(localStorage.getItem('users')),
            users: [],
            currentPage: 1,
            userPage: 5,
        }
    }

    getUsers() {
        return this.state.suspendedUser;
    }
    componentDidMount() {
        const suspendedUser = this.getUsers();
        this.setState({suspendedUser});
    }
    search = (e) => {
        const suspendedUser = this.getUsers();
        const search = e.target.value;

        if (search) {
            const filterUser = suspendedUser.filter(suspendedUser => {
                return suspendedUser.first_name.toLowerCase().includes(search.toLowerCase());
            });
            this.setState({suspendedUser: filterUser});
        } else {
            const filterUser = JSON.parse(localStorage.getItem('suspendedUser'));
            this.setState({suspendedUser: filterUser});
        }

        // localStorage.setItem('users', JSON.stringify(filterUser));
    };
    searchsubmit(e) {
        e.preventDefault();
    }
    firstPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: 1
            })
        }

    }
    lastPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.suspendedUser.length / this.state.userPage)) {
            this.setState({
                currentPage: Math.ceil(this.state.suspendedUser.length / this.state.userPage)
            })
        }

    }
    prevPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }

    }
    nextPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.suspendedUser.length / this.state.userPage)) {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    }
    paginateNumber = (id) => {
        this.setState({
            currentPage: id
        })
    }

    userAtive = id => (e) =>{
        e.preventDefault();
        const suspendedUser = this.getUsers();
        const reActiveUser = suspendedUser.filter(UserLists => {
            return UserLists.id === id;
        });
        if (JSON.parse(localStorage.getItem('users')) !== null){
            var users = JSON.parse(localStorage.getItem('users'));
        }else {
            var users = [];
        }
        users.push({
            first_name: reActiveUser[0].first_name,
            last_name: reActiveUser[0].last_name,
            email: reActiveUser[0].email,
            phone: reActiveUser[0].phone,
            role: reActiveUser[0].role,
            date: reActiveUser[0].date,
            id: reActiveUser[0].id,
        });
        localStorage.setItem('users', JSON.stringify(users));

        const filterUser = suspendedUser.filter(UserLists => {
            return UserLists.id !== id;
        });
        this.setState({suspendedUser: filterUser});
        localStorage.setItem('suspendedUser', JSON.stringify(filterUser));
        toast.success('Successfully!');
    }

    render() {
        const {suspendedUser, currentPage, userPage} = this.state;
        const lastIndex = currentPage * userPage;
        const firstIndex = lastIndex - userPage;
        const currentUser = suspendedUser ? suspendedUser.slice(firstIndex, lastIndex) : '';
        const totalPage = suspendedUser ? suspendedUser.length / userPage : '';

        const result= Math.ceil(totalPage);
        const pageNumber = [];
        for (let i = 1; i <= Math.ceil(totalPage); i++){
            pageNumber.push(i);
        }
        return(
            <div className="tab-pane" id="pills-suspended-user" role="tabpanel"
                 aria-labelledby="pills-suspended-user-tab">
                <div className="header-bar">
                    <div className="table-title">
                        <h3>Suspended User</h3>
                    </div>
                    <div className="search">
                        <form onSubmit={this.searchsubmit}>
                            <div className="form-group">
                                <input type="search" name="search" onChange={this.search} className="form-control"
                                       placeholder="Search"/>
                                <button className="btn btn-search">
                                    <img src={Search} className="img-fluid" alt=""/></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="table-area">
                    <div className="table-responsive">
                        <table className="table table-borderless custom-table display text-center"
                               width="100%">
                            <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email ID</th>
                                <th>Updated At</th>
                                <th>Activity</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.suspendedUser == null

                                ?
                                    <tr>
                                        <td>No data found! Please add some data</td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                :
                                    currentUser.map(sespendUser => {
                                    return(
                                        <tr key={sespendUser.id}>
                                            <td>{sespendUser.first_name}</td>
                                            <td>{sespendUser.last_name}</td>
                                            <td>{sespendUser.email}</td>
                                            <td>{sespendUser.date}</td>
                                            <td>
                                                <ul className="d-flex align-items-center justify-content-center">
                                                    <li><Link to="/user-view"><img
                                                        src={View}
                                                        className="img-fluid" alt=""/></Link></li>
                                                    <li><Link to="/"><img
                                                        src={User} alt=""/></Link>
                                                    </li>
                                                    <li><Link onClick={this.userAtive(sespendUser.id)}><img
                                                        src={BlockUser}
                                                        alt=""/></Link></li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )
                                })

                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="table-pagination">
                        <ul>
                            <li><Link onClick={this.prevPage} to="#"><img src={Pagination01} className="img-fluid"
                                                                          alt=""/></Link></li>
                            {
                                pageNumber.map((number, index) =>{
                                    return(
                                        <li onClick={() => this.paginateNumber(number)} className="active" key={number}><Link to="#">{number}</Link></li>
                                    )
                                })
                            }
                            <li><Link onClick={this.nextPage} to="#"><img src={RightArrow} className="img-fluid"
                                                                          alt=""/></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default SuspendedUser;