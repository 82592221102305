import React, {Component} from "react";
import UploadImage from '../../../assets/user/images/file-upload.svg'
import User_Header from "../Header";
import User_Sidebar from "../Sidebar";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

class BankInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBankImage: '',
            addClass: false,
            isMenu: false,
        }
    }

    //From Submit Handler
    fromSumitHandler = (e) => {
        e.preventDefault()
    }
    isBankImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                isBankImage: URL.createObjectURL(event.target.files[0])
            });
        }
        toast.success('Successfully Added!');
    }
    toggleClass = () => {
        this.setState({
            isMenu: !this.state.isMenu
        })
    }

    render() {
        return (
            <div>
                <User_Header toggleClass={this.toggleClass} />
                <User_Sidebar sidebarClass={this.state.isMenu}/>
                <div className="cp-user-main-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 mb-xl-0 mb-4">
                                <div className="card cp-user-custom-card cp-user-cp-user-credit-card-area">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <h4>Buy Our Coin From Here</h4>
                                        </div>
                                        <div className="cp-user-buy-coin-content-area">
                                            <p>Curabitur at metus vel risus posuere fringilla sit amet vel tortor.
                                                Praesent blandit dolor in mi sodales, ut dictum metus efficitur. Nulla
                                                pulvinar enim ligula.</p>
                                            <div className="cp-user-coin-info">
                                                <form onSubmit={this.fromSumitHandler}>
                                                    <div className="form-group">
                                                        <label>Coin Amount</label>
                                                        <input type="text" className="form-control"
                                                               placeholder="Your Amount . . ." name="coin-amout"/>
                                                    </div>
                                                    <div className="cp-user-payment-type">
                                                        <h3>Payment Type</h3>
                                                        <div className="form-group">
                                                            <Link to="/user-bitcoin">
                                                                <input type="radio" name="coin" id="cp-user-bit-coin"/>
                                                                <label htmlFor="cp-user-bit-coin">Bit Coin</label>
                                                            </Link>
                                                        </div>
                                                        <div className="form-group">
                                                            <Link to="/user-credit-card">
                                                                <input type="radio" name="coin" id="cp-user-credit-card"/>
                                                                <label htmlFor="cp-user-credit-card">Credit Card</label>
                                                            </Link>
                                                        </div>
                                                        <div className="form-group">
                                                            <Link to="/user-bank-info">
                                                                <input type="radio" name="coin" defaultChecked id="cp-user-bank-deposit"/>
                                                                <label htmlFor="cp-user-bank-deposit">Bank
                                                                    Deposit</label>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="cp-user-upload-info">
                                                    <label htmlFor="cp-user-upload-bank-info">
                                                        {this.state.isBankImage !== '' ?
                                                            <img src={this.state.isBankImage} alt="" width="280" height="115"/> :
                                                            <img src={UploadImage} className="img-fluid" alt=""/>}

                                                        <input onChange={this.isBankImage} type="file"
                                                               id="cp-user-upload-bank-info"/>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="card cp-user-custom-card">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <h4>Today’s Coin Rate</h4>
                                        </div>
                                        <div className="cp-user-coin-rate">
                                            <ul>
                                                <li>1 Cpocketo Coin</li>
                                                <li>=</li>
                                                <li>00.098642 USD</li>
                                            </ul>
                                            <div className="cp-user-cp-user-coin-info-area">
                                                <h5>Please Upload Your Card Information</h5>
                                                <div className="cp-user-copy cp-user-bank-info">
                                                    <ul>
                                                        <li>
                                                            <span>Bank Name </span>
                                                            <span className="cp-user-dot">:</span>
                                                            <span className="text-right">Sample Bank</span>
                                                        </li>
                                                        <li>
                                                            <span>Branch</span>
                                                            <span className="cp-user-dot">:</span>
                                                            <span className="text-right">Newyork</span>
                                                        </li>
                                                        <li>
                                                            <span>Account Name</span>
                                                            <span className="cp-user-dot">:</span>
                                                            <span className="text-right">John Doe</span>
                                                        </li>
                                                        <li>
                                                            <span>Account Number</span>
                                                            <span className="cp-user-dot">:</span>
                                                            <span className="text-right">987 6531 4570 87 655</span>
                                                        </li>
                                                        <li>
                                                            <span>Routing Number</span>
                                                            <span className="cp-user-dot">:</span>
                                                            <span className="text-right">987 6531 4570 87 655</span>
                                                        </li>
                                                    </ul>
                                                    <button className="btn cp-user-buy-btn">Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BankInfo;