import React, {Component} from "react";
import Search from "../../../../../assets/admin/images/search.svg";
import Joi from "joi-browser";
import {toast} from "react-toastify";
import {Redirect} from "react-router";

class UserAdd extends Component {

    userData;
    newDate;

    constructor(props) {
        super(props);
        this.Fromsubmit = this.Fromsubmit.bind(this);
        this.state = {
            users: [],
            date: '',
            email: '',
            first_name: '',
            last_name: '',
            phone: '',
            role: '',
            error: {}
        }
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
    }

    getKey() {
        return Math.random().toString(16).slice(-4);
    }

    componentDidMount() {
        var that = this;
        var date = new Date().getDate(); //Current Date
        var month = new Date().getMonth() + 1; //Current Month
        var year = new Date().getFullYear(); //Current Year
        var hours = new Date().getHours(); //Current Hours
        var min = new Date().getMinutes(); //Current Minutes
        var sec = new Date().getSeconds(); //Current Seconds
        that.setState({
            //Setting the value of the date time
            date:
                date + '/' + month + '/' + year + ' ' + hours + ':' + min + ':' + sec,
        });
    }


// From Validation
    schema = {
        email: Joi.string().email({minDomainAtoms: 2}).required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "string.email":
                        err.message = "Email Mast be a Valid email";
                        break;
                    default:
                        err.message = "EMAIL CAN NOT BE EMPTY";
                        break;
                }
            });
            return errors;
        }),
        first_name: Joi.string()
            .required()
            .label('First Name')
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        // eslint-disable-next-line no-unused-expressions
                        default:
                            // eslint-disable-next-line no-unused-expressions
                            err.message;
                            break;
                    }
                });
                return errors;
            }),
        last_name: Joi.string()
            .required()
            .label('Last Name')
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        // eslint-disable-next-line no-unused-expressions
                        default:
                            // eslint-disable-next-line no-unused-expressions
                            err.message;
                            break;
                    }
                });
                return errors;
            }),
        phone: Joi.string()
            .required()
            .label('Phone Number')
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        // eslint-disable-next-line no-unused-expressions
                        default:
                            // eslint-disable-next-line no-unused-expressions
                            err.message;
                            break;
                    }
                });
                return errors;
            }),
        role: Joi.string()
            .required()
            .label('Role')
            .error(errors => {
                errors.forEach(err => {
                    switch (err.type) {
                        // eslint-disable-next-line no-unused-expressions
                        default:
                            // eslint-disable-next-line no-unused-expressions
                            err.message;
                            break;
                    }
                });
                return errors;
            }),
    }
    changeHandler = event => {
        const error = {...this.state.error};
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    };

    onkeyUpHandler = (event) => {

        const error = {...this.state.error};
        if (event.target.name === 'confirm_password') {
            if (this.state.password !== this.state.confirm_password) {
                error[event.target.name] = "Password don't match";
            } else {
                delete error[event.target.name];
            }
        }

        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    }

    validationProperty = event => {
        const Obj = {[event.target.name]: event.target.value};
        const schema = {[event.target.name]: this.schema[event.target.name]}
        const {error} = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null
    };

    validate = () => {
        const options = {abortEarly: false}
        const form = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            phone: this.state.phone,
            role: this.state.role,
            email: this.state.email,
        }
        const {error} = Joi.validate(form, this.schema, options)

        let cfPasserrors = {};
        if (this.state.password !== this.state.confirm_password || this.state.confirm_password === '') {
            cfPasserrors.confirm_password = "Password don't match";
        } else {
            delete cfPasserrors.confirm_password;
            cfPasserrors = null;
        }

        if (!error && !cfPasserrors) return null;
        if (!error && cfPasserrors) return cfPasserrors;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message

        if (this.state.password !== this.state.confirm_password || this.state.confirm_password === '') {
            errors.confirm_password = "Password don't match";
        } else {
            delete errors.confirm_password;
        }
        return errors;
    };

    checkHandler = () => {
        this.setState({
            remember: !this.state.remember
        })
    };
    showpasshandler = () => {
        this.setState({
            showpass: !this.state.showpass
        })
    };
// From Submit
    Fromsubmit = function (e) {
        e.preventDefault();
        const error = this.validate()
        if (!error) {
            var first_name = this.refs.first_name.value;
            var last_name = this.refs.last_name.value;
            var email = this.refs.email.value;
            var phone = this.refs.phone.value;
            var role = this.refs.role.value;
            var newDate = this.state.date;
            var id = this.getKey();
            if (localStorage.getItem('users') == null) {
                var users = [];
                users.push({
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    phone: phone,
                    role: role,
                    date: newDate,
                    id: id,
                })
                localStorage.setItem('users', JSON.stringify(users));
                toast.success('Successfully Added');
            } else {
                var users = JSON.parse(localStorage.getItem('users'));
                users.push({
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    phone: phone,
                    role: role,
                    date: newDate,
                    id: id,
                });
                localStorage.setItem('users', JSON.stringify(users));
                toast.success('Successfully Added');
                this.setState({
                    users: users,
                    email: '',
                    first_name: '',
                    last_name: '',
                    phone: '',
                    role: '',
                    error: {}
                });
                return <Redirect to="/user" />
            }
        } else {
            toast.error('Oppss something wrong!');
            this.setState({
                error: error || {}
            })
        }
    };


    render() {
        return (
            <div className="tab-pane" id="pills-add-user" role="tabpanel" aria-labelledby="pills-add-user-tab">
                <div className="header-bar">
                    <div className="table-title">
                        <h3>Add User</h3>
                    </div>
                </div>
                <div className="add-user-form">
                    <form onSubmit={this.Fromsubmit}>
                        <div className="row">
                            <div className="col-lg-6 mb-lg-0 mb-4">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" className="form-control" placeholder="First Name"
                                           onChange={this.changeHandler} value={this.state.first_name}
                                           name="first_name" ref="first_name"/>
                                    <span style={{color: "red"}}>{this.state.error.first_name && this.state.error.first_name}</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-12 mb-lg-0 mb-4">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" placeholder="Last Name" name="last_name"
                                           onChange={this.changeHandler} value={this.state.last_name}
                                           ref="last_name"/>
                                    <span style={{color: "red"}}>{this.state.error.last_name && this.state.error.last_name}</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-12 mb-lg-0 mb-4">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" className="form-control" placeholder="Email" name="email"
                                           onChange={this.changeHandler} value={this.state.email}
                                           ref="email"/>
                                    <span style={{color: "red"}}>{this.state.error.email && this.state.error.email}</span>
                                </div>
                            </div>
                            <div className="col-xl-6 col-12 mb-lg-0 mb-4">
                                <div className="form-group">
                                    <label>Phone</label>
                                    <input type="text" className="form-control" placeholder="Phone" name="phone"
                                           onChange={this.changeHandler} value={this.state.phone}
                                           ref="phone"/>
                                    <span style={{color: "red"}}>{this.state.error.phone && this.state.error.phone}</span>

                                </div>
                            </div>
                            <div className="col-xl-6 col-12">
                                <div className="form-group">
                                    <label>Role</label>
                                    <div className="select-area">
                                        <select className="form-control" name="role" ref="role" onChange={this.changeHandler} value={this.state.role}>
                                            <option>Select</option>
                                            <option>User</option>
                                            <option>Admin</option>
                                        </select>
                                    </div>
                                    <span style={{color: "red"}}>{this.state.error.role && this.state.error.role}</span>
                                </div>
                            </div>
                            <div className="col-12">
                                <button className="btn add-user-btn">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default UserAdd;