import React, {Component} from "react";
import User_Header from "../Header";
import User_Dashboard from "../Dashboard";

import BuyCoinImg from '../../../assets/user/images/buy-coin-vector.svg'
import User_Sidebar from "../Sidebar";
import {Link} from "react-router-dom";

class BuyCoin extends Component{
    constructor(prop) {
        super(prop);
        this.state = {
            isMenu: false,
        }
    }

    fromSubmit = (e) =>{
      e.preventDefault()
    };
    toggleClass = () => {
        this.setState({
            isMenu: !this.state.isMenu
        })
    }
    render() {
        let sidebarClass = ["cp-user-sidebar"];
            if(this.state.addClass) {
            sidebarClass.push('cp-user-sidebar-show');
            }
        return(
            <div>
                <User_Header toggleClass={this.toggleClass} />
                <User_Sidebar sidebarClass={this.state.isMenu}/>
                <div className="cp-user-main-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 mb-xl-0 mb-4">
                                <div className="card cp-user-custom-card">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <h4>Buy Our Coin From Here</h4>
                                        </div>
                                        <div className="cp-user-buy-coin-content-area">
                                            <p>Curabitur at metus vel risus posuere fringilla sit amet vel tortor.
                                                Praesent blandit dolor in mi sodales, ut dictum metus efficitur. Nulla
                                                pulvinar enim ligula.</p>
                                            <div className="cp-user-coin-info">
                                                <form onSubmit={this.fromSubmit}>
                                                    <div className="form-group">
                                                        <label>Coin Amount</label>
                                                        <input type="text" className="form-control"
                                                               placeholder="Your Amount . . ." name="coin-amout"/>
                                                    </div>
                                                    <div className="cp-user-payment-type">
                                                        <h3>Payment Type</h3>
                                                        <div className="form-group">
                                                            <Link to="/user-bitcoin">
                                                                <input type="radio" name="coin" id="cp-user-bit-coin"/>
                                                                <label htmlFor="cp-user-bit-coin">Bit Coin</label>
                                                            </Link>
                                                        </div>
                                                        <div className="form-group">
                                                            <Link to="/user-credit-card">
                                                                <input type="radio" name="coin" id="cp-user-credit-card"/>
                                                                <label htmlFor="cp-user-credit-card">Credit Card</label>
                                                            </Link>
                                                        </div>
                                                        <div className="form-group">
                                                            <Link to="/user-bank-info">
                                                                <input type="radio" name="coin" id="cp-user-bank-deposit"/>
                                                                <label htmlFor="cp-user-bank-deposit">Bank
                                                                    Deposit</label>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="card cp-user-custom-card">
                                    <div className="card-body">
                                        <div className="cp-user-card-header-area">
                                            <h4>Today’s Coin Rate</h4>
                                        </div>
                                        <div className="cp-user-coin-rate">
                                            <ul>
                                                <li>1 Cpocketo Coin</li>
                                                <li>=</li>
                                                <li>00.098642 USD</li>
                                            </ul>
                                            <div className="img">
                                                <img src={BuyCoinImg} className="img-fluid" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default BuyCoin;