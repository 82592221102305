import React, {Component} from "react";
import User_Sidebar from "../Sidebar";
import User_Header from "../Header";
import Key from '../../../assets/user/images/wallet-table-icons/Key.svg';
import Wallet from '../../../assets/user/images/wallet-table-icons/wallet.svg';
import Share from '../../../assets/user/images/wallet-table-icons/share.svg';
import Send from '../../../assets/user/images/wallet-table-icons/send.svg';
import QR from '../../../assets/user/images/QR.svg';
import Modal from "react-bootstrap/Modal";
import AddPocket from '../../../assets/user/images/add-pockaet-vector.svg'
import {Link} from "react-router-dom";
import Pagination01 from "../../../assets/admin/images/angle-left.svg";
import RightArrow from "../../../assets/admin/images/angle-right.svg";
import {toast} from "react-toastify";


const TransactionActivity = [
    {
        id: 1,
        name: 'Btc Wallet 1',
        balance: '0.000000',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 2,
        name: 'Btc Wallet 2',
        balance: '0.000000',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 3,
        name: 'Btc Wallet 3',
        balance: '0.000000',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 4,
        name: 'Btc Wallet 4',
        balance: '0.000000',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 5,
        name: 'Btc Wallet 5',
        balance: '0.000000',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 6,
        name: 'Btc Wallet 6',
        balance: '0.000000',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 7,
        name: 'Btc Wallet 7',
        balance: '0.000000',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 8,
        name: 'Btc Wallet 8',
        balance: '0.000000',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 9,
        name: 'Btc Wallet 9',
        balance: '0.000000',
        date: '2019-01-24 12:50:17',
    },
    {
        id: 10,
        name: 'Btc Wallet 10',
        balance: '0.000000',
        date: '2019-01-24 12:50:17',
    },
];

class MyWallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showTwo: false,
            TransactionActivity: TransactionActivity,
            currentPage: 1,
            userPage: 5,
            isMenu: false,
            isDeposite: false,
            isWithdraw: false,
            isActivity: false,
            openDeposive: true,
            openWithdraw: false,
            showAddress: false,
            hideSection: true,
        };
        this.keyCount = 0;
        this.getKey = this.getKey.bind(this);
        this.modalShow = this.modalShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.modalShowTwo = this.modalShowTwo.bind(this);
        this.handleCloseTwo = this.handleCloseTwo.bind(this);
    }

    activeDeposite = () =>{
        this.setState({
            isWithdraw: false,
            isActivity: false,
            isDeposite: true,
        })
    }
    activeWithdraw = () =>{
        this.setState({
            isDeposite: false,
            isActivity: false,
            isWithdraw: true,
        })
    }
    activeActivity = () =>{
        this.setState({
            isDeposite: false,
            isWithdraw: false,
            isActivity: true,
        })
    }
    openDeposive = () =>{
        this.setState({
            openWithdraw: false,
            openDeposive: true,
        })
    }
    openWithdraw = () =>{
        this.setState({
            openDeposive: false,
            openWithdraw: true,
        })
    }
    showAddress = () =>{
        this.setState({
            showAddress: !this.state.showAddress,
        })
    }
    showDeposite = () =>{
        this.setState({
            hideSection: false,
            isWithdraw: false,
            isActivity: false,
            isDeposite: true,
        })
    };
    showActivity = () =>{
        this.setState({
            hideSection: false,
            isWithdraw: false,
            isDeposite: false,
            isActivity: true,
        })
    };
    showWallet = () =>{
        this.setState({
            hideSection: false,
            isActivity: false,
            isDeposite: false,
            isWithdraw: true,
        })
    };
    allWallet = () =>{
        this.setState({
            hideSection: true,
            isWithdraw: false,
            isActivity: false,
            isDeposite: false,
        })
    }

    getKey() {
        return Math.random().toString(16).slice(-4);
    }

    modalShow = (e) => {
        this.setState(
            {
                show: true,
            }
        )
    }

    handleClose = (e) => {
        this.setState(
            {
                show: false,
            }
        )
    }

    modalShowTwo() {
        this.setState(
            {
                showTwo: true,
            }
        )
    }

    handleCloseTwo() {
        this.setState(
            {
                showTwo: false,
            }
        )
    }

    // Pagination
    firstPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: 1
            })
        }

    };
    lastPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.TransactionActivity.length / this.state.userPage)) {
            this.setState({
                currentPage: Math.ceil(this.state.TransactionActivity.length / this.state.userPage)
            })
        }

    };
    prevPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }

    };
    nextPage = (e) => {
        e.preventDefault();
        if (this.state.currentPage < Math.ceil(this.state.TransactionActivity.length / this.state.userPage)) {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    };
    paginateNumber = (id) => {
        this.setState({
            currentPage: id
        })
    };

    addPocket = (e) => {
        e.preventDefault()
        const name = e.target.name.value;
        this.state.TransactionActivity.push({
            id: this.getKey(),
            name: name,
            balance: '0.000000',
            date: '2019-01-24 12:50:17',
        });
        this.setState({
            showTwo: false,
        })
        toast.success('Successfully Added');
    }
    toggleClass = () => {
        this.setState({
            isMenu: !this.state.isMenu
        })
    }

    render() {
        const {TransactionActivity, currentPage, userPage} = this.state;
        const lastIndex = currentPage * userPage;
        const firstIndex = lastIndex - userPage;
        const currentUser = TransactionActivity ? TransactionActivity.slice(firstIndex, lastIndex) : '';
        const totalPage = TransactionActivity ? TransactionActivity.length / userPage : '';

        const result = Math.ceil(totalPage);
        const pageNumber = [];
        for (let i = 1; i <= Math.ceil(totalPage); i++) {
            pageNumber.push(i);
        }
        let sidebarClass = ["cp-user-sidebar"];
        if (this.state.addClass) {
            sidebarClass.push('cp-user-sidebar-show');
        }
        return (

            <div>


                <User_Header toggleClass={this.toggleClass}/>
                <User_Sidebar sidebarClass={this.state.isMenu}/>

                {
                    this.state.hideSection  &&  <div className="cp-user-main-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card cp-user-custom-card cp-user-wallet-card">
                                        <div className="card-body">
                                            <div className="cp-user-card-header-area">
                                                <div className="cp-user-title">
                                                    <h4>Pocket Transaction Activity</h4>
                                                </div>
                                                <div className="buttons">
                                                    <button onClick={this.modalShowTwo}
                                                            className="btn cp-user-add-pocket">Add Pocket
                                                    </button>
                                                    <button onClick={this.modalShow}
                                                            className="btn link-button cp-user-move-coin">Move Coin
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="cp-user-wallet-table table-responsive">
                                                <table className="table table-borderless cp-user-custom-table" width="100%">
                                                    <thead>
                                                    <tr>
                                                        <th className="all">Name</th>
                                                        <th className="desktop">Balance</th>
                                                        <th className="desktop">Updated At</th>
                                                        <th className="all">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        currentUser.map(mypocket => {
                                                            return (
                                                                <tr key={mypocket.id}>
                                                                    <td>{mypocket.name}</td>
                                                                    <td>{mypocket.balance}</td>
                                                                    <td>{mypocket.date}</td>
                                                                    <td>
                                                                        <ul className="d-flex justify-content-center align-items-center">
                                                                            <li>
                                                                                <Link to="#">
                                                                                    <img src={Key}
                                                                                         className="img-fluid" alt=""/>
                                                                                </Link>
                                                                            </li>
                                                                            <li onClick={this.showDeposite}>
                                                                                <Link to="#" data-toggle="modal"
                                                                                      data-target="#exampleModalCenter">
                                                                                    <img src={Wallet}
                                                                                         className="img-fluid" alt=""/>
                                                                                </Link>
                                                                            </li>
                                                                            <li onClick={this.showWallet}>
                                                                                <Link to="#" data-toggle="modal"
                                                                                      data-target="#add-pocket">
                                                                                    <img src={Share}
                                                                                         className="img-fluid" alt=""/>
                                                                                </Link>
                                                                            </li>
                                                                            <li onClick={this.showActivity}>
                                                                                <Link to="#">
                                                                                    <img src={Send}
                                                                                         className="img-fluid" alt=""/>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }).reverse()
                                                    }

                                                    </tbody>
                                                </table>


                                                <div className="cp-user-pagin">
                                                    <ul className="d-flex justify-content-center">
                                                        <li><Link onClick={this.prevPage} to="#"><img src={Pagination01}
                                                                                                      className="img-fluid"
                                                                                                      alt=""/></Link></li>
                                                        {
                                                            pageNumber.map((number, index) => {
                                                                return (
                                                                    <li onClick={() => this.paginateNumber(number)}
                                                                        className="active" key={number}><Link
                                                                        to="#">{number}</Link></li>
                                                                )
                                                            })
                                                        }
                                                        <li><Link onClick={this.nextPage} to="#"><img src={RightArrow}
                                                                                                      className="img-fluid"
                                                                                                      alt=""/></Link></li>
                                                    </ul>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal centered show={this.state.show} onHide={this.handleClose} animation={false}>
                            <img src={require('../../../assets/user/images/move-coin-modal-img.svg')} />
                            <Modal.Header closeButton>
                                <Modal.Title>Want To Move Coin ?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <div className="form-group">
                                        <label>From</label>
                                        <div className="cp-user-select-area">
                                            <select className="form-control">
                                                <option>Select Reciever Account</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>To</label>
                                        <div className="cp-user-select-area">
                                            <select className="form-control">
                                                <option>Select Reciever Account</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input type="text" className="form-control"
                                               placeholder="Enter Coin Amount . . ."/>
                                    </div>
                                </form>
                                <button type="button" className="btn btn-block cp-user-move-btn">Move Coin
                                </button>
                            </Modal.Body>
                        </Modal>

                        <Modal centered show={this.state.showTwo} onHide={this.handleCloseTwo} animation={false}>
                        <img src={require('../../../assets/user/images/add-pockaet-vector.svg')} />
                            <Modal.Header closeButton>
                                <Modal.Title>Want to Add pocket?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form onSubmit={this.addPocket}>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input type="text" name="name" ref="name" className="form-control"
                                               placeholder="Write Your Pocket Name. . ."/>
                                    </div>
                                    <button type="submit" className="btn btn-block cp-user-move-btn">add
                                        pocket
                                    </button>
                                </form>
                            </Modal.Body>
                        </Modal>
                    </div>
                }

                {
                    this.state. hideSection === false && <div className="cp-user-main-wrapper">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="cp-user-my-wallet-header">
                                        <div><h4>BTC Wallet 1</h4></div>
                                        <div className="cp-user-wallet-card">
                                            <a onClick={this.allWallet} href="#" className="btn cp-user-move-coin">All Wallets</a>
                                        </div>
                                    </div>
                                    <ul className="nav cp-user-profile-nav" id="pills-tab" role="tablist">
                                        <li onClick={this.activeDeposite} className="nav-item">
                                            <a className={ this.state.isDeposite ? "nav-link active" : "nav-link"} id="pills-profile-tab" data-toggle="pill"
                                               aria-selected="true">
                                <span className="cp-user-img">
                                    <img src={Wallet} className="img-fluid" alt=""/>
                                </span>
                                                Deposite
                                            </a>
                                        </li>
                                        <li onClick={this.activeWithdraw} className="nav-item">
                                            <a className={ this.state.isWithdraw ? "nav-link active" : "nav-link"} id="pills-edit-profile-tab" data-toggle="pill"
                                               aria-selected="true">
                                <span className="cp-user-img">
                                    <img src={Send} className="img-fluid" alt=""/>
                                </span>
                                                Withdraw
                                            </a>
                                        </li>
                                        <li onClick={this.activeActivity} className="nav-item">
                                            <a className={ this.state.isActivity ? "nav-link active" : "nav-link"} id="pills-phone-verify-tab" data-toggle="pill"
                                               aria-selected="true">
                                <span className="cp-user-img">
                                    <img src={Share} className="img-fluid" alt=""/>
                                </span>
                                                Activity
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content cp-user-profile-tab-content" id="pills-tabContent">
                                        {
                                            this.state.isDeposite && <div className="tab-pane fade show active" id="pills-profile" role="tabpanel"
                                                                          aria-labelledby="pills-profile-tab">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="card cp-user-custom-card">
                                                            <div className="card-body">
                                                                <div className="cp-user-profile-header">
                                                                    <h5>Deposit</h5>
                                                                </div>

                                                                <div className="cp-user-deposit-info">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-lg-5">
                                                                            <div className="cp-user-qr">
                                                                                <img src={QR} className="img-fluid"
                                                                                     alt=""/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-7">
                                                                            <div
                                                                                className="cp-user-referral-content cp-user-withdraw-card">
                                                                                <form>
                                                                                    <div className="form-group">
                                                                                        <div className="input-group">
                                                                                            <div
                                                                                                className="input-group-prepend">
                                                                                                <button type="button"
                                                                                                    className="btn copy-url-btn">
                                                                                                    Copy
                                                                                                </button>
                                                                                            </div>
                                                                                            <input type="text"
                                                                                                   className="form-control"
                                                                                                   placeholder="2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q2NCzoqbY7CXi"/>
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                                                                                <a href="#"
                                                                                   className="cp-user-generate-address">Generate
                                                                                    a new address</a>
                                                                                <button onClick={this.showAddress} className="btn submit-btn btn-block">
                                                                                    Show past address
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.showAddress && <div className="cp-user-show-past-address">
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="cp-user-profile-header">
                                                                                        <h5>Show past address</h5>
                                                                                    </div>
                                                                                    <div
                                                                                        className="cp-user-wallet-table table-responsive">
                                                                                        <table
                                                                                            className="table table-borderless cp-user-custom-table"
                                                                                            width="100%">
                                                                                            <thead>
                                                                                            <tr>
                                                                                                <th className="all text-left">Address</th>
                                                                                                <th className="all text-right">Created
                                                                                                    At
                                                                                                </th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                            <tr>
                                                                                                <td className="text-left">2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                                                                <td className="text-right">2019-01-24
                                                                                                    12:50:17
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="text-left">2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                                                                <td className="text-right">2019-01-24
                                                                                                    12:50:17
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="text-left">2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                                                                <td className="text-right">2019-01-24
                                                                                                    12:50:17
                                                                                                </td>
                                                                                            </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            this.state.isWithdraw && <div className="tab-pane fade show active" id="pills-edit-profile" role="tabpanel"
                                                                          aria-labelledby="pills-edit-profile-tab">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="card cp-user-custom-card">
                                                            <div className="card-body">
                                                                <div className="cp-user-profile-header">
                                                                    <h5>Withdraw</h5>
                                                                </div>
                                                                <div className="cp-user-withdraw-card">
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-12">
                                                                            <form>
                                                                                <div className="row">
                                                                                    <div className="col-xl-6">
                                                                                        <div className="form-group">
                                                                                            <label>To</label>
                                                                                            <div
                                                                                                className="cp-user-coin-address">
                                                                                                <input type="text"
                                                                                                       placeholder="Coin Address . . ."
                                                                                                       className="form-control"/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group">
                                                                                            <label>Amount</label>
                                                                                            <input type="text"
                                                                                                   placeholder="Amount in Bit . . ."
                                                                                                   className="form-control"/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xl-6">
                                                                                        <div className="form-group">
                                                                                            <label>Note</label>
                                                                                            <textarea className="form-control"
                                                                                                      rows="7"
                                                                                                      placeholder="Type your message here. . . . . .(Optional)"></textarea>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xl-6">
                                                                                        <div className="form-group">
                                                                                            <button type="button"
                                                                                                className="btn submit-btn btn-block">Submit
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.isActivity && <div className="tab-pane fade show active" id="pills-phone-verify" role="tabpanel"
                                                                          aria-labelledby="pills-phone-verify-tab">
                                                <div className="card cp-user-custom-card">
                                                    <div className="card-body">
                                                        <div className="row justify-content-center">
                                                            <div className="col-12">

                                                                <div
                                                                    className="cp-user-profile-header d-sm-flex d-block justify-content-sm-between align-items-center text-center">
                                                                    <div>
                                                                        <h5>Wallet Transaction Activity</h5>
                                                                    </div>
                                                                    <div className="mt-sm-0 mt-4">
                                                                        <ul className="nav cp-user-activity-tab mb-3 justify-content-sm-end justify-content-center"
                                                                            id="pills-tab" role="tablist">
                                                                            <li onClick={this.openDeposive} className="nav-item">
                                                                                <a className={ this.state.openDeposive ? "nav-link active" : "nav-link"}
                                                                                   id="pills-deposit-activity-tab"
                                                                                   data-toggle="pill"
                                                                                   aria-controls="pills-deposit-activity"
                                                                                   aria-selected="true">
                                                                                    Deposit
                                                                                </a>
                                                                            </li>
                                                                            <li onClick={this.openWithdraw} className="nav-item">
                                                                                <a className={ this.state.openWithdraw ? "nav-link active" : "nav-link"}
                                                                                   id="pills-withdraw-activity-tab"
                                                                                   data-toggle="pill"
                                                                                   aria-controls="pills-withdraw-activity"
                                                                                   aria-selected="false">
                                                                                    Withdraw
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="tab-content" id="pills-tabContent">
                                                                    {
                                                                        this.state.openDeposive && <div className="tab-pane fade show active"
                                                                                                        id="pills-deposit-activity" role="tabpanel"
                                                                                                        aria-labelledby="pills-deposit-activity-tab">

                                                                            <div className="cp-user-wallet-table table-responsive">
                                                                                <table
                                                                                    className="table table-borderless cp-user-custom-table"
                                                                                    width="100%">
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th className="all text-left">Address</th>
                                                                                        <th className="text-left">Amount</th>
                                                                                        <th className="text-left">Transaction Hash
                                                                                        </th>
                                                                                        <th className="all text-left">Updated At
                                                                                        </th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    <tr>
                                                                                        <td className="text-left">2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                                                        <td className="text-left">455.93939999</td>
                                                                                        <td className="text-left">2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                                                        <td className="text-left">2019-01-24
                                                                                            12:50:17
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text-left">2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                                                        <td className="text-left">455.93939999</td>
                                                                                        <td className="text-left">2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                                                        <td className="text-left">2019-01-24
                                                                                            12:50:17
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text-left">2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                                                        <td className="text-left">455.93939999</td>
                                                                                        <td className="text-left">2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                                                        <td className="text-left">2019-01-24
                                                                                            12:50:17
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        this.state.openWithdraw && <div className="tab-pane fade show active" id="pills-withdraw-activity"
                                                                                                        role="tabpanel"
                                                                                                        aria-labelledby="pills-withdraw-activity-tab">

                                                                            <div className="cp-user-wallet-table table-responsive">
                                                                                <table
                                                                                    className="table table-borderless cp-user-custom-table"
                                                                                    width="100%">
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th className="all text-left">Address</th>
                                                                                        <th className="text-left">Amount</th>
                                                                                        <th className="text-left">Transaction Hash
                                                                                        </th>
                                                                                        <th className="all text-left">Updated At
                                                                                        </th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    <tr>
                                                                                        <td className="text-left">2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                                                        <td className="text-left">455.93939999</td>
                                                                                        <td className="text-left">2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                                                        <td className="text-left">2019-01-24
                                                                                            12:50:17
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="text-left">2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                                                        <td className="text-left">455.93939999</td>
                                                                                        <td className="text-left">2NCzoqbY7CXitVwwiNDq9MqvjDJV4DcBj3Q</td>
                                                                                        <td className="text-left">2019-01-24
                                                                                            12:50:17
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default MyWallet;